import React from "react";
import { Navigate } from "react-router-dom";
import { authContext } from "../logic/authHook";
import { useLocation } from 'react-router-dom'

/*
 * WRAPPER COMPONENT FOR PAGE THAT REQUIRES AUTHENTICATION
 *
 */
export default function RequiredAuth({children} : {children: React.ReactNode})  {

    const auth = React.useContext(authContext);
    const location = useLocation();

    // Why do we need this?
    // https://stackoverflow.com/questions/71550567/how-to-make-useeffect-in-authprovider-runs-first-then-call-usecontext
    if (auth?.loading === true)  
        return (<div className="text-xl text-center w-full h-full flex flex-row justify-center items-center">
                    Đang xác thực người dùng...
                </div>);
    
    return auth?.authedUser  ? <>{children}</> : <Navigate to="/login" 
                                                     replace 
                                                     // redirect to the page user originally access
                                                     state={{ path: location.pathname }}
                                            />;
}