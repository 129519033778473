/*
 * FUNCTION TO CHECK IF A SCROLL IS NEAR THE BOTTOM
 * - @param scrollHeight: height of the scrollable element
 * - @param scrollTop: current scroll position
 * - @param clientHeight: height of the visible element
 * - @param isReverse: true if the scroll is reversed
 * - @param offset: offset from the bottom
 * - @return: true if the scroll is near the bottom
 *
 */
export function shouldLoadMore(
    scrollHeight: number,
    scrollTop: number,
    clientHeight: number,
    isReverse: boolean,
    offset = 0
) {
    if(isReverse)
        return scrollHeight + scrollTop - clientHeight  < offset;
        
    return scrollHeight - scrollTop - clientHeight < offset;
}


/*
 * FUNCTION TO FORMAT A NUMBER TO CURRENCY STRING of vnđ
 * - @param num: number to be formatted
 * - @return: formatted string of vnđ
 *
 */
export function formatCurrency(num: number) {
    return num.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' });
}

export function vndCurrencyToNumber(vnd: string) {
    return Number(vnd.replace(/[^0-9,-]+/g,""));
}
