export enum CarStatus {
    PENDING = "pending",
    IDLE = "idle",
    RUNNING = "running",
    BLOCKED = "blocked",
}


export interface CarRequest {

    // Basic info
    brand_name: string;
    model: string;
    manufacture_year: number;
    seats: number;
    vehicle_number: string;

    // More details info
    photos: string[];         // $uuid
    is_taxi?: boolean;

    // For UI and logic only
    photoSrcs?: string[];
    photoFiles?: File[];
}

export interface CarInfo extends CarRequest {
    // Index
    _id: string;               // $uuid
    id: string;                // $uuid
    status: CarStatus;         // $enum
}

export function getDefaultCarRequest(): CarRequest {
    return {
        brand_name: "",
        model: "",
        manufacture_year: new Date().getFullYear(),
        seats: 4,
        vehicle_number: "",
        photos: [],
        is_taxi: false,
    };
}