import { BookingType } from "./Booking";
import { CarInfo } from "./Car";

export enum ProfileStatus {
    NO_PROFILE = "no_profile",
    PENDING = "pending",
    ACTIVATED = "activated",
}

export enum AccountType {
    ADMIN_1 = "admin-1",
    ADMIN_2 = "admin-2",
    ADMIN_3 = "admin-3",
    ADMIN_4 = "admin-4",
    DRIVER = "driver",
}

export interface BankInfo {
    bin: string;
    account_number: string;
    account_name: string;
}

export interface UserMinimalInfo {
    phone_number: string;
    avatar?: string;            // $uuid
    name: string;

    // these fields are not in the database, save them here for optimized image loading
    avatar_url?: string;
    avatar_data_string?: string;
}


export interface UserPublicInfo extends UserMinimalInfo {
    join_date?: string;         // $date
    status: ProfileStatus;      // $enum
    locked: boolean;
    bank?: BankInfo;
    no_pass_bookings?: number;
    no_bought_bookings?: number;
    type?: AccountType;          // $enum
}

export interface UserInfo extends UserPublicInfo {
    // Identity
    id_name: string;
    id_number: string;
    date_of_birth: string;
    sex: "male" | "female" | "other";
    id_photos: string[];        // $uuid[]

    // Driver info
    point: number;
    pending_point: number;
    activate_date?: string;     // $date
    expire_date?: string;       // $date
    sponsor: string;            // $uuid
    buy_type: BookingType[];
    deposit: number;
    vehicles: [CarInfo] | null;
    conversations: string[];    // $uuid[]
    approvals: string[];        // $uuid[]
    bought_bookings: string[];  // $uuid[]
    passed_bookings: string[];    // $uuid[]
    locked_time: string;        // $date

    // For UI only
    profile?: ApprovalInfo;
}


export function getDefaultUserPublicInfo(): UserPublicInfo {
    return {
        phone_number: "",
        name: "",
        status: ProfileStatus.NO_PROFILE,
        locked: false,
    };
}


export enum ApprovalType {
    PROFILE = "profile",
    VEHICLE = "vehicle",
    TRANSACTION = "transaction",
}

export enum ApprovalStatus {
    PENDING = "pending",
    ACCEPTED = "accepted",
    REJECTED = "rejected",
}

export enum TransactionType {
    WITHDRAW = "withdraw",
    DEPOSIT = "deposit",
    EXCHANGE = "exchange",
    BUY_BOOKING = "buy_booking",
    SUBSCRIBE = "subscribe",
}

export interface ApprovalInfo {
    _id?: string;                // $uuid
    id_name?: string;
    id_number?: string;
    date_of_birth?: string;
    sex?: "male" | "female" | "other";
    id_photos?: string[];        // $uuid[]
    type: ApprovalType;
    sponsor?: string;            // $uuid
    vehicles?: CarInfo[];
    transaction_type?: TransactionType;
    point?: number;
    photo?: string;              // $uuid
    receiver?: string;           // $phone
    note?: string;
    creator?: string;            // $phone
    status?: ApprovalStatus;     // $enum
    created_time?: string;       // $datetime
    updateAt?: string;           // $datetime
    admin_review?: {
        created_time?: string;   // $datetime
        admin?: string;          // $phone
        note?: string;
        accept?: boolean;
    }
    sponsor_review?: {
        created_time?: string;   // $datetime
        note?: string;
        accept?: boolean;
    }
}

export interface ApprovalDecision {
    accept: boolean;
    note: string;
    rule: "admin" | "sponsor";
    buy_type?: BookingType[];
    deposit?: number;
    subscription?: "none" | "1_week" | "2_week" | "1_month" | "3_month" | "6_month" | "1_year";
}

export interface Notification {
    _id: string;                // $uuid
    createdAt: string;          // $datetime
    updatedAt: string;          // $datetime
    notification: {
        title: string,
        body: string,
    }
    data: {
        type: string,
        data: string,
    }
}


export interface TransactionInfo {
    _id: string;                // $uuid
    type: "withdraw" | "deposit" | "exchange" | "buy_booking" | "subscribe";
    from: string;               // $phone
    to: string;                 // $phone
    created_time: string;       // $datetime
    approval_id?: string;       // $uuid
    booking_id?: string;        // $uuid
    point: number;
    status: "pending" | "accepted" | "rejected";
}