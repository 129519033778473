import { width } from "@mui/system";
import React from "react";
import { Icon } from "@iconify/react";


export default function NavButton(
  {
    label = "",
    icon_selected,
    icon_unselected,
    selected = false,
    onClick = () => { }
  }: {
    label?: string,
    icon_selected: React.ReactNode | string,
    icon_unselected: React.ReactNode | string,
    selected?: boolean,
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  }
) {
  let iconBtnlassName = selected
    ? `w-16 h-16 flex flex-col justify-center items-center border-0 bg-green-700 text-white`
    : `w-16 h-16 flex flex-col justify-center items-center border-0 bg-transparent text-green-700 
      hover:bg-green-600 hover:text-green-100`;
  let labelClassName = selected
    ? `text-sm font-medium text-white text-center px-1`
    : `text-sm font-medium text-green-700 text-center px-1`;
  let icon = selected ? icon_selected : icon_unselected;

  return (
    <div className="flex flex-col items-center">
      <button
        className={iconBtnlassName}
        onClick={onClick}
      >
        {
          typeof icon === "string" ? <Icon icon={icon} width={32} /> : icon
        }
      </button>

      {
        label &&
        <span className={labelClassName}>
          {label}
        </span>
      }

    </div>
  );
}