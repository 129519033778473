import React from "react";

export default function Chip({
    label = "",
    color = "ghost",
    onClick = () => {},
} : {
    label: string,
    color?: "info" | "warning" | "error" | "success" | "ghost",
    onClick?: () => void,
}) {

    let chipColor = "";
    let main_color = "gray";
    switch (color) {
        case "info":
            main_color = "blue";
            break;
        case "warning":
            main_color = "yellow";
            break;
        case "error":
            main_color = "red";
            break;
        case "success":
            main_color = "green";
            break;
        case "ghost":
            main_color = "gray";
            break;
        default:
            break;
    }

    return (
        <div 
            className={`badge badge-sm p-2.5 rounded  border-0 bg-${main_color}-100 text-${main_color}-600`}
            onClick={onClick}
        >
            {label}
        </div>
    );
}