import axios from "axios";

export async function checkUserExists( phone: string ) {
    const ret = await axios( {
        method: 'GET',
        url: `/exist/${phone}`,
    })
    
    return ret.data.code === 0;
}

export async function getMe() {
    const ret = await axios( {
        method: 'GET',
        url: `/user`,
    })
    
    return ret.data;
}