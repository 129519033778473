import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { 
    ConversationInfo, 
    SearchChatResult 
} from "../../common/models/Chat";
import { 
    UserPublicInfo, 
    getDefaultUserPublicInfo 
} from "../../common/models/User";

import { joinConversationWithUser, searchConversation } from "../../chat/logic/api";
import { shouldLoadMore } from "../../common/logic/utils";

import { authContext } from "../../auth/logic/authHook";
import {
    appContext,
    getUserFromAppContext,
} from "../../common/logic/appContext";
import { chatContext } from "../../chat/logic/chatContext";

import NavList from "../../common/ui/Nav/NavList";
import SearchBox from "../../common/ui/SearchBox";
import ChatThreadCard from "../../chat/components/ChatThreadCard";
import SearchChatCard from "../../chat/components/SearchChatCard";


import menu_json from "../../common/config/menu.json";
import { enqueueSnackbar } from "notistack";
var booking_sub_menu = menu_json[0].sub_menus;

export default function SideBarBooking() {

    const [partner, setPartner] = React.useState<UserPublicInfo | undefined>(undefined);
    const [lastSearchText, setLastSearchText] = React.useState<string>("");
    const [searchChatResults, setSearchChatResults] = React.useState<SearchChatResult[] | undefined>(undefined);
    const [selectedSearchChatID, setSelectedSearchChatID] = React.useState<string>("");
    const [showSearchResult, setShowSearchResult] = React.useState<boolean>(false);
    const auth = React.useContext(authContext);
    const appCtx = React.useContext(appContext);
    const chatCtx = React.useContext(chatContext);
    const navigate = useNavigate();
    const searchBoxRef = React.useRef<HTMLInputElement>(null);

    /*
     *======= Get avatar of the chat thread  ======
     *
     */
     const handleSelectChat = (
        conversation: ConversationInfo | undefined,
        partner: UserPublicInfo | undefined,
    ) => {

        if (conversation) {
            if (conversation.type === 'complaining') {
                // fixed avatar for complaining thread
                setPartner({
                    ...getDefaultUserPublicInfo(),
                    name: `Khiếu nại lịch xe: ${conversation.complaining?.booking_short_id}`,
                    avatar_data_string: conversation.complaining?.status === 'resolved'
                        ? `${process.env.PUBLIC_URL}/icons/complain_resolved.svg`
                        : `${process.env.PUBLIC_URL}/icons/complain_unresolved.svg`,
                })
                navigate(`/booking/chat?convId=${conversation._id}`);
            }
            else {
                const partnerPhone = conversation.users.find((p) => p !== auth?.authedUser?.phone_number);
                if (appCtx && partnerPhone) {
                    const userInCtx = getUserFromAppContext(partnerPhone, appCtx.users); // user here is phone number
                    if (!userInCtx) {
                        axios({
                            method: 'GET',
                            url: `/user/${partnerPhone}`,
                        })
                            .then((res) => {
                                setPartner(res.data as UserPublicInfo);
                            })
                    }
                    else {
                        setPartner(userInCtx);
                    }
                }
                navigate(`/booking/chat?convId=${conversation._id}`);
            }
        }
        else if (partner) {
            console.log( "-----------> new partner: ", partner)
            setPartner(partner as UserPublicInfo);
            joinConversationWithUser(partner.phone_number)
                .then((conversation) => {
                    console.log("-----------> new conversation: ", conversation)
                    setLastSearchText("");
                    setSearchChatResults([]);
                    setShowSearchResult(false);
                    chatCtx?.updateConversation(conversation);
                    chatCtx?.setSelectConversionId(conversation._id);
                    navigate(`/booking/chat?convId=${conversation._id}`);
                })
                .catch((err) => {
                    enqueueSnackbar(`Không thể bắt đầu cuộc trò chuyện với ${partner.phone_number}: ${err.response.data}`, { variant: "error"});
                });
        }

        // navigate("/booking/chat");
    }

    /*
     *======= When search request submitted
     *
     */
     const handleSearch = (searchText: string) => {
        console.log( "------------> okoko: ", searchText)
        const finalRes = [] as SearchChatResult[];
        setSearchChatResults(undefined);
        if (searchText === "") {
            return;
        }

        searchConversation(searchText)
            .then((res) => {
                // get found conversations
                if (res[0].status === "fulfilled") {
                    const searchChats = res[0].value.data?.map((conversationInfo: ConversationInfo) => {
                        return {
                            conversation: conversationInfo,
                            hasMessage: true,
                        }
                    })
                    finalRes.push(...searchChats!);
                }

                // get found users
                if (res[1].status === "fulfilled") {
                    let searchChats = [] as SearchChatResult[];
                    (res[1].value.data! as UserPublicInfo[]).forEach((user) => {
                        // check if this user has conversation
                        const hasConversation = finalRes?.find((searchChat) => {
                            return searchChat.conversation?.type === "normal"
                                && searchChat.conversation.users.includes(user.phone_number);
                        });

                        // if not, add to search result
                        if (hasConversation === undefined) {
                            searchChats.push({
                                user: user,
                                hasMessage: false,
                            })
                        }
                    })

                    // add to search result
                    if (searchChats.length > 0) {
                        finalRes.push(...searchChats);
                    }
                    setSearchChatResults(finalRes);
                }
            });
    }

    /*
     *======= When search input changing
     *
     */
     const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (Math.abs(event.target.value.length - lastSearchText.length) > 2) {
            handleSearch(event.target.value);
            setLastSearchText(event.target.value);
        }
    }


    return (
        <div className="w-full h-full flex flex-col bg-gray-50 border-r border-gray-200 overflow-y-scroll">
            {booking_sub_menu &&
                <NavList
                    menu={booking_sub_menu}
                    basePath="/booking"
                />
            }

            <div className="w-full px-4 py-3">
                <SearchBox
                    onFocus={() => {
                        setSearchChatResults([]);
                        setShowSearchResult(true)
                    }}
                    onBlur={(event) => {
                        if (event.target.value === "") setShowSearchResult(false);
                    }}
                    onChange={handleChange}
                    onSubmit={handleSearch}
                    onCancel={() => {
                        setShowSearchResult(false);
                    }}
                    ref={searchBoxRef}
                />
            </div>

            <div
                className="w-full flex flex-col overflow-y-scroll"
                onScroll={(event) => {
                    const ele = event.target as HTMLDivElement;
                    if (shouldLoadMore(
                        ele.scrollHeight,
                        ele.scrollTop,
                        ele.clientHeight,
                        false,
                        1)
                    ) {
                        chatCtx?.loadMoreChatThreads();
                    }
                }}
            >
                {
                    showSearchResult
                        ? searchChatResults?.map((searchChatResult) => {
                            return (
                                <SearchChatCard
                                    key={searchChatResult.conversation?._id || searchChatResult.user?.phone_number}
                                    chatInfo={searchChatResult}
                                    selected={selectedSearchChatID !== ""
                                        ? (selectedSearchChatID === (searchChatResult.conversation?._id || searchChatResult.user?.phone_number || ""))
                                        : false}
                                    onClick={() => {
                                        (searchBoxRef.current as HTMLInputElement).value = "";
                                        setSelectedSearchChatID(searchChatResult.conversation?._id || searchChatResult.user?.phone_number || "");
                                        handleSelectChat(searchChatResult.conversation, searchChatResult.user);
                                    }}
                                />
                            )
                        })

                        : chatCtx?.conversations.map((conversationInfo) => {
                            return (
                                <ChatThreadCard
                                    key={conversationInfo._id}
                                    conversationInfo={conversationInfo}
                                    selected={chatCtx?.selectConversionId === conversationInfo._id}
                                    onClick={() => {
                                        if (chatCtx?.selectConversionId !== conversationInfo._id) {
                                            chatCtx?.setSelectConversionId(conversationInfo._id)
                                            const conv = chatCtx?.conversations.find((conv) => conv._id === conversationInfo._id);
                                            handleSelectChat(conv, undefined);
                                        }
                                    }}
                                />
                            );
                        })
                }
            </div>
        </div>
    );
}