import React from "react";
import { UserPublicInfo } from "../models/User";
import axios from "axios";
import {Buffer} from 'buffer';

// CONTEXT FOR STORING GLOBAL STATE ALL OVER THE APP
export interface AppContext {
    users: UserPublicInfo[];
    setUsers: (users: UserPublicInfo[]) => void;
}

export const appContext = React.createContext<AppContext | null>(null);

/*
 * FUNCTION TO PRELOAD USER AVATAR AND STORE THEM IN APP CONTEXT
 *  - @param user: user to preload avatar and added to app context
 *  - @return: user with avatar data string
 *
 */
export function preloadUserAvatar( user: UserPublicInfo) : UserPublicInfo | undefined {
    let tmpUser = {...user};

    // get user avatar url
    axios({
        method: "GET",
        url: `/files/${user.avatar}`,
    })
    .then((res) => {
        // update user avatar url
        tmpUser.avatar_url = res.data.url;
        // load avatar data
        axios(
            {
                method: 'GET',
                url: tmpUser.avatar_url,
                responseType: 'arraybuffer',
            }
        )
        .then(res1 => {
            tmpUser.avatar_data_string = `data:${res1.headers["content-type"]
                };base64,${new Buffer(res1.data, "binary").toString("base64")}`;
            return tmpUser;
        })
        .catch(err1 => {
            return undefined;
        })
    })
    .then(() => {
        return tmpUser;
    })
    .catch((err) => {
        return undefined;
    });

    return tmpUser;
}

/*
 * FUNCTION TO PRELOAD USER AVATAR AND STORE THEM IN APP CONTEXT
 *  - @param user: user to preload avatar and added to app context
 *  - @return: user with avatar data string
 *
 */
export async function asyncPreloadUserAvatar(user:UserPublicInfo) : Promise<UserPublicInfo | undefined> {

    if( user.avatar === undefined || user.avatar === "") {
        return undefined;
    }

    const avatarUrl = await axios({
        method: "GET",
        url: `/files/${user.avatar}`,
    });

    const avatarData = await axios({
            method: 'GET',
            url: avatarUrl.data.url,
            responseType: 'arraybuffer',
    });
    
    // const avatarDataString = `data:${avatarData.headers["content-type"] }
    //                         ;base64,${new Buffer(avatarData.data, "binary").toString("base64")}`;
    
    const blob = new Blob([avatarData.data], { type: 'image/jpeg' });
    const avatarDataString =  URL.createObjectURL(blob);
    
    return {
        ...user,
        avatar_url: avatarUrl.data.url,
        avatar_data_string: avatarDataString,
    }
}



/*
 * FUNCTION TO GET A SPECIFIC USER FROM APP CONTEXT BY PHONE NUMBER
 *  - @param phone_number: phone number of the user to get
 *  - @param ctxUsers: list of users in app context
 *  - @return: user with the phone number
 *
 */
export function getUserFromAppContext(
    phone_number: string,
    ctxUsers: UserPublicInfo[],
) {
    return ctxUsers.find((item) => item.phone_number === phone_number);
}


/*
 * FUNCTION TO PRELOAD USER AVATAR AND STORE THEM IN APP CONTEXT
 *  - @param users: list of users to preload avatar and added to app context
 *  - @param ctxUsers: list of users in app context
 *  - @param setCtxUsers: function to set app context users
 *
 */
export function saveUserToAppContext(
    users: UserPublicInfo[],
    ctxUsers: UserPublicInfo[],
    setCtxUsers: (users: UserPublicInfo[]) => void,
) {

    users.forEach((user) => {
        // check if user is in the list
        const userInList = getUserFromAppContext(user.phone_number, ctxUsers);
        if (!userInList) {
            let tmpUser = preloadUserAvatar(user);
            if( tmpUser ) {
                setCtxUsers([...ctxUsers, tmpUser]);
            }
        }

    });
}


/*
 * AUTHENTICATION PROVIDER, THAT PROVIDE
 *  - USERS LIST
 *
 */
export default function AppContextProvider({ children }: { children: React.ReactNode }) {
    const [users, setUsers] = React.useState<UserPublicInfo[]>([]);

    const appCtx : AppContext = {
        users: users,
        setUsers: setUsers,
    }

    return (
        <appContext.Provider value={appCtx}>
            {children}
        </appContext.Provider>
    );
}
