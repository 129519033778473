import React from "react";

export default function InfoWithLabel({
    label = "",
    info = "",
    direction = "row",
    alignInfo = "left",
    width = "100%",
    highlight = false,
    emphasis = false,
    multilines = false,
}: {
    label: string,
    info: string,
    direction?: "row" | "column",
    alignInfo?: "left" | "right",
    width?: string,
    highlight?: boolean,
    emphasis?: boolean,
    multilines?: boolean,
}) {
    return (
        <div 
            className={direction === "row"
                ?"flex flex-row items-baseline gap-2"
                :"flex flex-col items-start gap-2"
            }
            style={{
                width: width,
                maxWidth: "100%"
            }}
        >
            <div 
                className="text-sm text-left font-medium text-gray-500"
                style={ direction === "row" ? {width: "50%"} : {width: "100%"} }
            >
                {label}
            </div>
            <text 
                className={`${highlight ? "text-green-500 font-medium" : "text-gray-700"} 
                            ${emphasis ? "text-xl font-semibold" : "text-base text-left font-regular"} 
                            ${alignInfo === "right" ? "text-right" : "text-left"}`
                        }
                style={ direction === "row" ? {width: "50%"} : {width: "100%"} }
            >
                {info}
            </text>
        </div>
    );
}