import React from "react";
import NavButton from "./NavButton";
import menu_json from "../../config/menu.json";
import { authContext, AuthContext } from "../../../auth/logic/authHook";
import { useLocation, useNavigate } from "react-router-dom";
import Badge from '@mui/material/Badge';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

export default function Nav() {
    const navigate = useNavigate();
    const location = useLocation();
    const {logout} = React.useContext(authContext) as AuthContext;

    return (
        <div className="w-16 h-full bg-green-500 flex flex-col justify-between items-center">
            <div className="w-full flex flex-row justify-center pt-3">
                <img
                    src={`${process.env.PUBLIC_URL}/images/logo_192.png`}
                    alt="logo"
                    className="w-12 h-12 rounded-full"
                />
            </div>

            <div className="flex flex-col">
                {menu_json.map((item, index) => {
                    if (item.label !== "Chat")
                    return (
                        <NavButton
                            key={index}
                            // label={item.label}
                            icon_selected={item.icon_selected}
                            icon_unselected={item.icon_unselected}
                            selected={
                                location.pathname === item.path ||
                                location.pathname.startsWith(item.path + "/")
                            }
                            onClick={() => {
                                navigate(item.path);
                            }}
                        />
                    );
                })}
            </div>

            <div className="w-full text-center pb-16 flex flex-col">
                {/* <NavButton
                    icon_selected="octicon:bell-24"
                    icon_unselected="octicon:bell-24"
                    onClick={() => {
                        logout();
                    }}
                /> */}

                <NavButton
                    icon_selected="circum:logout"
                    icon_unselected="circum:logout"
                    onClick={() => {
                        logout();
                    }}
                />
            </div>
        </div>
    );
}