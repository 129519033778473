import React from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import SideBarAdmin from "../components/SideBarAdmin";

export default function AdminPageLayout() {

    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        if (location.pathname === '/admin') {
            navigate('/admin/members');
        }
    }, [location]);

    return (
        <div className="w-full h-full grid grid-cols-10 overflow-y-hidden">
            <div className="col-span-2 h-full">
                < SideBarAdmin />
            </div >
            <div className="col-span-8 h-full overflow-y-hidden">
                <Outlet />
            </div >
        </div>
    );
}