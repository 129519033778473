import React from "react";
import { BookingInfoV2, BookingPublicInfoV2, BookingType} from "../../common/models/Booking";
import { useNavigate } from "react-router-dom";
import InfoWithLabel from "../../common/ui/InfoWithLabel";
import { UserInfo, AccountType } from "../../common/models/User";
import BankInfoCard from "../../common/ui/BankInfoCard";
import IconButton from "../../common/ui/IconButton";
import BookingCard from "./BookingCard";
import { authContext } from "../../auth/logic/authHook";
import Select from "../../common/ui/Select";
import { useSnackbar } from "notistack";
import { 
    handleCancelBooking, 
    handleRepostBooking,
    buyBooking,
    getAvailableCars,
} from "../logic/api";
import { CarInfo } from "../../common/models/Car";
import { isExpired } from "../../common/logic/time_utils";
import { fDateTime } from "../../common/logic/time_utils";
import { pinBooking, getConversationIDByPhone } from "../../chat/logic/api";
import DriverInfoCard from "./DriverInfoCard";
import { ConversationInfo } from "../../common/models/Chat";
import { chatContext } from "../../chat/logic/chatContext";

export default function ViableBuyBookingDetail({
    booking,
    onClose = () => { },
    onBuy = () => { },
    onCancel = () => { },
    onRepost = () => { },
}: {
    booking: BookingInfoV2 | BookingPublicInfoV2,
    onClose?: () => void,
    onBuy?: () => void,
    onCancel?: () => void,
    onRepost?: () => void,
}) {
    const [_booking, setBooking] = React.useState<BookingInfoV2 | BookingPublicInfoV2>(booking);
    const [cars, setCars] = React.useState<CarInfo[]>([]);
    const auth = React.useContext(authContext);
    const [selectedCarId, setSelectedCarId] = React.useState<string>("");
    const { enqueueSnackbar } = useSnackbar();
    const chatCtx = React.useContext(chatContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        setBooking(booking);

        getAvailableCars()
            .then((res) => {
                // console.log("-----> cars: ", res.data, res.status);
                if (res.status === 200) {
                    setCars(res.data);
                    setSelectedCarId(res.data[0]?.id || "");
                }
            })
            .catch((err) => {
                setCars([]);
            });
    }, [booking]);


    /*
     *======= Handle Buy Booking  ======
     *
     */
    const handleBuy = () => {
        if ( _booking.status === "pending" && isExpired(_booking.update_at) ) {
            enqueueSnackbar("Chuyến xe đã hết hạn", { variant: "error" });
            return;
        }

        buyBooking(selectedCarId, _booking)
            .then((res) => {
                if (res.status === 200) {
                    enqueueSnackbar("Mua chuyến xe thành công", { variant: "success" });
                    onBuy();
                }
            })
            .catch((err) => {
                if(err.status === 400)
                {
                    enqueueSnackbar("Xe đang được dùng cho chuyến khác", { variant: "error" });
                    return;
                }
                enqueueSnackbar(`Mua chuyến xe thất bại: ${err.response.data}`, { variant: "error" });
            });
    }


    /*
     *======= UI Rendering
     *
     */
    return (
        <div className="w-full h-full flex flex-col gap-4 px-4 py-3 justify-between overflow-y-scroll">

            {/* Form Label */}
            <div className="py-3 flex flex-row justify-between items-start">
                <div className="flex flex-col gap-2 items-start">
                    <div className="text-xl font-semibold text-gray-700">
                        Thông tin chuyến xe
                    </div>
                    <div className="text-sm text-left font-medium text-gray-500">
                        {`Mã số: ${booking.short_id}`}
                    </div>
                </div>

                <IconButton
                    icon="ion:close-outline"
                    variant="mute"
                    onClick={() => {
                        onClose();
                    }}
                />
            </div>

            <div className="w-full h-full flex flex-col gap-4">

                {/* Booking card */}
                <BookingCard
                    booking={booking}
                    show_status={true}
                    width={"100%"}
                />

                {/* Booking optional info */}
                <div className="w-full flex flex-col items-start gap-1">
                    <InfoWithLabel
                        label="Loại lộ trình"
                        info={  booking.type === BookingType.GOODBYE ? "Tiễn sân bay":
                                booking.type === BookingType.WELCOME ? "Đón sân bay" : "Liên/nội tỉnh"}
                    />

                    <InfoWithLabel
                        label="Thời gian đón"
                        info={booking.start_time ? fDateTime(booking.start_time, "dd/MM/yyyy - HH:mm") : "N/A"}
                    />

                    <InfoWithLabel
                        label="Số chỗ"
                        info={booking.seats ? booking.seats.toString() : "N/A"}
                    />

                    <InfoWithLabel
                        label="Sđt khách"
                        info={booking.client_phone ? booking.client_phone : "N/A"}
                    />
                </div>

                <InfoWithLabel
                    label="Thông tin thêm"
                    info={
                        `${booking.allow_taxi ? "Cho phép taxi" : "Không taxi"} - 
                    ${booking.highway_fee ? "Trọn gói cao tốc - " : ""}
                    ${booking.round_trip ? "Khứ hồi" : "1 chiều"} - 
                    ${booking.name_board_required ? "Cần bảng tên" : ""}`
                    }
                    direction="column"
                    width="100%"
                />

                { // Booking Note
                    (booking.note !== undefined && booking.note !== "") &&
                    <InfoWithLabel
                        label="Ghi chú"
                        info={booking.note as string}
                        direction="column"
                        width="100%"
                    />
                }

                { // Booking image
                    booking.image &&
                    <img
                        src={booking.image}
                        alt="booking"
                        className="w-36 h-36 rounded-lg object-cover object-center"
                    />
                }

                
                { // Car info: TODO
                    'vehicle' in booking &&

                    <div className="w-full flex flex-col gap-2 items-start">
                        <div className="text-base font-medium text-left pt-4">
                            Thông tin xe đón
                        </div>
                    </div>
                }

                {/* Booking driver (pass/buy) info */}
                {
                    auth?.authedUser?.phone_number === booking.user_creator.phone_number && ('buyer' in booking) 
                    
                    ?   <div className="w-full flex flex-col gap-2 items-start">
                            <div className="text-base font-medium text-left pt-4">
                                Thông tin tài xế
                            </div>

                            <DriverInfoCard
                                user={booking.buyer}
                                onChatClick={() => {
                                    pinBooking(booking._id, booking.buyer.phone_number)
                                        .then((res) => {
                                            if(res[0].status === "fulfilled"){
                                                chatCtx?.setSelectConversionId((res[0].value as ConversationInfo)._id);
                                                navigate(`/booking/chat?convId=${(res[0].value as ConversationInfo)._id}`);
                                            }
                                                
                                        })
                                        .catch((err) => {
                                            enqueueSnackbar("Đã có lỗi xảy ra", { variant: "error" });
                                        });
                                }}
                            />

                            {
                                booking.buyer.bank &&
                                <BankInfoCard
                                    bankInfo={booking.buyer.bank}
                                />
                            }
                        </div>

                    :   auth?.authedUser?.phone_number !== booking.user_creator.phone_number
                        ?   <div className="w-full flex flex-col gap-2 items-start gap-4">
                                <div className="text-base font-medium text-left pt-4">
                                    Thông tin chủ lịch
                                </div>
                                
                                <DriverInfoCard
                                    user={booking.user_creator}
                                    onChatClick={() => {
                                        pinBooking(booking._id, booking.user_creator.phone_number)
                                            .then((res) => {
                                                if(res[0].status === "fulfilled"){
                                                    chatCtx?.setSelectConversionId((res[0].value as ConversationInfo)._id);
                                                    navigate(`/booking/chat?convId=${(res[0].value as ConversationInfo)._id}`);
                                                }
                                            })
                                            .catch((err) => {
                                                enqueueSnackbar("Đã có lỗi xảy ra", { variant: "error" });
                                            });
                                    }}
                                />

                                {
                                    booking.user_creator.bank &&
                                    <BankInfoCard
                                        bankInfo={booking.user_creator.bank}
                                    />
                                }
                            </div>
                        : null

                }
            </div>

            {   // User is driver and booking is not his, and it has not been bought
                auth?.authedUser?.phone_number !== booking.user_creator.phone_number 
                && auth?.authedUser?.type === AccountType.DRIVER && !('buyer' in booking) &&
                
                <div className="flex flex-col gap-4 pt-12 bg-white" >
                    <Select
                        label="Chọn xe cho chuyến đi này"
                        options={cars.map((vehicle) => {
                            return {
                                label: `${vehicle.brand_name} ${vehicle.model} (${vehicle.vehicle_number})`,
                                value: vehicle.id,
                            }}
                        )}
                        value={selectedCarId}
                        onChange={(e) => { 
                            // console.log( "-----> car selected changed: ", e.target.value)
                            setSelectedCarId(e.target.value as string);
                         }}
                    />
                    <button
                        className="w-full py-3 bg-green-500 text-white rounded-lg"
                        onClick={handleBuy}
                    >
                        Bổ chuyến
                    </button>
                </div>
            }

            {   // User is Admin and booking has not been bought
                auth?.authedUser?.phone_number !== booking.user_creator.phone_number 
                && auth?.authedUser?.type !==  AccountType.DRIVER && !('buyer' in booking) &&

                <div className="flex flex-col gap-4 pt-12 bg-white" >
                    <Select
                        label="Chọn xe cho chuyến đi này"
                        options={cars.map((vehicle) => {
                            return {
                                label: `${vehicle.brand_name} ${vehicle.model} (${vehicle.vehicle_number})`,
                                value: vehicle.id,
                            }
                        }
                        )}
                        value={selectedCarId}
                        onChange={(e) => {
                            setSelectedCarId(e.target.value as string);
                        }}
                    />

                    <div className="flex flex-row gap-4 bg-white" >

                        <button
                            className="w-full py-3 bg-red-500 text-white rounded-lg"
                            onClick={() => { 
                                let ret = handleCancelBooking(_booking) 
                                if (ret === "success"){
                                    onCancel();
                                    onClose();
                                    enqueueSnackbar("Đã huỷ chuyến thành công", { variant: "success" });
                                }
                                else
                                    enqueueSnackbar(`Lỗi khi huỷ chuyến với lý do: ${ret}`, { variant: "error" });
                            }}
                        >
                            Huỷ chuyến
                        </button>

                        <button
                            className="w-full py-3 bg-green-500 text-white rounded-lg"
                            onClick={handleBuy}
                        >
                            Bổ chuyến
                        </button>
                    </div>
                </div>
            }

            { // User is booking creator and this booking has not been bought
                auth?.authedUser?.phone_number === booking.user_creator.phone_number
                && !('buyer' in booking) &&

                <div className="flex flex-row gap-4 pt-12 bg-white" >
                    <button
                        className="w-full py-3 bg-red-500 text-white rounded-lg"
                        onClick={() => {
                            let ret = handleCancelBooking(_booking) 
                            if (ret === "success"){
                                onCancel();
                                onClose();
                                enqueueSnackbar("Đã huỷ chuyến thành công", { variant: "success" });
                            }
                            else
                                enqueueSnackbar(`Lỗi khi huỷ chuyến với lý do: ${ret}`, { variant: "error" });
                        }}
                    >
                        Huỷ chuyến
                    </button>

                    <button
                        className="w-full py-3 bg-green-500 text-white rounded-lg"
                        onClick={() => {
                            let ret = handleRepostBooking( _booking )
                            if (ret === "success"){
                                onRepost();
                                onClose();
                                enqueueSnackbar("Đã đăng lại chuyến thành công", { variant: "success" });
                            }
                            else
                                enqueueSnackbar(`Lỗi khi đăng lại chuyến với lý do: ${ret}`, { variant: "error" });
                        }}
                    >
                        Đăng lại
                    </button>
                </div>
            }
        </div>
    );
}