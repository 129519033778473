import React from "react";
import CheckBox from "../../common/ui/CheckBox";
import Input from "../../common/ui/Input";
import PhotoSelect from "../../common/ui/PhotoSelect";
import { useSnackbar } from "notistack";


/*
 * Login form fields interface
 *
 */
export interface SignupFormFields {
    phone_number: string;
    name: string;
    password: string;
    avatar: string | undefined;
    token: string;
}

/*
 * Login Form React Component
 *
 */
export default function FormSignUp({
    handleFormSubmit,
    phone,
    token
}: {
    handleFormSubmit: (formFields: SignupFormFields, file?: File) => void;
    phone: string;
    token: string;
}) {
    const [_formFields, setFormFields] = React.useState<SignupFormFields>({
        phone_number: phone,
        name: "",
        password: "",
        avatar: undefined,
        token: token,
    })
    const [rePassword, setRePassword] = React.useState<string>("")
    const [avatarFile, setAvatarFile] = React.useState<File | undefined>(undefined)
    const [disabledSubmit, setDisabledSubmit] = React.useState<boolean>(true)
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        if (_formFields.name.length > 0 && _formFields.password.length > 0  && rePassword.length > 0) {
            setDisabledSubmit(false)
        } else {
            setDisabledSubmit(true)
        }
    }, [_formFields, rePassword])

    return (
        <div
            className="flex flex-col gap-6 items-start px-8 py-8"
        >

            <div className="text-3xl font-medium">
                Đăng ký tài khoản mới
            </div>

            <Input
                label="Tên tài khoản"
                onChange={(event) => {
                    setFormFields({
                        ..._formFields,
                        name: event.target.value,
                    });
                }}
            />

            <Input
                label="Mật khẩu"
                type="password"
                onChange={(event) => {
                    setFormFields({
                        ..._formFields,
                        password: event.target.value,
                    });
                }}
            />

            <Input
                label="Nhập lại mật khẩu"
                type="password"
                onChange={(event) => {
                    setRePassword(event.target.value);
                }}
            />

            {/* <PhotoSelect
                label="Ảnh đại diện"
                onChange={(event) => setAvatarFile(event.target.files?.[0])}
                width="100px"
                height="100px"
            /> */}

            <button
                className="btn btn-block btn-success"
                disabled={disabledSubmit}
                onClick={() => {
                    if (_formFields.password !== rePassword) {
                        enqueueSnackbar("Mật khẩu không khớp!", { variant: "error", });
                        return;
                    }
                    handleFormSubmit(_formFields, avatarFile);
                }}
            >
                Hoàn tất đăng ký
            </button>

            <a 
                className="link link-hover link-success"
            >
                Đăng nhập
            </a>
        </div>
    );
}