import React from "react";
import { BookingInfoV2, BookingStatus, BookingType} from "../../common/models/Booking";
import InfoWithLabel from "../../common/ui/InfoWithLabel";
import { AccountType } from "../../common/models/User";
import BankInfoCard from "../../common/ui/BankInfoCard";
import IconButton from "../../common/ui/IconButton";
import BookingCard from "./BookingCard";
import { authContext } from "../../auth/logic/authHook";
import { useSnackbar } from "notistack";
import { handleCancelBooking, handleRepostBooking} from "../logic/api";
import { fDateTime } from "../../common/logic/time_utils";
import { Button } from "@mui/material";
import { Icon } from '@iconify/react';
import { finishBooking } from "../logic/api";
import DriverInfoCard from "./DriverInfoCard";
import BookingComplaintForm from "../forms/BookingComplaintForm";
import { useNavigate } from "react-router-dom";
import { ConversationInfo } from "../../common/models/Chat";
import CarCard from "./CarCard";
import { pinBooking } from "../../chat/logic/api";

export default function HistoryBookingDetail({
    booking,
    onClose = () => { },
    onComplain = () => { },
    onCompleted = () => { },
    onCancel = () => { },
    onRepost = () => { },
    onEdit = () => { },
}: {
    booking: BookingInfoV2,
    onClose?: () => void,
    onComplain?: () => void,
    onCompleted?: () => void,
    onCancel?: () => void,
    onRepost?: () => void,
    onEdit?: () => void,
}) {
    const [_booking, setBooking] = React.useState<BookingInfoV2>(booking);
    const auth = React.useContext(authContext);
    const { enqueueSnackbar } = useSnackbar();
    const modalCheckboxRef = React.useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        setBooking(booking);
    }, [booking]);


    /*
     *======= UI Rendering
     *
     */
    return (
        <div className="w-full h-full flex flex-col gap-4 px-4 py-3 justify-between overflow-y-scroll">
            
            {/* Modal for showing booking complaint form */}
            <input 
                type="checkbox" 
                id="booking-complain-form" 
                className="modal-toggle" 
                ref={modalCheckboxRef}
            />
            <label htmlFor="booking-complain-form" className="modal cursor-pointer">
                <label className="modal-box relative" htmlFor="">
                    <BookingComplaintForm 
                        booking={booking}
                        onRaisedComplaint={(conversation : ConversationInfo) => {
                            modalCheckboxRef.current?.click();
                            onComplain();

                            // Redirect to chat page
                            navigate(`/booking/chat?convId=${conversation._id}`);
                        }}
                        onFailedComplaint={() => {
                            modalCheckboxRef.current?.click();
                        }}
                    />
                </label>
            </label>

            {/* Form Label */}
            <div className="py-3 flex flex-row justify-between items-start">
                <div className="flex flex-col gap-2 items-start">
                    <div className="text-xl font-semibold text-gray-700">
                        Thông tin chuyến xe
                    </div>
                    <div className="text-sm text-left font-medium text-gray-500">
                        {`Mã số: ${booking.short_id}`}
                    </div>
                </div>

                <IconButton
                    icon="ion:close-outline"
                    variant="mute"
                    onClick={() => {
                        onClose();
                    }}
                />
            </div>

            <div className="w-full h-full flex flex-col gap-4">

                {/* Booking card */}
                <BookingCard
                    booking={booking}
                    show_status={true}
                    width={"100%"}
                />

                {/* Booking optional info */}
                <div className="w-full flex flex-col items-start gap-1">
                    <InfoWithLabel
                        label="Loại lộ trình"
                        info={  booking.type === BookingType.GOODBYE ? "Tiễn sân bay":
                                booking.type === BookingType.WELCOME ? "Đón sân bay" : "Liên/nội tỉnh"}
                    />

                    <InfoWithLabel
                        label="Thời gian đón"
                        info={booking.start_time ? fDateTime(booking.start_time, "dd/MM/yyyy - HH:mm") : "N/A"}
                    />

                    <InfoWithLabel
                        label="Số chỗ"
                        info={booking.seats ? booking.seats.toString() : "N/A"}
                    />

                    <InfoWithLabel
                        label="Sđt khách"
                        info={booking.client_phone ? booking.client_phone : "N/A"}
                    />
                </div>

                <InfoWithLabel
                    label="Thông tin thêm"
                    info={
                        `${booking.allow_taxi ? "Cho phép taxi" : "Không taxi"} - 
                    ${booking.highway_fee ? "Trọn gói cao tốc - " : ""}
                    ${booking.round_trip ? "Khứ hồi" : "1 chiều"} - 
                    ${booking.name_board_required ? "Cần bảng tên" : ""}`
                    }
                    direction="column"
                    width="100%"
                />

                { // Booking Note
                    (booking.note !== undefined && booking.note !== "") &&
                    <InfoWithLabel
                        label="Ghi chú"
                        info={booking.note as string}
                        direction="column"
                        width="100%"
                    />
                }

                { // Booking image
                    booking.image &&
                    <img
                        src={booking.image}
                        alt="booking"
                        className="w-36 h-36 rounded-lg object-cover object-center"
                    />
                }

                
                { // Car info: TODO
                    booking.vehicle &&

                    <div className="w-full flex flex-col gap-2 items-start">
                        <div className="text-base font-medium text-left pt-4">
                            Thông tin xe đón
                        </div>

                        <CarCard
                            car={booking.vehicle}
                        />
                    </div>
                }

                {/* Booking driver (pass/buy) info */}
                {
                    auth?.authedUser?.phone_number === booking.user_creator.phone_number && ('buyer' in booking) 
                    
                    ?   <div className="w-full flex flex-col gap-2 items-start">
                            <div className="text-base font-medium text-left pt-4">
                                Thông tin tài xế
                            </div>

                            <DriverInfoCard 
                                user={booking.buyer}
                                onChatClick={() => {
                                    pinBooking(booking._id, booking.buyer.phone_number)
                                        .then((res) => {
                                            if(res[0].status === "fulfilled")
                                                navigate(`/booking/chat?convId=${(res[0].value as ConversationInfo)._id}`);
                                        })
                                        .catch((err) => {
                                            enqueueSnackbar("Đã có lỗi xảy ra", { variant: "error" });
                                        });
                                }}
                            />

                            {
                                booking.buyer.bank &&
                                <BankInfoCard
                                    bankInfo={booking.buyer.bank}
                                />
                            }
                        </div>

                    :   auth?.authedUser?.phone_number !== booking.user_creator.phone_number
                        ?   <div className="w-full flex flex-col gap-2 items-start gap-4">
                                <div className="text-base font-medium text-left pt-4">
                                    Thông tin chủ lịch
                                </div>
                                
                                <DriverInfoCard 
                                    user={booking.user_creator}
                                    onChatClick={() => {
                                        pinBooking(booking._id, booking.user_creator.phone_number)
                                            .then((res) => {
                                                if(res[0].status === "fulfilled")
                                                    navigate(`/booking/chat?convId=${(res[0].value as ConversationInfo)._id}`);
                                            })
                                            .catch((err) => {
                                                enqueueSnackbar("Đã có lỗi xảy ra", { variant: "error" });
                                            });
                                    }}
                                />

                                {
                                    booking.user_creator.bank &&
                                    <BankInfoCard
                                        bankInfo={booking.user_creator.bank}
                                    />
                                }
                            </div>
                        : null

                }
            </div>

            {   // User bought this booking
                auth?.authedUser?.phone_number !== booking.user_creator.phone_number 
                && auth?.authedUser?.type === AccountType.DRIVER && ('buyer' in booking)
                && (_booking.status !== BookingStatus.FINISHED) &&
                
                <div className="flex flex-row gap-4 pt-12 bg-white" >
                    {
                        _booking.complain_id
                            ?
                            <button
                                className="w-1/2 py-3 bg-yellow-500 text-white rounded-lg"
                                onClick={() => {
                                    onComplain();
                                    navigate(`/booking/chat?convId=${_booking.complain_id}`)
                                }}
                            >
                                Khiếu nại
                            </button>

                            :
                            <label 
                                htmlFor="booking-complain-form" 
                                className="w-1/2 btn btn-warning"
                            >
                                Khiếu nại
                            </label>
                    }

                    <button
                        className="w-1/2 py-3 bg-green-500 text-white rounded-lg"
                        onClick={() => { 
                            finishBooking(_booking._id)
                            .then( (res) => {
                                enqueueSnackbar(`Bạn vừa hoàn thành chuyến xe mã số ${_booking.short_id}`, {variant: "success"});
                                onCompleted();
                            })
                            .catch( (err) => {
                                switch (err.status) {
                                    case 400:
                                        enqueueSnackbar("Không thể hoàn thành chuyến, dữ liệu không hợp lệ", {variant: "error"});
                                        break;
                                    case 401:
                                        enqueueSnackbar("Không thể hoàn thành chuyến, bạn không được cấp quyền", {variant: "error"});
                                        break;
                                    case 404:
                                        enqueueSnackbar("Không thể hoàn thành chuyến, chuyến xe không tồn tại", {variant: "error"});
                                        break;
                                    case 500:
                                        enqueueSnackbar("Không thể hoàn thành chuyến, lỗi máy chủ", {variant: "error"});
                                        break;
                                    default:
                                        enqueueSnackbar("Không thể hoàn thành chuyến, lỗi không xác định", {variant: "error"});
                                        break;
                                }
                            });
                        }}
                    >
                        Hoàn thành chuyến
                    </button>
                </div>
            }

            
            { // User posted this booking and this booking has not been bought
                auth?.authedUser?.phone_number === _booking.user_creator.phone_number
                && (_booking.status === BookingStatus.PENDING) &&
                
                <div className="flex flex-col gap-4 pt-12 bg-white" >
                    <div className="flex flex-row justify-end gap-4" >
                        <Button
                            variant="text"
                            size="small"
                            onClick={onEdit}
                            startIcon={<Icon icon="material-symbols:edit" />}
                        >
                            Chỉnh sửa
                        </Button>
                    </div>

                    <div className="flex flex-row gap-4" >
                        <button
                            className="w-1/2 py-3 bg-red-500 text-white rounded-lg"
                            onClick={() => {
                                let ret = handleCancelBooking(_booking) 
                                if (ret === "success"){
                                    onCancel();
                                    onClose();
                                    enqueueSnackbar("Đã huỷ chuyến thành công", { variant: "success" });
                                }
                                else
                                    enqueueSnackbar(`Lỗi khi huỷ chuyến với lý do: ${ret}`, { variant: "error" });
                            }}
                        >
                            Huỷ chuyến
                        </button>

                        <button
                            className="w-1/2 py-3 bg-green-500 text-white rounded-lg"
                            onClick={() => {
                                let ret = handleRepostBooking( _booking )
                                if (ret === "success"){
                                    onRepost();
                                    onClose();
                                    enqueueSnackbar("Đã đăng lại chuyến thành công", { variant: "success" });
                                }
                                else
                                    enqueueSnackbar(`Lỗi khi đăng lại chuyến với lý do: ${ret}`, { variant: "error" });
                            }}
                        >
                            Đăng lại
                        </button>
                    </div>
                </div>
            }

            { // User posted this booking and this booking has been bought and not finished
                auth?.authedUser?.phone_number === _booking.user_creator.phone_number
                && (_booking.status === BookingStatus.ACTIVATED) &&
                
                <div className="flex flex-row gap-4 pt-12 bg-white" >
                    <button
                        className="w-1/2 btn btn-error"
                        onClick={() => {
                            let ret = handleCancelBooking(_booking) 
                            if (ret === "success"){
                                onCancel();
                                onClose();
                                enqueueSnackbar("Đã huỷ chuyến thành công", { variant: "success" });
                            }
                            else
                                enqueueSnackbar(`Lỗi khi huỷ chuyến với lý do: ${ret}`, { variant: "error" });
                        }}
                    >
                        Huỷ chuyến
                    </button>

                    {
                        _booking.complain_id
                            ?
                            <button
                                className="w-1/2 btn btn-warning"
                                onClick={() => {
                                    onComplain();
                                    navigate(`/booking/chat?convId=${_booking.complain_id}`)
                                }}
                            >
                                Khiếu nại
                            </button>

                            :
                            <label 
                                htmlFor="booking-complain-form" 
                                className="w-1/2 btn btn-warning"
                            >
                                Khiếu nại
                            </label>
                    }
                </div>
            }

            { // This booking was finished
                (_booking.status === BookingStatus.FINISHED) &&
                
                <div className="flex flex-col gap-4 mt-12 bg-white" >

                    {
                        _booking.complain_id
                            ?
                            <button
                                className="w-full py-3 bg-yellow-500 text-white rounded-lg"
                                onClick={() => {
                                    onComplain();
                                    navigate(`/booking/chat?convId=${_booking.complain_id}`)
                                }}
                            >
                                Khiếu nại
                            </button>

                            :
                            <label 
                                htmlFor="booking-complain-form" 
                                className="w-full btn btn-warning"
                            >
                                Khiếu nại
                            </label>
                    }
                </div>
            }
        </div>
    );
}