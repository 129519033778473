import React from "react";
import { Notification, UserPublicInfo } from "../../common/models/User";
import { fDateTime } from "../../common/logic/time_utils";
import { downloadFile } from "../../common/logic/api";
import { sendNotification } from "../logic/api";
import IconButton from "../../common/ui/IconButton";
import InfoWithLabel from "../../common/ui/InfoWithLabel";
import InputMultiLine from "../../common/ui/InputMultiLine";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import { set } from "date-fns";


export default function NewNotificationForm({
    onBroadcastSuccessful = () => { },
    onClose = () => { },
}: {
    onBroadcastSuccessful?: (noti?: Notification) => void,
    onClose?: () => void,
}) {


    const [content, setContent] = React.useState<string>("");
    const [image, setImage] = React.useState<string>("");
    const [file, setFile] = React.useState<File | undefined>(undefined);
    const imgRef = React.useRef<HTMLInputElement>(null);
    const {enqueueSnackbar} = useSnackbar();

    const handleBroadcastNotification = () => {
        sendNotification(content, file)
            .then(() => {
                setContent("");
                setImage("");
                setFile(undefined);
                if (imgRef.current) {
                    imgRef.current.files = null;
                    imgRef.current.value = "";
                }
                enqueueSnackbar("Gửi thông báo thành công", {variant: "success"});
                onBroadcastSuccessful();
            })
            .catch((err) => {
                enqueueSnackbar(`Không thể gửi thông báo với lý do: ${err.response.data}`, {variant: "error"});
            });
    }


    return (
        <div className="w-full flex flex-col gap-4 p-4 bg-white rounded-lg border border-green-700">
            <div className="text-lg font-medium text-green-700">
                Tạo thông báo mới
            </div>

            <InputMultiLine
                placeholder="Nhập nội dung thông báo"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                rows={3}
                border={false}
            />

            { /* Image preview */
                image !== "" &&
                <div
                    className="w-40 h-40 flex flex-col justify-start items-end px-1 py-1 rounded-lg"
                    style={{
                        backgroundImage: `url(${image})`,
                        backgroundSize: "cover",
                    }}
                >
                    <Icon
                        icon="iwwa:delete"
                        // variant="danger"
                        onClick={(event) => {
                            event.preventDefault();
                            setFile(undefined);
                            setImage("");
                            if (imgRef.current) {
                                imgRef.current.files = null;
                                imgRef.current.value = "";
                            }
                        }}
                        className="cursor-pointer px-1 py-1 rounded-full bg-gray-200"
                    />
                </div>
            }

            <div className="w-full h-px border border-gray-200"></div> 

            <div className="w-full flex flex-row justify-between items-start">
                
                {/* Hidden input for uploading image */}
                <div className="flex flex-row gap-2 items-center">
                    <input
                        type="file"
                        id="file"
                        style={{display: "none"}}
                        onChange={(event) => {
                            if( event.target.files !== null ) {
                                const file_ = event.target.files[0];
                                const src = URL.createObjectURL(file_);
                                setFile(file_)
                                setImage(src);
                            }
                        }}
                        accept="image/*"
                        ref={imgRef}
                    />
                </div>

                {/* Image upload button and send button */}
                <div className="flex flex-row gap-2 items-center">
                    {/* Upload image button */}
                    <IconButton
                        icon="heroicons:photo"
                        variant="mute"
                        onClick={(event) => {
                            event.preventDefault();
                            console.log("Upload image");
                            if( imgRef.current !== null ) {
                                imgRef.current.click();
                            }
                        }}
                    />

                    {/* Send notification button */}
                    <button
                        className="btn btn-success btn-sm gap-2"
                        onClick={handleBroadcastNotification}
                    >
                        <Icon
                            icon="carbon:send-alt-filled"
                            fontSize={20}
                        />
                        Gửi thông báo
                    </button>
                </div>
            </div>
        </div>
    )

}