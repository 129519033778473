import React from "react";
import { ApprovalInfo, ApprovalStatus } from "../../common/models/User";
import InputMultiLine from "../../common/ui/InputMultiLine";
import { UserInfo, ApprovalDecision } from "../../common/models/User";
import { BookingType } from "../../common/models/Booking";
import ToogleButton from "../../common/ui/ToogleButton";
import Select from "../../common/ui/Select";
import Input from "../../common/ui/Input";
import { vndCurrencyToNumber } from "../../common/logic/utils";
import { updateAccountPermission, getApprovalProfileByPhone } from "../logic/api";
import { useSnackbar } from "notistack";


const defaultPreviledge: ApprovalDecision = {
    accept: false,
    note: "",
    rule: "admin",
    buy_type: [BookingType.GOODBYE, BookingType.WELCOME, BookingType.PROVINCE],
    deposit: 0,
    subscription: "none"
}


export default function DriverPreviledgeUpdateForm({
    user,
    onUpdateSuccessfully = () => { },
    onUpdateFailed = () => { },
}: {
    user: UserInfo,
    onUpdateSuccessfully?: () => void,
    onUpdateFailed?: () => void,
}) {

    const [previlidege, setPrevilidege] = React.useState<ApprovalDecision>(defaultPreviledge);
    const [approvalId, setApprovalId] = React.useState<string>("");
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        getApprovalProfileByPhone(user.phone_number)
            .then((res) => {
                const profile = res.data as ApprovalInfo;
                setApprovalId(profile._id!);
                setPrevilidege({
                    accept: true,
                    note: "",
                    rule: "admin",
                    buy_type: user.buy_type,
                    subscription: "none",
                });
            })
            .catch((err) => {
                setPrevilidege(defaultPreviledge);
            });

    }, [user]);

    const handleSumit = (decision: ApprovalDecision) => {
        updateAccountPermission(user.phone_number, decision)
            .then((res) => {
                enqueueSnackbar("Cập nhật quyền tài xế thành công", { variant: "success" });
                onUpdateSuccessfully();
            })
            .catch((err) => {
                enqueueSnackbar(`Lỗi cập nhật quyền tài xế: ${err.response.data}`, { variant: "error" });
                onUpdateFailed();
            });
    }

    return (
        <div
            className="w-full h-full bg-white flex flex-col gap-4 rounded-lg"
        >
            <div className="text-base font-semibold text-left">
                Cập nhật quyền tài xế
            </div>

            {/* Type of booking driver can buy */}
            <div className="flex flex-col gap-2">
                <div className="text-sm font-medium text-gray-500">
                    Loại chuyến tài xế được bổ
                </div>

                <div className="flex flex-row gap-2">
                    <ToogleButton
                        text="Tiễn"
                        value={previlidege.buy_type ? previlidege.buy_type.includes(BookingType.GOODBYE) : false}
                        onChange={(value) => {
                            if (value) {
                                setPrevilidege({
                                    ...previlidege,
                                    buy_type: [
                                        ...previlidege.buy_type!,
                                        BookingType.GOODBYE
                                    ]
                                });
                            } else {
                                setPrevilidege({
                                    ...previlidege,
                                    buy_type: previlidege.buy_type?.filter(type => type !== BookingType.GOODBYE)
                                });
                            }
                        }}
                    />

                    <ToogleButton
                        text="Đón"
                        value={previlidege.buy_type ? previlidege.buy_type.includes(BookingType.WELCOME) : false}
                        onChange={(value) => {
                            if (value) {
                                setPrevilidege({
                                    ...previlidege,
                                    buy_type: [
                                        ...previlidege.buy_type!,
                                        BookingType.WELCOME
                                    ]
                                });
                            } else {
                                setPrevilidege({
                                    ...previlidege,
                                    buy_type: previlidege.buy_type?.filter(type => type !== BookingType.WELCOME)
                                });
                            }
                        }}
                    />

                    <ToogleButton
                        text="Tỉnh"
                        value={previlidege.buy_type ? previlidege.buy_type.includes(BookingType.PROVINCE) : false}
                        onChange={(value) => {
                            if (value) {
                                setPrevilidege({
                                    ...previlidege,
                                    buy_type: [
                                        ...previlidege.buy_type!,
                                        BookingType.PROVINCE
                                    ]
                                });
                            } else {
                                setPrevilidege({
                                    ...previlidege,
                                    buy_type: previlidege.buy_type?.filter(type => type !== BookingType.PROVINCE)
                                });
                            }
                        }}
                    />
                </div>
            </div>


            {/* Membership grant */}
            <Select
                label="Tặng hạn thành viên"
                options={[
                    { value: "none", label: "Không tặng" },
                    { value: "1_week", label: "Tặng 1 tuần" },
                    { value: "2_week", label: "Tặng 2 tuần" },
                    { value: "1_month", label: "Tặng 1 tháng" },
                    { value: "3_month", label: "Tặng 3 tháng" },
                    { value: "6_month", label: "Tặng 6 tháng" },
                    { value: "1_year", label: "Tặng 1 năm" },
                ]}
                value={previlidege.subscription}
                onChange={event => setPrevilidege({
                    ...previlidege,
                    subscription: event.target.value as ApprovalDecision["subscription"]
                })}
            />

            {/* Note */}
            <InputMultiLine
                placeholder="Ghi chú cho tài xế"
                value={previlidege.note}
                onChange={event => setPrevilidege({
                    ...previlidege,
                    note: event.target.value
                })}
                rows={3}
            />

            <button
                className="btn btn-block btn-success"
                onClick={() => {
                    handleSumit(previlidege as ApprovalDecision);
                }}
            >
                Cập nhật quyền
            </button>
        </div>
    )
}