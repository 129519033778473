import React from "react";
import { UserPublicInfo, Notification } from "../../common/models/User";
import Avatar from "../../common/ui/Avatar";
import Image from "../../common/ui/Image";
import { fDateTime } from "../../common/logic/time_utils";
import { Icon } from "@iconify/react";
import { getUserByPhone } from "../../common/logic/api";
import {
    appContext,
    getUserFromAppContext,
    saveUserToAppContext,
} from "../../common/logic/appContext";


export default function NotificationCard({
    noti,
    selected = false,
    onViewDetail = () => { },
}: {
    noti: Notification,
    selected?: boolean,
    onViewDetail?: (noti: Notification) => void,
}) {
    const [data, setData] = React.useState<any>(undefined);
    const [user, setUser] = React.useState<UserPublicInfo | undefined>(undefined);
    const appCtx = React.useContext(appContext);

    // Get user info
    React.useEffect(() => {
        const dataObj = JSON.parse(noti.data.data);
        setData( dataObj );

        if (dataObj.user.phone_number && appCtx) {
            const savedUser = getUserFromAppContext(dataObj.user.phone_number, appCtx.users);
            if (savedUser) {
                setUser(savedUser);
            } else {
                getUserByPhone(dataObj.user.phone_number)
                    .then((user) => {
                        if (user) {
                            setUser(user);
                            saveUserToAppContext([user], appCtx.users, appCtx.setUsers);
                        }
                    })
                    .catch((err) => {
                        setUser(undefined);
                    });
            }
        }
    }, []);


    return (
        <div 
            className={`w-full flex flex-row justify-between px-4 py-2 border-b border-gray-200
                        ${selected ? "bg-green-200" : ""}`}
            onClick={() => onViewDetail(noti)}
        >
            <div className="flex flex-row gap-2">
                <Avatar
                    user={user}
                    size="large"
                    showTitle={false}
                />

                <div className="flex flex-col gap-2">
                    <span className="text-sm text-gray-500 overflow-ellipsis whitespace-nowrap overflow-hidden max-w-sm">
                        {noti.notification.body}
                    </span>

                    <span className="text-xs text-gray-400">
                        {fDateTime(noti.createdAt, "HH:mm dd/MM/yyyy")}
                    </span>
                </div>
            </div>

            {
                noti.data.type === "global"
                    ? <span className="badge badge-success badge-sm">Đã ban hành</span>
                    : <span className="badge badge-error badge-sm">Đã thu hồi</span>
            }
        </div>
    );
}