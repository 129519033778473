import React from "react";
import { UserPublicInfo, ApprovalInfo, TransactionType, TransactionInfo } from "../../common/models/User";
import Avatar from "../../common/ui/Avatar";
import { fDateTime } from "../../common/logic/time_utils";
import { formatCurrency } from "../../common/logic/utils";
import { Icon } from "@iconify/react";
import { getUserByPhone } from "../../common/logic/api";
import {
    appContext,
    getUserFromAppContext,
    saveUserToAppContext,
} from "../../common/logic/appContext";
import { authContext } from "../../auth/logic/authHook";

export default function TransactionCard({
    transaction,
    ofUser,
}: {
    transaction: TransactionInfo,
    ofUser?: UserPublicInfo,
}) {

    const [user, setUser] = React.useState<UserPublicInfo | undefined>(undefined);
    const authCtx = React.useContext(authContext);

    React.useEffect(() => {
        if (ofUser) {
            setUser(ofUser);
        } else {
            setUser(authCtx?.authedUser ? authCtx.authedUser : undefined);
        }
    }, [ofUser]);

    if(!user) return null;

    return (
        <div className="w-full flex flex-row items-center justify-between px-4 py-2 border-b border-gray-200">

            {/* Avatar */}
            <Avatar
                src={
                    transaction.type === TransactionType.DEPOSIT
                        ? `${process.env.PUBLIC_URL}/icons/transaction_top_up.svg`
                        : transaction.type === TransactionType.WITHDRAW
                            ? `${process.env.PUBLIC_URL}/icons/transaction_withdraw.svg`
                            : transaction.type === TransactionType.SUBSCRIBE
                                ? `${process.env.PUBLIC_URL}/icons/transaction_subscribe.svg`
                                : transaction.type === TransactionType.BUY_BOOKING
                                    ? `${user.phone_number === transaction.from  
                                        ? `${process.env.PUBLIC_URL}/icons/transaction_buy_booking.svg` 
                                        : `${process.env.PUBLIC_URL}/icons/transaction_pass_booking.svg`}`
                                    : transaction.type === TransactionType.EXCHANGE
                                        ? `${process.env.PUBLIC_URL}/icons/transaction_exchange.svg`
                                        : ""
                }
                size="large"
                showTitle={true}
                title={
                    transaction.type === TransactionType.DEPOSIT
                        ? `Nạp ${transaction.point} điểm`
                        : transaction.type === TransactionType.WITHDRAW
                            ? `Rút ${transaction.point} điểm`
                            : transaction.type === TransactionType.SUBSCRIBE
                                ? `Gia hạn tài khoản ${Math.round(Number(transaction.point) / 0.25)} tháng`
                                : transaction.type === TransactionType.BUY_BOOKING
                                    ? `${user.phone_number === transaction.from ? "Bổ chuyến" : "Bán chuyến"}`
                                    : transaction.type === TransactionType.EXCHANGE
                                        ? `${user.phone_number === transaction.from
                                            ? `San điểm cho ${transaction.to}`
                                            : `Nhận điểm từ ${transaction.from}`
                                        }`
                                        : ""
                }
                subtitle={fDateTime(transaction.created_time!, "dd/MM/yyyy HH:mm")}
            />

            {/* Point and status */}
            <div className="flex flex-col gap-1 items-end">
                    <span className={`font-medium text-base`}>
                        {transaction.from === user.phone_number
                            ? `-${transaction.point}`
                            : `+${transaction.point}`
                        }
                    </span>
                    
                    {/* <span className="badge badge-warning badge-sm">Chờ duyệt</span> */}

                    {
                    transaction.status === "pending"
                        ? <span className="badge badge-warning badge-sm">Chờ duyệt</span>
                        : transaction.status === "accepted"
                            ? <span className="badge badge-success badge-sm">Thành công</span>
                            : <span className="badge badge-error badge-sm">Từ chối</span>
                   }
                </div>
        </div>
    )
}