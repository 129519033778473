export interface SettlementInfo {
    type: 'makepeace' | 'creator' | 'driver'
    created_time: string;       // ISO 8601 format
    admin: string;              // phone number of the admin who settled the complaint
    note: string;               // notes for the settlement
    penalty: {
        block_account?: '1_day' | '2_day' | '3_day' | '1_week' | '2_week' | '1_month' | '2_month' | 'forever';
        point: number;          // points to be deducted
    }
    award: {
        point: number;          // points to be awarded
    }
}


export interface ComplainingInfo {
    booking_id: string;        // $uuid
    booking_short_id: string;  // 6 digits short id
    complainer: string;        // phone number of the complainer
    status: 'resolved' | 'unresolved';
    settlement: SettlementInfo;
}


export function getDefaultComplaint() {
    return {
        booking_id: '',
        booking_short_id: '',
        complainer: '',
        status: 'unresolved',
        settlement: {
            type: 'makepeace',
            created_time: '',
            admin: '',
            note: '',
            penalty: {
                block_account: undefined,
                point: 0
            },
            award: {
                point: 0
            }
        }
    } as ComplainingInfo;
}