import React from "react";
import { ApprovalInfo, UserInfo } from "../../common/models/User";
import { shouldLoadMore } from "../../common/logic/utils";
import { getPendingSponsorRequests, getProcessedSponsorRequests } from "../logic/api";
import { useSnackbar } from "notistack";

import ToogleButton from "../../common/ui/ToogleButton";
import DriverInfoCard from "../../booking/components/DriverInfoCard";
import AppBar from "../../common/ui/Nav/AppBar";
import SponsorProfileDetails from "../components/SpronsorProfileDetails";

export default function SponsoredProfilePage() {

    const [pendingRequests, setPendingRequests] = React.useState<ApprovalInfo[]>([]);
    const [pendingRequestPage, setPendingRequestPage] = React.useState<number>(0);
    const [stopLoadMorePendingRequests, setStopLoadMorePendingRequests] = React.useState<boolean>(false);
    const [processedRequests, setProcessedRequests] = React.useState<UserInfo[]>([]);
    const [processedRequestPage, setProcessedRequestPage] = React.useState<number>(0);
    const [stopLoadMoreProcessedRequests, setStopLoadMoreProcessedRequests] = React.useState<boolean>(false);
    const [selectedRequest, setSelectedRequest] = React.useState<ApprovalInfo | undefined>(undefined);
    const [showSidePopup, setShowSidePopup] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [type, setType] = React.useState<"pending" | "processed">("pending");


    /*
    *======= Handle load more account
    *
    */
    const handleLoadMoreAccs = (type: string) => {
        if( type === "pending" ) {
            if( stopLoadMorePendingRequests ) return;

            getPendingSponsorRequests( pendingRequestPage, 20)
                .then(res => {
                    setPendingRequests([...pendingRequests, ...res.data]);
                    setPendingRequestPage(pendingRequestPage + 1);
                    if (res.data.length < 20) setStopLoadMorePendingRequests(true);
                })
                .catch(err => {
                    enqueueSnackbar( `Lỗi tải các hồ sơ chờ bảo lãnh: ${err.response.data}` , { variant: "error" });
                });
        }
        else {
            if( stopLoadMoreProcessedRequests ) return;

            getProcessedSponsorRequests( processedRequestPage, 20)
                .then(res => {
                    setProcessedRequests([...processedRequests, ...res.data]);
                    setProcessedRequestPage(processedRequestPage + 1);
                    if (res.data.length < 20) setStopLoadMoreProcessedRequests(true);
                })
                .catch(err => {
                    enqueueSnackbar(err.message, { variant: "error" });
                });
        }


    }

    React.useEffect(() => {
        if (type === "pending") {
            if (pendingRequests.length > 0) return;
            getPendingSponsorRequests( pendingRequestPage, 20)
                .then(res => {
                    setPendingRequests(res.data);
                    setPendingRequestPage(1);
                    if (res.data.length < 20) setStopLoadMorePendingRequests(true);
                })
                .catch(err => {
                    enqueueSnackbar( `Lỗi tải các hồ sơ chờ bảo lãnh: ${err.response.data}` , { variant: "error" });
                });
        }
        else {
            if (processedRequests.length > 0) return;
            getProcessedSponsorRequests( processedRequestPage, 20)
                .then(res => {
                    setProcessedRequests(res.data);
                    console.log(res.data);
                    setProcessedRequestPage(1);
                    if (res.data.length < 20) setStopLoadMoreProcessedRequests(true);
                })
                .catch(err => {
                    enqueueSnackbar(err.message, { variant: "error" });
                });
        }
    }, [type]);


    return (
        <div className="relative w-full h-full grid grid-cols-12 overflow-y-hiden">
            <div className={`${showSidePopup ? "col-span-8" : "col-span-12"} h-full flex flex-col overflow-y-hidden`}>

                <AppBar />

                <div className={`${showSidePopup ? "w-full" : "w-2/3"} h-full p-4 flex flex-row overflow-y-hidden`}>
                    {/* Indentity info */}
                    <div className="w-1/2 flex flex-col gap-4 p-4">
                        {/* <div className="w-full h-full flex flex-col gap-4 px-4 py-3 overflow-y-hidden"> */}

                        {/* Form Title */}
                        <div className="text-lg font-medium text-gray-700">
                            Danh sách hồ sơ yêu cầu bạn bảo lãnh
                        </div>


                        {/* Filter bookings by type */}
                        <div className="flex flex-row gap-2">
                            <ToogleButton
                                text="Chưa duyệt"
                                value={type === "pending"}
                                onChange={(value) => {
                                    if (value) setType("pending");
                                }}
                            />

                            <ToogleButton
                                text="Đã duyệt"
                                value={type === "processed"}
                                onChange={(value) => {
                                    if (value) setType("processed");
                                }}
                            />
                        </div>

                        {/* List of account */}
                        <div
                            className="flex flex-col gap-2 overflow-y-scroll"
                            onScroll={(event) => {
                                const ele = event.target as HTMLDivElement;
                                if (shouldLoadMore(
                                    ele.scrollHeight,
                                    ele.scrollTop,
                                    ele.clientHeight,
                                    false,
                                    1
                                )
                                ) {
                                    handleLoadMoreAccs(type);
                                }
                            }}
                        >
                            {type === "pending" && pendingRequests.map((request, index) => (
                                <DriverInfoCard
                                    key={index}
                                    user={request.creator ? request.creator : ""}
                                    enableChat={false}
                                    className="border-b border-gray-200 mt-1"
                                    onClick={() => {
                                        console.log(request);
                                        setSelectedRequest(request);
                                        setShowSidePopup(true);
                                    }}
                                />
                            ))}

                            {type === "processed" && processedRequests.map((request, index) => (
                                <DriverInfoCard
                                    key={index}
                                    user={request}
                                    enableChat={false}
                                    className="border-b border-gray-200 mt-1"
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Side popup */}
            <div className={`${showSidePopup ? "col-span-4" : "hidden"} h-full flex flex-col overflow-y-hidden`}>
                {selectedRequest &&
                    <SponsorProfileDetails
                        profile={selectedRequest}
                        onClose={() => setShowSidePopup(false)}
                    />
                }
            </div>
        </div>
    );
}