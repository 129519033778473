import React from "react";
import AppBar from "../../common/ui/Nav/AppBar";
import Select from "../../common/ui/Select";
import SearchBox from "../../common/ui/SearchBox";
import AccountRow from "../components/AccountRow";
import DriverPreviledgeUpdateForm from "../forms/DriverPreviledgeUpdateForm";
import LockUnlockAccountForm from "../forms/LockUnlockAccountForm";
import ProfileDetail from "../components/ProfileDetails";
import { UserInfo } from "../../common/models/User";
import { getUsers } from "../logic/api";
import { useSnackbar } from "notistack";
import { getUserByPhone } from "../../common/logic/api";
import { shouldLoadMore } from "../../common/logic/utils";
import BookingsHistorySidebar from "../components/BookingsHistorySidebar";
import TransactionsHistorySidebar from "../components/TransactionsHistorySidebar";

interface MemberFilter {
    status: "no_profile" | "pending" | "activated" | "deactivated" | "all";
    deposit: "0_100" | "101_300" | "310_500" | "501_1000" | "1001" | "all";
}

function MembersManaPage() {

    const [users, setUsers] = React.useState<UserInfo[]>([]);
    const [currPage, setCurrPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [filter, setFilter] = React.useState<MemberFilter>({
        status: "all",
        deposit: "all",
    });
    const [stopLoadMore, setStopLoadMore] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState<UserInfo | undefined>(undefined);
    const [showSidePopup, setShowSidePopup] = React.useState(false);
    const [showInfoType, setShowInfoType] = React.useState<"profile" | "bookings" | "transactions">("profile");
    const modalCheckboxManaPageRef = React.useRef<HTMLInputElement>(null);
    const modalLockUnlockRef = React.useRef<HTMLInputElement>(null);
    const { enqueueSnackbar } = useSnackbar();

    // Load list of user
    React.useEffect(() => {
        setStopLoadMore(false);
        getUsers(
            0,
            search,
            filter.status === "all" ? undefined : filter.status,
            filter.deposit === "all" ? undefined : filter.deposit,
        )
            .then((res) => {
                setUsers(res.data);
                setCurrPage(1);
            })
            .catch((err) => {
                enqueueSnackbar(`Lỗi tải thông tin tài khoản tài xế: ${err as string}`, { variant: "error" });
            });
    }, [filter, search]);

    // Handle view profile of a user
    const handleViewProfile = (user: UserInfo) => {
        setSelectedUser(user);
        setShowInfoType("profile");
        setShowSidePopup(true);
    }

    // Handle view profile of a user
    const handleViewBookingsHistory = (user: UserInfo) => {
        setSelectedUser(user);
        setShowInfoType("bookings");
        setShowSidePopup(true);
    }

    // Handle view profile of a user
    const handleViewTransactionsHistory = (user: UserInfo) => {
        setSelectedUser(user);
        setShowInfoType("transactions");
        setShowSidePopup(true);
    }

    const handelEditPreviledge = (user: UserInfo) => {
        setSelectedUser(user);
        modalCheckboxManaPageRef.current?.click();
    }

    const handleLockUnlock = (user: UserInfo) => {
        setSelectedUser(user);
        modalLockUnlockRef.current?.click();
    }

    /*
     *======= Handle load more account
     *
     */
    const handleLoadMoreAccount = () => {

        if (stopLoadMore) {
            return;
        }

        getUsers(
            currPage,
            search,
            filter.status === "all" ? undefined : filter.status,
            filter.deposit === "all" ? undefined : filter.deposit,
        )
            .then((res) => {
                if (res.data.length! > 0) {
                    const concatUsers = users.concat(res.data);
                    setUsers(concatUsers);
                    setCurrPage(currPage + 1);
                }
                else {
                    setStopLoadMore(true);
                }
            })
            .catch((err) => {
                enqueueSnackbar(`Lỗi tải thông tin tài khoản tài xế: ${err as string}`, { variant: "error" });
            });
    }

    return (
        <div className="w-full h-full grid grid-cols-12 overflow-y-hiden">

            {/* Modal for showing driver previledge update form */}
            <input
                type="checkbox"
                id="driver-priviledge-form"
                className="modal-toggle"
                ref={modalCheckboxManaPageRef}
            />
            <label htmlFor="driver-priviledge-form" className="modal cursor-pointer">
                <label className="modal-box relative" htmlFor="">
                    {
                        selectedUser &&
                        <DriverPreviledgeUpdateForm
                            user={selectedUser}
                            onUpdateSuccessfully={() => {
                                modalCheckboxManaPageRef.current?.click();

                                // reload selected user
                                getUserByPhone(selectedUser.phone_number)
                                    .then((resUser) => {
                                        // update list of users
                                        const newUsers = users.map((user) => {
                                            if (user.phone_number === resUser.phone_number) {
                                                return resUser as UserInfo;
                                            }
                                            return user;
                                        });
                                        setUsers(newUsers);
                                    })
                                    .catch((err) => {
                                        enqueueSnackbar(`Lỗi tải thông tin tài khoản tài xế: ${err as string}`, { variant: "error" });
                                    });
                            }}
                            onUpdateFailed={() => {
                                modalCheckboxManaPageRef.current?.click();
                            }}
                        />
                    }
                </label>
            </label>


            {/* Modal for showing driver previledge update form */}
            <input
                type="checkbox"
                id="lock-unlock-form"
                className="modal-toggle"
                ref={modalLockUnlockRef
                }
            />
            <label htmlFor="lock-unlock-form" className="modal cursor-pointer">
                <label className="modal-box relative" htmlFor="">
                    {
                        selectedUser &&
                        <LockUnlockAccountForm
                            user={selectedUser}
                            action={selectedUser.locked ? "unlock" : "lock"}
                            onLockUnlockSuccessfully={() => {
                                modalLockUnlockRef.current?.click();

                                // reload selected user
                                getUserByPhone(selectedUser.phone_number)
                                    .then((resUser) => {
                                        // update list of users
                                        const newUsers = users.map((user) => {
                                            if (user.phone_number === resUser.phone_number) {
                                                return resUser as UserInfo;
                                            }
                                            return user;
                                        });
                                        setUsers(newUsers);
                                    })
                                    .catch((err) => {
                                        enqueueSnackbar(`Lỗi tải thông tin tài khoản tài xế: ${err as string}`, { variant: "error" });
                                    });
                            }}
                            onLockUnlockFailed={() => {
                                modalLockUnlockRef.current?.click();
                            }}
                        />
                    }
                </label>
            </label>

            {/* Body */}
            <div className={`${showSidePopup ? "col-span-8" : "col-span-12"} h-full flex flex-col overflow-y-hidden`}>
                <AppBar>
                    <div className="flex flex-row justify-start items-center gap-2">
                        <Select
                            options={[
                                { value: "all", label: "Trạng thái hồ sơ" },
                                { value: "activated", label: "Hoạt động" },
                                { value: "deactivated", label: "Đã khóa" },
                                { value: "pending", label: "Chờ duyệt" },
                                { value: "no_profile", label: "Chưa cập nhật hồ sơ" },
                            ]}
                            value={filter.status}
                            onChange={(e) => setFilter({
                                ...filter,
                                status: e.target.value as MemberFilter["status"],
                            })}
                            width="200px"
                        />

                        <Select
                            options={[
                                { value: "all", label: "Số tiền đặt cọc" },
                                { value: "0_100", label: "0 - 100k" },
                                { value: "101_300", label: "101k - 300k" },
                                { value: "310_500", label: "301k - 500k" },
                                { value: "501_1000", label: "501k - 1000k" },
                                { value: "1001", label: "Trên 1000k" },
                            ]}
                            value={filter.deposit}
                            onChange={(e) => setFilter({
                                ...filter,
                                deposit: e.target.value as MemberFilter["deposit"],
                            })}
                            width="200px"
                        />

                        <SearchBox
                            placeholder="Nhập tên hoặc sđt"
                            width="260px"
                            value={search}
                            onChange={(event) => {
                                if (Math.abs(event.target.value.length - search.length) > 2) {
                                    setSearch(event.target.value);
                                }
                            }}
                        />
                    </div>
                </AppBar>

                <div
                    className="w-full h-full flex flex-col px-4 py-6 overflow-y-scroll"
                    onScroll={(event) => {
                        const ele = event.target as HTMLDivElement;
                        if (shouldLoadMore(
                            ele.scrollHeight,
                            ele.scrollTop,
                            ele.clientHeight,
                            false,
                            1)
                        ) {
                            handleLoadMoreAccount();
                        }
                    }}
                >
                    <table className="table w-full">
                        <thead className="sticky top-0">
                            <tr>
                                <th>Tài khoản</th>
                                <th>Tên /Ngày sinh</th>
                                <th>Hạn thành viên</th>
                                <th>Điểm /Đặt cọc</th>
                                <th># xe</th>
                                <th># lịch đăng</th>
                                <th># lịch bổ</th>
                                <th>Hồ sơ</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody
                            className="overflow-y-scroll"
                        >
                            {
                                users.map((user) => (
                                    <AccountRow
                                        key={user.phone_number}
                                        user={user}
                                        onViewProfile={() => handleViewProfile(user)}
                                        onEditPrivilege={() => handelEditPreviledge(user)}
                                        onLock={()=> handleLockUnlock(user)}
                                        onUnlock={() => handleLockUnlock(user)}
                                        onViewBookingsHistory={() => {
                                            console.log("view booking history: ", user);
                                            handleViewBookingsHistory(user)
                                        }}
                                        onViewTransactionsHistory={() => handleViewTransactionsHistory(user)}
                                    />
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Detail of customers */}
            <div className={`${showSidePopup ? "flex" : "hidden"} col-span-4 h-full flex flex-col-reverse border-l border-gray-200 overflow-y-scroll`}>
                {selectedUser &&
                    (
                        showInfoType === "profile"
                            ? <ProfileDetail
                                user={selectedUser}
                                onClose={() => setShowSidePopup(false)}
                            />
                            : showInfoType === "bookings"
                                ? <BookingsHistorySidebar
                                    ofUser={selectedUser}
                                    onClose={() => setShowSidePopup(false)}
                                />
                                : <TransactionsHistorySidebar
                                    ofUser={selectedUser}
                                    onClose={() => setShowSidePopup(false)}
                                />
                    )
                }
            </div>
        </div>
    );
}

export default MembersManaPage;