import React from "react";
import NavTab from "./NavTab";
import { useLocation, useNavigate } from "react-router-dom";

export default function NavList({
    menu,
    basePath = "/",
}: {
    menu: {
        path: string,
        label: string,
    }[]
    basePath?: string,
}) {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="w-full px-4 py-3 flex flex-col gap-1">
            {menu.map((item, index) => {
                return (
                    <NavTab
                        key={index}
                        label={item.label}
                        selected={
                            location.pathname.endsWith( item.path ) ||
                            location.pathname.startsWith(item.path + "/")
                        }
                        onClick={() => {
                            navigate( `${basePath}${item.path}`);
                        }}
                    />
                );
            })}
        </div>
    );
}