import React from "react";
import { BookingPublicInfoV2, BookingInfoV2, BookingType, BookingInfoV2Req } from "../../common/models/Booking";
import InputMultiLine from "../../common/ui/InputMultiLine";
import Select from "../../common/ui/Select";
import Input from "../../common/ui/Input";
import CheckBox from "../../common/ui/CheckBox";
import PhotoSelect from "../../common/ui/PhotoSelect";
import IconButton from "../../common/ui/IconButton";
import axios from "axios";
import { getDefaultBookingInfoV2Req } from "../logic/utils";
import { handlePostBooking } from "../logic/api";
import { enqueueSnackbar } from "notistack";
import { bookingContext } from "../logic/bookingHook";
import { chatContext } from "../../chat/logic/chatContext";
import InfoWithLabel from "../../common/ui/InfoWithLabel";
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import dayjs from 'dayjs';
import { fDateTime } from "../../common/logic/time_utils";
import { getSession } from "../../auth/logic/callbacks";

export default function BookingCreateForm({
    onClose = () => { },
}: {
    onClose: () => void,
}) {

    const bCtx = React.useContext(chatContext);
    const [bookingsReq, setBookingsReq] = React.useState<BookingInfoV2Req[]>([]);
    const [isMultipleBooking, setIsMultipleBooking] = React.useState<boolean>(false);
    const [moreOptions, setMoreOptions] = React.useState<boolean>(false);


    /*
     *======= Init form states 
     *
     */
    React.useEffect(() => {
        console.log( "BookingCreateForm: useEffect(): ", bCtx?.viablePostContents, getSession() );
        
        /* If booking context is not valid, return null */
        if (bCtx === null
            || bCtx?.setViablePostContents.length === 0
            || bCtx?.postedContentsId >= bCtx?.viablePostContents.length) {
            return;
        }
        if (bCtx.postedContentsId >= 0) {
            // Post single content
            let bookingInfo = getDefaultBookingInfoV2Req();
            bookingInfo.content = bCtx.viablePostContents[bCtx.postedContentsId] as string;
            setBookingsReq([bookingInfo]);
            setIsMultipleBooking(false);
        }
        else {
            // Post all contents when postedContentsId is -1
            setBookingsReq(bCtx.viablePostContents.map((content) => {
                let bookingInfo = getDefaultBookingInfoV2Req();
                bookingInfo.content = content;
                return bookingInfo;
            }));
            setIsMultipleBooking(true);
        }
        setMoreOptions(false);
    }, [bCtx?.viablePostContents, bCtx?.postedContentsId, bCtx?.mainContent]);


    /*
     *======= Handle clicking posting bookings button =======
     *
     */
    const handleSubmit = () => {
        // Post bookings
        let failedPostIds = handlePostBooking(bookingsReq);
        if (failedPostIds.length === 0) {
            enqueueSnackbar("Đăng lịch thành công", { variant: "success" });
            onClose();
        }
        else {
            enqueueSnackbar("Đăng lịch thất bại", { variant: "error" });
        }
        // Remove successfully posted contents from viablePostContents
        bCtx?.onPostContentsFinished(failedPostIds);
    }


    return (
        <form
            className="w-full h-full flex flex-col gap-4 px-4 py-3 justify-between overflow-x-hidden overflow-y-scroll"
            onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
            }}
        >
            {/* Form Label */}
            <div className="py-3 flex flex-row justify-between items-start">
                <div className="text-xl font-semibold text-gray-700">
                    Đăng lịch xe mới
                </div>

                <IconButton
                    icon="ion:close-outline"
                    variant="mute"
                    onClick={(e) => {
                        e.preventDefault();
                        onClose();
                    }}
                />
            </div>

            <div className="w-full h-full flex flex-col gap-4">
                {/* Booking Content */}
                {
                    bookingsReq.map((bookingReq, index) => {
                        return(
                            <InfoWithLabel
                                label={`Nội dung chuyến xe ${bookingsReq.length > 1 ? index + 1 : ""}`}
                                info={bookingReq.content}
                                direction="column"
                                width="100%"
                                highlight={true}
                                multilines={true}
                            />
                        )
                    })
                }

                {/* Booking Route Type and Point cost */}
                <div className="w-full flex flex-row gap-4 items-center">
                    <Select
                        label="Loại lộ trình"
                        options={[{ value: BookingType.GOODBYE, label: "Tiễn" },
                        { value: BookingType.WELCOME, label: "Đón" },
                        { value: BookingType.PROVINCE, label: "Tỉnh" }]}
                        value={getDefaultBookingInfoV2Req().type}
                        onChange={(event) => {
                            let newBookingsReq = bookingsReq;
                            newBookingsReq.forEach((bookingReq) => {
                                bookingReq.type = event.target.value as BookingType;
                            });
                            setBookingsReq(newBookingsReq);
                        }}
                        width="50%"
                    />

                    <Select
                        label="Điểm bổ lịch"
                        options={
                            Array.from(
                                { length: 12 },
                                (_, index) => index
                                    ? { value: index * 0.25, label: `${index * 0.25} điểm` }
                                    : { value: 0, label: "Free" })}
                        value={getDefaultBookingInfoV2Req().point_cost}
                        onChange={(event) => {
                            let newBookingsReq = bookingsReq;
                            newBookingsReq.forEach((bookingReq) => {
                                bookingReq.point_cost = Number(event.target.value);
                            });
                            setBookingsReq(newBookingsReq);
                        }}
                        width="50%"
                    />
                </div>

                {/* Customer Phone Number */}
                {
                    !isMultipleBooking &&
                    <Input
                        label="Số điện thoại khách hàng"
                        value={""}
                        onChange={(event) => {
                            let newBookingsReq = bookingsReq;
                            newBookingsReq[0].client_phone = event.target.value;
                            setBookingsReq(newBookingsReq);
                        }}
                    />
                }

                {/* ============== Optional Section =============== */}
                {
                    !isMultipleBooking &&
                    (
                        <CheckBox
                            label="Thêm tuỳ chọn"
                            checked={moreOptions}
                            onChange={(event) => {
                                setMoreOptions(event.target.checked);
                            }}
                            style={{
                                marginTop: "1rem",
                            }}
                        />
                    )
                }
                

                {
                    !isMultipleBooking && moreOptions &&
                    <div className="w-full flex flex-col gap-4">
                        {/* Car seats and Pickup time */}
                        <Select
                            label="Loại xe"
                            options={[{ value: 4, label: "4 chỗ" },
                            { value: 5, label: "5 chỗ" },
                            { value: 7, label: "7 chỗ" },
                            { value: 16, label: "16 chỗ" }]}
                            value={getDefaultBookingInfoV2Req().seats}
                            width="100%"
                            onChange={(event) => {
                                let newBookingsReq = bookingsReq;
                                newBookingsReq.forEach((bookingReq) => {
                                    bookingReq.seats = parseInt(event.target.value);
                                });
                                // newBookingsReq[0].seats = parseInt(event.target.value);
                                setBookingsReq(newBookingsReq);

                                console.log( "-----> seats change", typeof event.target.value );
                            }}
                        />

                        <div className="w-full flex flex-col mt-1 gap-1">
                            <label className="text-sm font-medium text-gray-500">
                                Thời gian đón
                            </label>
                            <DesktopDateTimePicker
                                format="YY/MM/DD - HH:mm"
                                className="w-full text-sm"
                                value={ dayjs( new Date() )}
                                onChange={(value) => {
                                    let newBookingsReq = bookingsReq;
                                    newBookingsReq.forEach((bookingReq) => {
                                        bookingReq.start_time = fDateTime( value?.toDate() as Date );
                                    });
                                    setBookingsReq(newBookingsReq);
                                }}
                            />
                        </div>

                        {/* Note */}
                        <InputMultiLine
                            label="Ghi chú"
                            value={""}
                            onChange={(event) => {
                                let newBookingsReq = bookingsReq;
                                newBookingsReq[0].note = event.target.value;
                                setBookingsReq(newBookingsReq);
                            }}
                            rows={3}
                        />

                        {/* Photos */}
                        <PhotoSelect
                            label="Ảnh chụp"
                            value={""}
                            onChange={(event) => {
                                let newBookingsReq = bookingsReq;
                                newBookingsReq[0].image = event.target.value;
                                setBookingsReq(newBookingsReq);
                            }}
                            width="100px"
                        />
                    </div>
                }
            </div>

            <div className="pt-12 bg-white" >
                <button
                    type="submit"
                    className="w-full py-3 bg-green-500 text-white rounded-lg"
                >
                    {isMultipleBooking ? "Đăng hàng loạt" : "Đăng chuyến"}
                </button>
            </div>
        </form>
    )
}
