import React from "react";
import { fDateTime, secondsToNow } from "../../common/logic/time_utils";
import NavList from "../../common/ui/Nav/NavList";
import menu_json from "../../common/config/menu.json";
import { authContext } from "../../auth/logic/authHook";
import { Icon } from "@iconify/react";

var admin_sub_menu = menu_json[2].sub_menus;

export default function SideBarPersonalAccount() {

    const authCtx = React.useContext(authContext);

    // membership card styling
    const secDiff = -secondsToNow(authCtx?.authedUser?.expire_date!);
    const caution = secDiff < 0 ? "danger"
                    : secDiff < 3600*24*7 ? "warning"
                    : "safe";
    const mbsBorderClass = caution === "danger" ? "border border-red-500"
                        : caution === "warning" ? "border border-yellow-500"
                        : "border border-gray-200";
    const mbsBgrClass = caution === "danger" ? "bg-red-200"
                        : caution === "warning" ? "bg-yellow-200"
                        : "bg-white";
    const mbsTextClass = caution === "danger" ? "text-red-700"
                        : caution === "warning" ? "text-yellow-700"
                        : "text-gray-700";
    
    // profile card styling
    const profile = authCtx?.authedUser?.profile;
    const isNew = profile?.created_time === profile?.updateAt;
    const prfBorderClass = (profile === undefined || profile === null) ? "border border-gray-500"
                            : (profile.status === "pending") ? "border border-yellow-700"
                            : (profile.status === "accepted") ? "border border-gray-200"
                            : "border border-red-700";
    const prfBgrClass = (profile === undefined || profile === null) ? "bg-gray-200"
                            : (profile.status === "pending") ? "bg-yellow-200"
                            : (profile.status === "accepted") ? "bg-white"
                            : "bg-red-200";
    const prfTextClass = (profile === undefined || profile === null) ? "text-gray-700"
                            : (profile.status === "pending") ? "text-yellow-700"
                            : (profile.status === "accepted") ? "text-gray-700"
                            : "text-red-700";
    

    return (
        <div className="w-full h-full flex flex-col bg-gray-50 border-r border-gray-200">
            {admin_sub_menu &&
                <NavList
                    menu={admin_sub_menu}
                    basePath="/account"
                />
            }

            <div className="relative flex flex-col gap-2 p-4">
                {/* LOCK UNLOCK */}
                {
                    authCtx?.authedUser?.locked 
                    ? <div className="rounded-lg p-4 bg-red-700 text-white">
                        {`Tài khoản của bạn đã bị khóa. Vui lòng liên hệ với quản trị viên để được hỗ trợ.`}
                    </div>

                    : <div className="rounded-lg p-4 bg-green-500 text-white">
                        {`Tài khoản của bạn đang hoạt động bình thường.`}
                    </div>
                }


                {/* BALANCE */}
                <div className="relative flex flex-col gap-4 items-start border border-gray-200 rounded-lg p-4 bg-white">
                    <div className="text-sm font-medium capitalize text-gray-500">
                        SỐ DƯ ĐIỂM
                    </div>

                    <div className="text-3xl font-semibold text-green-700">
                        {`${authCtx?.authedUser?.point} điểm`}
                    </div>

                    { authCtx?.authedUser?.point &&
                        <div className="text-sm text-gray-400">
                            {`Số điểm khả dụng: ${authCtx?.authedUser?.point - authCtx?.authedUser?.pending_point} điểm`}
                        </div>
                    }

                    <Icon
                        className="absolute top-4 right-4 text-gray-500"
                        icon="solar:wallet-linear"
                        fontSize={24}
                    />
                </div>

                {/* MEMBERSHIP */}
                <div className={`relative flex flex-col gap-4 items-start ${mbsBorderClass} ${mbsBgrClass} rounded-lg p-4`}>
                    <div className="text-sm font-medium capitalize text-gray-500">
                        HẠN THÀNH VIÊN
                    </div>

                    <div className={`text-3xl font-semibold ${mbsTextClass}`}>
                        {caution === "danger" ? `Hết hạn`
                                              : `${Math.floor(secDiff / 3600 / 24)} ngày`
                        }
                    </div>

                    <div className="text-sm text-gray-400">
                        {`Hết hạn vào ngày ${fDateTime(authCtx?.authedUser?.expire_date!, "dd/MM/yyyy")}`}
                    </div>

                    <Icon
                        className="absolute top-4 right-4 text-gray-500"
                        icon="ic:baseline-card-membership"
                        fontSize={24}
                    />
                </div>

                {/* PROFILE */}
                <div className={`relative flex flex-col gap-4 items-start ${prfBorderClass} ${prfBgrClass} rounded-lg p-4`}>
                    <div className="text-sm font-medium capitalize text-gray-500">
                        HỒ SƠ TÀI XẾ
                    </div>

                    <div className={`text-3xl font-semibold ${prfTextClass}`}>
                        {(profile === undefined || profile === null) ? "Không hồ sơ"
                            : (profile.status === "pending") ? "Chờ duyệt"
                            : (profile.status === "accepted") ? "Đã thông qua"
                            : "Đã từ chối"
                        }
                    </div>

                    <div className="text-sm text-gray-400">
                        { isNew === undefined ? null
                         : (isNew === true) ? `Đăng ký ngày ${fDateTime(profile?.created_time!, "dd/MM/yyyy")}`
                         : `Cập nhật ngày ${fDateTime(profile?.created_time!, "dd/MM/yyyy")}`
                        }
                    </div>

                    <Icon
                        className="absolute top-4 right-4 text-gray-500"
                        icon="carbon:user-profile"
                        fontSize={24}
                    />
                </div>
            </div>
        </div>
    );
}