import React from "react";
import { Noti } from "../../models/Noti";

export default function NotiCard({
    noti,
}: {
    noti: Noti,
}) {

    return (
        <div className="flex flex-row gap-3 items-center px-3 py-2 rounded-md hover:bg-gray-100">
            {/* <div className="w-12 h-12 rounded-full bg-gray-200"></div> */}
            <div className="flex flex-col gap-1">
                <span className="font-semibold">{noti.notification.title}</span>
                <span className="text-sm text-gray-500">{noti.notification.body}</span>
            </div>
        </div>
    )
}