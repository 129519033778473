import Nav from './common/ui/Nav/Nav';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import React from 'react';

export default function App() {

  const navigate = useNavigate();
  const location = useLocation();
  
  React.useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/booking')
    {
      navigate('/booking/for-buy');
    }
  }, [location]);

  return (
    <div className="w-screen h-screen flex flex-row items-start justify-start">
      <Nav/>
      <Outlet/>
    </div>

  );
}


