import React from "react";
import { BankInfo } from "../../common/models/User";
import { authContext } from "../../auth/logic/authHook";
import AppBar from "../../common/ui/Nav/AppBar";
import { Icon } from "@iconify/react";
import BankSettingForm from "../forms/BankSettingForm";
import BankInfoCard from "../../common/ui/BankInfoCard";

export default function BankSettingPage () {

    const [bank, setBank] = React.useState<BankInfo | undefined>(undefined);
    const [showSidePopup, setShowSidePopup] = React.useState(false);
    const authCtx = React.useContext(authContext);

    React.useEffect(() => {
        setBank(authCtx?.authedUser?.bank);
    }, [authCtx?.authedUser]); 

    return (
        <div className="relative w-full h-full grid grid-cols-12 overflow-y-hiden">
            <div className={`${showSidePopup ? "col-span-8" : "col-span-12"} h-full flex flex-col overflow-y-hidden`}>

                <AppBar />

                <div className={`${showSidePopup ? "w-full" : "w-2/3"} h-full p-4 flex flex-row overflow-y-hidden`}>
                    {/* Indentity info */}
                    <div className="w-1/2 flex flex-col gap-4 p-4">
                        <div className="flex flex-row gap-8 items-center text-lg font-medium px-0">
                            Tài khoản ngân hàng

                            <Icon
                                icon="material-symbols:edit"
                                className="text-gray-400 cursor-pointer"
                                fontSize={20}
                                onClick={() => {
                                    setShowSidePopup(true);
                                }}
                            />
                        </div>

                        {
                            bank ? (
                                <BankInfoCard
                                    bankInfo={bank}
                                    showTitle={false}
                                />
                            ) : (
                                <div className="text-gray-400">
                                    Bạn chưa cập nhật thông tin tài khoản ngân hàng
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            {/* Side Popup */}
            <div className={`${showSidePopup ? "" : "hidden"} col-span-4 h-full flex flex-col-reverse border-l border-gray-200 overflow-y-scroll`}>

                <BankSettingForm
                    bank={bank}
                    onClose={() => setShowSidePopup(false)}
                    onUpdate={(bank_) => {
                        setBank(bank_);
                        setShowSidePopup(false);
                    }}
                />

            </div>
        </div>
    );
}