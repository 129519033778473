import React from "react";
import IconButton from "../../common/ui/IconButton";
import InfoWithLabel from "../../common/ui/InfoWithLabel";
import Image from "../../common/ui/Image";
import CarCard from "../../booking/components/CarCard";
import DriverInfoCard from "../../booking/components/DriverInfoCard";
import {
    UserInfo,
    UserPublicInfo,
    ApprovalDecision,
    ApprovalInfo,
    ApprovalStatus,
} from "../../common/models/User";
import { fDateTime } from "../../common/logic/time_utils";
import { getUserByPhone } from "../../common/logic/api";
import { getApprovalProfileByPhone } from "../logic/api";
import { enqueueSnackbar } from "notistack";
import AdminApprovalForm from "../forms/AdminApprovalForm";
import { authContext } from "../../auth/logic/authHook";


export default function SponsorProfileDetails({
    profile,
    onClose = () => { },
}: {
    profile: ApprovalInfo,
    onClose?: () => void,
}) {

    const [sponsor, setSponsor] = React.useState<UserPublicInfo | undefined>(undefined);
    const [approvalAction, setApprovalAction] = React.useState<ApprovalStatus.ACCEPTED | ApprovalStatus.REJECTED>(ApprovalStatus.ACCEPTED);
    const modalCheckboxRef = React.useRef<HTMLInputElement>(null);
    const authCtx = React.useContext(authContext);

    React.useEffect(() => {

        setSponsor( authCtx?.authedUser! );

    }, [profile]);

    return (
        <div className="w-full h-full flex flex-col gap-4 px-4 py-3 overflow-y-scroll">

            {/* Modal for showing approval form */}
            {
                profile &&
                (
                    <div>
                        <input
                            type="checkbox"
                            id="approval-form"
                            className="modal-toggle"
                            ref={modalCheckboxRef}
                        />
                        <label htmlFor="approval-form" className="modal cursor-pointer">
                            <label className="modal-box relative" htmlFor="">
                                <AdminApprovalForm
                                    approvalId={profile?._id!}
                                    type={approvalAction}
                                    onApprovedSuccessfully={(decision: ApprovalDecision) => {
                                        

                                        modalCheckboxRef.current?.click();
                                        onClose();
                                    }}
                                    onApprovaedFailed={() => {
                                        modalCheckboxRef.current?.click();
                                    }}
                                />
                            </label>
                        </label>
                    </div>
                )
            }

            {/* Form Title */}
            <div className="py-3 flex flex-row justify-between items-start">
                <div className="flex flex-col gap-2 items-start">
                    <div className="text-xl font-semibold text-gray-700">
                        Hồ sơ tài xế
                    </div>
                    <div className="text-sm text-left font-medium text-gray-500">
                        {`Tài khoản: ${profile.id_name} (${profile.id_number})`}
                    </div>
                </div>

                <IconButton
                    icon="ion:close-outline"
                    variant="mute"
                    onClick={() => {
                        onClose();
                    }}
                />
            </div>

            {/* Info about approval */
                profile && profile.status === ApprovalStatus.REJECTED
                    ? (<div className="alert alert-error shadow-lg">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                            <span>Hồ sơ đã bị phê duyệt từ chối</span>
                        </div>
                    </div>)

                    : profile && profile.status === ApprovalStatus.PENDING
                        ? (<div className="alert alert-warning shadow-lg">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                                <span>{`Hồ sơ ${profile.created_time === profile.updateAt ? 'mới' : 'chỉnh sửa'} đang chờ phê duyệt`}</span>
                            </div>
                        </div>)

                        : profile && profile.status === ApprovalStatus.ACCEPTED
                            ? (<div className="alert alert-success shadow-lg">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                                    <span>Hồ sơ đã được phê duyệt thông qua</span>
                                </div>
                            </div>)

                            : (<div className="alert shadow-lg">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info flex-shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    <span>Tài xế chưa có hồ sơ trong hệ thống</span>
                                </div>
                            </div>)
                    
            }

            {
                profile &&
                <div className="w-full flex flex-col gap-4">
                    {/* Identity */}
                    <div className="flex flex-col gap-2">
                        <div className="text-base font-medium px-0">
                            Thông tin định danh
                        </div>

                        <InfoWithLabel
                            label="Họ tên CCCD"
                            info={profile?.id_name ? profile.id_name : "N/A"}
                        />

                        <InfoWithLabel
                            label="Ngày sinh"
                            info={profile?.date_of_birth ? fDateTime(profile.date_of_birth, "dd/MM/yyyy") : "N/A"}
                        />

                        <InfoWithLabel
                            label="Giới tính"
                            info={profile?.sex === "male" ? "Nam" : (profile?.sex === "female" ? "Nữ" : "Khác")}
                        />

                        <InfoWithLabel
                            label="Số CMT/CCCD"
                            info={profile?.id_number ? profile.id_number : "N/A"}
                        />

                        {/* <InfoWithLabel
                            label="Ngày vào nhóm"
                            info={profile..join_date ? fDateTime(user.join_date, "dd/MM/yyyy") : "N/A"}
                        /> */}

                        <InfoWithLabel
                            label="Ảnh CCCD 2 mặt"
                            info={""}
                        />

                        <div className="w-full flex flex-row gap-4 overflow-x-scroll mt-2">
                            {profile?.id_photos![0] &&
                                <Image
                                    id={profile.id_photos[0]}
                                    alt="Ảnh mặt trước CMT/CCCD"
                                    className="w-80 h-40 rounded-lg border border-gray-300"
                                />}

                            {profile?.id_photos![1] &&
                                <Image
                                    id={profile.id_photos[1]}
                                    alt="Ảnh mặt trước CMT/CCCD"
                                    className="w-80 h-40 rounded-lg border border-gray-300"
                                />}

                        </div>
                    </div>

                    {/* Cars */}
                    <div className="flex flex-col gap-2">
                        <div className="text-base font-medium px-0">
                            Danh sách xe
                        </div>

                        {(profile?.vehicles?.length && profile?.vehicles.length > 0)
                            ? profile.vehicles.map((car) => (
                                <CarCard car={car} />
                            ))
                            : <div className="text-sm text-gray-500">
                                Không có xe nào
                            </div>
                        }
                    </div>

                    {/* Sponsor */}
                    <div className="flex flex-col gap-2 mt-4">
                        <div className="text-base font-medium px-0">
                            Người bảo lãnh
                        </div>

                        {sponsor ?
                            <DriverInfoCard
                                user={sponsor}
                                subtitle={""}
                                enableChat={false}
                                className="border border-gray-200 rounded-lg px-4"
                            />
                            : <div className="text-sm text-gray-500">
                                Không có
                            </div>
                        }

                    </div>

                    {/* Approval Button */}
                    {
                        profile?.status === ApprovalStatus.PENDING &&
                        <div className="flex flex-row gap-4 mt-12 mb-4">
                            <button
                                className="w-1/2 btn btn-error"
                                onClick={() => {
                                    setApprovalAction(ApprovalStatus.REJECTED);
                                    modalCheckboxRef.current?.click();
                                }}
                            >
                                Từ chối
                            </button>

                            <button
                                className="w-1/2 btn btn-success"
                                onClick={() => {
                                    setApprovalAction(ApprovalStatus.ACCEPTED);
                                    modalCheckboxRef.current?.click();
                                }}
                            >
                                Thông qua
                            </button>
                        </div>
                    }
                </div>
            }
        </div>
    )
}