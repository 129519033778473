import React from "react";
import { formatCurrency, vndCurrencyToNumber } from "../logic/utils";

export default function Input(
    {
        label = "",
        type = "text",
        placeholder = "",
        value = "",
        onChange = () => {},
        width = "100%",
    } : {
        label?: string,
        type?: string,
        placeholder?: string,
        value?: string | number,
        onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
        width?: string,
    }
) {

    const [_value, setValue] = React.useState<string | number>(value);

    React.useEffect(() => {
        setValue(value);
    }, [value]);

    return (
        <div 
            className="flex flex-col gap-1 items-start mt-1"
            style={{
                width: width,
            }}
        >
            {
                label &&
                <label className="text-sm font-medium text-gray-500">
                    {label}
                </label>
            }

            {
                type === "submit" ?
                <input
                    className="w-full py-3 bg-green-600 text-white rounded-md focus:outline-none font-semibold" 
                    type={type} 
                    value={_value}        
                />
                :
                <input 
                    className="w-full h-10 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                    type={type === 'currency' ? 'text' : type}
                    placeholder={placeholder}
                    value={type === 'currency' ? formatCurrency( _value as number ) : _value}
                    onChange={(event) => {
                        type === 'currency' 
                            ? setValue(vndCurrencyToNumber(event.target.value))
                            : setValue(event.target.value);
                        onChange(event);
                    }}
                />
            }
        </div>
    );
}
