import React from "react";


export default function InputMultiLine({
    label = "",
    placeholder = "",
    value = "",
    onChange = () => {},
    rows = 1,
    border = true,
}: {
    label?: string,
    placeholder?: string,
    value?: string,
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    rows?: number,
    border?: boolean,
}) {

    const [valueState, setValueState] = React.useState<string>(value);
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

    React.useEffect(() => {
        setValueState(value);
    }, [value]);

    React.useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto";
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
        }
    }, [valueState]);

    return (
        <div className="w-full flex flex-col gap-1 items-start mt-1">
            {
                label &&
                <label className="text-sm font-medium text-gray-500">
                    {label}
                </label>
            }

            <textarea
                className={
                    border
                    ? "w-full h-10 border px-2 py-2 rounded-md focus:outline-none focus:border-blue-500"
                    : "w-full h-10 py-2 focus:outline-none focus:border-none"
                }
                rows={rows}
                placeholder={placeholder}
                value={valueState}
                ref={textAreaRef}
                onChange={
                    (event) => {
                        setValueState(event.target.value);
                        onChange(event);
                    }
                }
            />
        </div>
    );
}