import axios from "axios";
import { UserInfo } from "../../common/models/User";


/*
 * Set or delete the session token in the local storage and in the axios headers
 *
 */
export const setSession = (accessToken: string ) => {
    if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
        axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    } else {
        throw new Error("Access token is not provided");
    }
};

export const deleteSession = () => {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
};

export const getSession = () => {
    return localStorage.getItem("accessToken");
};

/*
 * Session initialization callback
 *
 */
export const loginCallback = async (phone: string, password: string) : Promise<UserInfo | null> => {

    // Call login API and get the access token
    try {
        const response = await axios({
            url: "/login",
            method: "post",
            data: {
                phone_number: phone,
                password: password,
            },
        });
        setSession(response.data.token);

    } catch (error: any) {
        if (error.response) {
            if (error.response.status === 401) {
                // TODO: handle 401 code
                console.log("401 Error");
            }
        }

        return Promise.reject(error);
    }

    // Get user info
    try {
        const response = await axios({
            url: `/user`,
            method: "get",
        });
        return Promise.resolve(response.data);

    } catch (error: any) {
        if (error.response) {
            if (error.response.status === 401) {
                //TODO: handle 401 code
                console.log("401 Error");
            }
        }
        deleteSession();
        return Promise.reject(error);
    }
}


/*
 * Logout callback
 *
 */
export const logoutCallback = () => {
    deleteSession();
}