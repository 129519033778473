import React from "react";
import { UserPublicInfo, AccountType } from "../../common/models/User";
import Avatar from "../../common/ui/Avatar";
import { fDateTime } from "../../common/logic/time_utils";
import { formatCurrency } from "../../common/logic/utils";
import { Icon } from "@iconify/react";

function AdminRow({
    user,
    onViewProfile = () => { },
    onLock = () => { },
    onUnlock = () => { },
    onEditPrivilege = () => { },
}: {
    user: UserPublicInfo,
    onViewProfile?: (user: UserPublicInfo) => void,
    onLock?: (user: UserPublicInfo) => void,
    onUnlock?: (user: UserPublicInfo) => void,
    onEditPrivilege?: (user: UserPublicInfo) => void,
}) {

    return (
        <tr>
            {/* Avatar, name and phone */}
            <td>
                <Avatar
                    user={user}
                    size="large"
                    showTitle={true}
                    subtitle={user.phone_number}
                />
            </td>

            {/* Join Date */}
            <td>
                {user.join_date ? fDateTime(user.join_date, "dd/MM/yyyy") : "-"}
            </td>

            {/* Admin Level */}
            <td>
                {
                    user.type === AccountType.ADMIN_1
                        ? "Admin cấp 1"
                        : user.type === AccountType.ADMIN_2
                            ? "Admin cấp 2"
                            : user.type === AccountType.ADMIN_3
                                ? "Admin cấp 3"
                                : "Admin cấp 4"
                }
            </td>

            {/* Number of Pass Booking */}
            <td>{user.no_pass_bookings ? user.no_pass_bookings : 0}</td>

            {/* Number of Buy Booking */}
            <td>{user.no_bought_bookings ? user.no_bought_bookings : 0}</td>

            {/* Profile status */}
            <td>
                {
                    user.status === "pending"
                        ? <span className="badge badge-warning badge-sm">Chờ duyệt</span>
                        : user.status === "activated"
                            ? <span className="badge badge-success badge-sm">Đã duyệt</span>
                            : user.status === "no_profile"
                                ? <span className="badge badge-ghost badge-sm">Không hồ sơ</span>
                                : <span className="badge badge-error badge-sm">Tạm khóa</span>
                }
            </td>

            {/* Locked status */}
            <td>
                {user.locked
                    ? <Icon
                        icon="material-symbols:lock"
                        color="#f44336"
                        fontSize={20}
                    />
                    : <Icon
                        icon="material-symbols:lock-open-rounded"
                        color="#4caf50"
                        fontSize={20}
                    />


                }
            </td>

            {/* Details button */}
            <th>
                <div className="flex flex-row gap-4">
                    <div className="dropdown dropdown-end">
                        <label tabIndex={0} className="btn btn-ghost btn-xs m-1">
                            Đổi cấp
                        </label>
                        <ul tabIndex={0} className="text-sm font-medium dropdown-content menu p-2 shadow bg-base-100 rounded-box w-48">
                            <li>
                                <a
                                    onClick={() => onViewProfile(user)}
                                >
                                    Admin cấp 1
                                </a>
                            </li>
                            <li>
                                <a
                                    onClick={() => {
                                        if (user.status === "activated" && !user.locked) {
                                            onLock(user);
                                        } else {
                                            onUnlock(user);
                                        }
                                    }}
                                >
                                    Admin cấp 2
                                </a>
                            </li>

                            <li>
                                <a
                                    onClick={() => onEditPrivilege(user)}
                                >
                                    Admin cấp 3
                                </a>
                            </li>

                            <li>
                                <a
                                    onClick={() => onEditPrivilege(user)}
                                >
                                    Admin cấp 4
                                </a>
                            </li>
                        </ul>
                    </div>

                    <button className="btn btn-ghost btn-xs">
                        <Icon
                            icon="mingcute:delete-2-line"
                            className="text-red-700"
                            fontSize={24}
                        />
                    </button>
                </div>
            </th>
        </tr>
    )
}


export default React.memo(AdminRow)