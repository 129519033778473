import React from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import SideBarPersonalAccount from "../components/SideBarPersonalAccount";
import { authContext } from "../../auth/logic/authHook";

export default function PersonalPageLayout() {

    const navigate = useNavigate();
    const location = useLocation();
    const authCtx = React.useContext(authContext);

    // Reload user info before displaying the page
    React.useEffect(() => {
        authCtx?.reloadMe();
    }, []);

    React.useEffect(() => {
        if (location.pathname === '/account') {
            navigate('/account/my-wallet');
        }
    }, [location]);
        

    return (
        <div className="w-full h-full grid grid-cols-10 overflow-y-hidden">
            <div className="col-span-2 h-full">
                < SideBarPersonalAccount />
            </div >
            <div className="col-span-8 h-full overflow-y-hidden">
                <Outlet />
            </div >
        </div>
    );
}