import {UserPublicInfo} from "./User";
import {CarInfo} from "./Car";

export enum BookingType {
    WELCOME = "welcome",
    GOODBYE = "goodbye",
    PROVINCE = "province",
}

export enum BookingStatus {
    PENDING = "pending",
    ACTIVATED = "activated",
    CANCELED = "canceled",
    FINISHED = "finished",
}

export interface BookingInfoV2Req {
    // Basic info
    content: string;
    type: BookingType;
    go_now: boolean;
    point_cost: number;
    client_phone?: string;
    start_time?: string;
    note?: string;
    allow_taxi: boolean;
    highway_fee: boolean;
    one_way: boolean;
    round_trip: boolean;
    name_board_required: boolean;
    seats: number;
    image?: string;            // $uuid

    // urls 
    image_url?: string;
}

export interface BookingPublicInfoV2 extends BookingInfoV2Req {
    // Index
    _id: string;                // $uuid
    short_id: string;           // $uuid
    user_creator: UserPublicInfo;

    // Basic info
    created_time: string;       // $date
    update_at: string;          // $date
    status: BookingStatus;      // $enum

    // More details info
    start_time?: string;
    note?: string;
    image?: string;             // $uuid

    // Complaination
    complain_id?: string;       // $uuid
}


export interface BookingInfoV2 extends BookingPublicInfoV2 {
    
    buyer: UserPublicInfo;
    buy_time: string;          // $date
    finish_time?: string;      // $date
    vehicle?: CarInfo;
}