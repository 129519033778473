import React from "react";
import AppBar from "../../common/ui/Nav/AppBar";
import Select from "../../common/ui/Select";
import SearchBox from "../../common/ui/SearchBox";
import AccountRow from "../components/AccountRow";
import ProfileDetail from "../components/ProfileDetails";
import { UserInfo } from "../../common/models/User";
import { getAdmins } from "../logic/api";
import { useSnackbar } from "notistack";
import AdminRow from "../components/AdminRow";

export default function AdminsManaPage() {

    const [users, setUsers] = React.useState<UserInfo[]>([]);
    const [selectedUser, setSelectedUser] = React.useState<UserInfo | undefined>(undefined);
    const [showSidePopup, setShowSidePopup] = React.useState(false);
    const {enqueueSnackbar} = useSnackbar();

    // Load list of admin
    React.useEffect(() => {
        getAdmins()
        .then((res) => {
            setUsers(res.data);
        })
        .catch((err) => {
            enqueueSnackbar( `Lỗi tải thông tin tài khoản admin: ${err as string}`, { variant: "error" });
        });
    }, []);

    return (
        <div className="w-full h-full grid grid-cols-12 overflow-y-hiden">
            <div className={`${showSidePopup ? "col-span-8" : "col-span-12"} h-full flex flex-col overflow-y-hidden`}>
                <AppBar />

                {/* Table of Admins */}
                <div className="w-full h-full flex flex-col px-4 py-6 overflow-y-scroll">
                    <table className="table w-full">
                        <thead className="sticky top-0">
                            <tr>
                                <th>Tài khoản admin</th>
                                <th>Ngày gia nhập</th>
                                <th>Cấp Admin</th>
                                <th># lịch đăng</th>
                                <th># lịch bổ</th>
                                <th>Hồ sơ</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody
                            className="overflow-y-scroll"
                        >
                            {
                                users.map((user) => (
                                    <AdminRow
                                        user={user}
                                        // onViewProfile={handleViewProfile}
                                    />
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Detail of admin */}
            <div className={`${showSidePopup ? "flex" : "hidden"} col-span-4 h-full flex flex-col-reverse border-l border-gray-200 overflow-y-scroll`}>
                
            </div>
        </div>
    );
}