import React from "react";

function Select({
    label = "",
    options = [],
    value = "",
    onChange = () => {},
    width = "100%",
}: {
    label?: string,
    options?: {label: string, value: any}[],
    value?: any,
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void,
    width?: string,
}) {

    const [valueState, setValueState] = React.useState<any>(value);

    React.useEffect(() => {
        console.log("-----> I am being rerendered: ", value);
        if(value !== "" && value !== valueState){
            setValueState(value);
        }
        if(value === "" && options.length > 0) {
            setValueState(options[0].value);
        }
    }, [value, options]);

    return (
        <div 
            className="flex flex-col gap-1 items-start"
            style={{
                width: width,
            }}
        >
            {
                label &&
                <label className="text-sm font-medium text-gray-500">
                    {label}
                </label>
            }

            <select
                className="w-full h-10 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                value={valueState}
                onChange={(event) => {
                    setValueState(event.target.value);
                    onChange(event);
                }}
            >
                {
                    options.map((option, index) => (
                        <option key={index} value={option.value}>{option.label}</option>
                    ))
                }
            </select>
        </div>
    );
}

export default Select;