import React from "react";
import { Link } from "react-router-dom";

export default function NavTab({
    label,
    selected,
    onClick = () => {},
} : {
    link?: string,
    label?: string,
    selected?: boolean,
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
}) {

    let addedClassName = selected
                        ? "bg-green-100 font-bold text-green-500"
                        : "bg-transparent font-regular text-black hover:font-semibold hover:text-green-500";

    return (
        <div 
            className={`w-full h-14 pl-5 flex flex-row items-center gap-4 border-0 rounded-lg text-base ${addedClassName}`}
            onClick={onClick}
        >
            {label}
        </div>
    );
}