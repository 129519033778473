import React from "react";
import { UserInfo } from "../../common/models/User";
import { BookingPublicInfoV2 } from "../../common/models/Booking";
import { getUserBookings } from "../../booking/logic/api";
import { shouldLoadMore } from "../../common/logic/utils";
import BookingCard from "../../booking/components/BookingCard";
import ToogleButton from "../../common/ui/ToogleButton";
import IconButton from "../../common/ui/IconButton";
import { useSnackbar } from "notistack";


export default function BookingsHistorySidebar({
    ofUser,
    onClose = () => { },
}: {
    ofUser?: UserInfo,
    onClose?: () => void,
}) {

    const [boughtBookings, setBoughtBookings] = React.useState<BookingPublicInfoV2[]>([]);
    const [boughtBookingPage, setBoughtBookingPage] = React.useState<number>(0);
    const [stopLoadMoreBoughtBookings, setStopLoadMoreBoughtBookings] = React.useState<boolean>(false);
    const [passBookings, setPassBookings] = React.useState<BookingPublicInfoV2[]>([]);
    const [passBookingPage, setPassBookingPage] = React.useState<number>(0);
    const [stopLoadMorePassBookings, setStopLoadMorePassBookings] = React.useState<boolean>(false);
    const [type, setType] = React.useState<"buy" | "pass">("pass");
    const { enqueueSnackbar } = useSnackbar();

    // Load list of bookings
    React.useEffect(() => {
        if (ofUser === undefined) return;

        const bookings = type === "buy" ? boughtBookings : passBookings;

        if (bookings && bookings?.length > 0) 
            return;

        getUserBookings(
            0,                      // page
            20,                     // page size
            type,                   // type
            ofUser.phone_number,    // phone number
        )
            .then((res) => {
                if (type === "buy") {
                    setBoughtBookings(res.data.buy);
                    setBoughtBookingPage(1);
                }
                else {
                    setPassBookings(res.data.pass);
                    setPassBookingPage(1);
                }
            })
            .catch((err) => {
                enqueueSnackbar(`Lỗi tải danh sách lịch sử đăng bổ: ${err.response.data}`, { variant: "error" });
            });

    }, [ofUser, type]);


    /*
    *======= Handle load more account
    *
    */
    const handleLoadMoreBookings = () => {

        if (ofUser === undefined) return;

        getUserBookings(
            type === "buy" ? boughtBookingPage : passBookingPage,                      // page
            20,                     // page size
            type,                   // type
            ofUser.phone_number,    // phone number
        )
            .then((res) => {
                if (type === "buy") {
                    if (res.data.length === 0)
                        setStopLoadMoreBoughtBookings(true);
                    else {
                        const concatBoughtBookings = boughtBookings.concat(res.data.buy);
                        setBoughtBookings(concatBoughtBookings);
                        setBoughtBookingPage(boughtBookingPage + 1);
                    }
                }
                else {
                    if (res.data.length === 0)
                        setStopLoadMorePassBookings(true);
                    else {
                        const concatPassBookings = passBookings.concat(res.data.pass);
                        setPassBookings(concatPassBookings);
                        setPassBookingPage(passBookingPage + 1);
                    }
                }
            })
            .catch((err) => {
                enqueueSnackbar(`Lỗi tải danh sách lịch sử đăng bổ: ${err.response.data}`, { variant: "error" });
            });
    }


    if (ofUser === undefined) return null;

    return (
        <div className="w-full h-full flex flex-col gap-4 px-4 py-3 overflow-y-hidden">

            {/* Form Title */}
            <div className="py-3 flex flex-row justify-between items-start">
                <div className="flex flex-col gap-2 items-start">
                    <div className="text-xl font-semibold text-gray-700">
                        Lịch sử đăng bổ
                    </div>
                    <div className="text-sm text-left font-medium text-gray-500">
                        {`Tài khoản: ${ofUser.name} (${ofUser.phone_number})`}
                    </div>
                </div>

                <IconButton
                    icon="ion:close-outline"
                    variant="mute"
                    onClick={() => {
                        onClose();
                    }}
                />
            </div>


            {/* Filter bookings by type */}
            <div className="flex flex-row gap-2">
                <ToogleButton
                    text="Lịch đăng"
                    value={type === "pass"}
                    onChange={(value) => {
                        if (value) setType("pass");
                    }}
                />

                <ToogleButton
                    text="Lịch bổ"
                    value={type === "buy"}
                    onChange={(value) => {
                        if (value) setType("buy");
                    }}
                />
            </div>

            {/* List of bookings */}
            <div
                className="flex flex-col gap-2 overflow-y-scroll"
                onScroll={(event) => {
                    const ele = event.target as HTMLDivElement;
                    if (shouldLoadMore(
                        ele.scrollHeight,
                        ele.scrollTop,
                        ele.clientHeight,
                        false,
                        1
                    )
                    ) {
                        handleLoadMoreBookings();
                    }
                }}
            >
                {
                    (type === "buy" && boughtBookings && boughtBookings?.length > 0)
                        ? boughtBookings.map((booking, index) => (
                            <BookingCard
                                key={booking._id}
                                booking={booking}
                                show_status={true}
                            />
                        ))
                        : (type === "pass" && passBookings && passBookings?.length > 0)
                            ? passBookings.map((booking, index) => (
                                <BookingCard
                                    key={booking._id}
                                    booking={booking}
                                    show_status={true}
                                />
                            ))
                            : null
                }
            </div>
        </div>
    )
}