import React from "react";
import { ApprovalInfo, ApprovalStatus } from "../../common/models/User";
import InputMultiLine from "../../common/ui/InputMultiLine";
import { UserInfo, ApprovalDecision } from "../../common/models/User";
import { BookingType } from "../../common/models/Booking";
import ToogleButton from "../../common/ui/ToogleButton";
import Select from "../../common/ui/Select";
import Input from "../../common/ui/Input";
import { vndCurrencyToNumber } from "../../common/logic/utils";
import { lockAccount, unlockAccount } from "../logic/api";
import { useSnackbar } from "notistack";


const defaultPreviledge: ApprovalDecision = {
    accept: false,
    note: "",
    rule: "admin",
    buy_type: [BookingType.GOODBYE, BookingType.WELCOME, BookingType.PROVINCE],
    deposit: 0,
    subscription: "none"
}


export default function LockUnlockAccountForm({
    user,
    action = "lock",
    onLockUnlockSuccessfully = () => { },
    onLockUnlockFailed = () => { },
}: {
    user: UserInfo,
    action?: "lock" | "unlock",
    onLockUnlockSuccessfully?: () => void,
    onLockUnlockFailed?: () => void,
}) {

    const [lockTime, setLockTime] = React.useState<"1_day" | "2_day" | "3_day" | "1_week" | "2_week" | "1_month" | "forever">("1_day");
    const [note, setNote] = React.useState<string>("");
    const { enqueueSnackbar } = useSnackbar();

    const handleSumit = () => {
        if (action === "lock") {
            lockAccount(user.phone_number, lockTime, note)
                .then((res) => {
                    enqueueSnackbar("Khoá tài khoản thành công", { variant: "success" });
                    onLockUnlockSuccessfully();
                })
                .catch((err) => {
                    enqueueSnackbar("Khoá tài khoản thất bại", { variant: "error" });
                    onLockUnlockFailed();
                });
        }
        else {
            unlockAccount(user.phone_number)
                .then((res) => {
                    enqueueSnackbar("Mở khóa tài khoản thành công", { variant: "success" });
                    onLockUnlockSuccessfully();
                })
                .catch((err) => {
                    enqueueSnackbar("Mở khóa tài khoản thất bại", { variant: "error" });
                    onLockUnlockFailed();
                });
        }
    }

    return (
        <div
            className="w-full h-full bg-white flex flex-col gap-4 rounded-lg"
        >
            <div className="text-base font-semibold text-left">
                {
                    action === "lock" ? "Khóa tài khoản" : "Mở khóa tài khoản"
                }
            </div>


            {/* Membership grant */
                action === "lock" &&
                <Select
                    label="Thời gian khoá tài khoản"
                    options={[
                        { value: "1_day", label: "Khoá 1 ngày" },
                        { value: "2_day", label: "Khoá 2 ngày" },
                        { value: "3_day", label: "Khoá 3 ngày" },
                        { value: "1_week", label: "Khoá 1 tuần" },
                        { value: "2_week", label: "Khoá 2 tuần" },
                        { value: "1_month", label: "Khoá 1 tháng" },
                        { value: "forever", label: "Khoá đến khi được mở lại" },
                    ]}
                    value={lockTime}
                    onChange={event => setLockTime(event.target.value as any)}
                />
            }

            {/* Note */}
            <InputMultiLine
                placeholder="Ghi chú cho tài xế"
                value={note}
                onChange={event => setNote(event.target.value)}
                rows={3}
            />

            <button
                className={`btn btn-block ${action === "lock" ? 'btn-error' : 'btn-success'}`}
                onClick={handleSumit}
            >
                {action === "lock" ? "Xác nhận khóa tài khoản" : "Xác nhận mở khóa tài khoản"}
            </button>
        </div>
    )
}