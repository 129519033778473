import React from "react";

export default function ToogleButton({
    text,
    value,
    onChange = () => {},
}:{
    text: string,
    value: boolean,
    onChange?: (value: boolean) => void,
}) {
    
        const [checked, setChecked] = React.useState<boolean>(value);

        React.useEffect(() => {
            setChecked(value);
        }, [value]);
    
        return (
            checked 
            ? <button
                className={`px-3 py-2 border border-green-600 text-green-600 bg-green-50 no-animation rounded-md`}
                onClick={() => {
                    setChecked(false);
                    onChange(false);
                }}
            >
                {text}
            </button>

            : <button
                className={`px-3 py-2 border border-gray-400 text-gray-500 bg-gray-50 no-animation rounded-md`}
                onClick={() => {
                    setChecked(true);
                    onChange(true);
                }}
            >
                {text}
            </button>
        )
}