import React from "react";
import { getKPIs } from "../logic/api";
import { useSnackbar } from "notistack";
import { formatCurrency } from "../../common/logic/utils";


export default function KPICards( ) {

    const [kpi, setKPI] = React.useState(null);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        getKPIs()
        .then((kpi) => {
            setKPI(kpi);
        })
        .catch((err) => {
            setKPI(null);
            enqueueSnackbar( `Lỗi trả về dữ liệu báo cáo: ${err.response.data}`, {variant: "error"});
        });
    }, []);

    return kpi && (
        <div className="relative w-full flex flex-col gap-2 px-4 py-3">

            {/* GROUP BUDGET */}
            <div className="relative flex flex-col gap-4 items-start border border-gray-200 rounded-lg p-4 bg-white">
                <div className="text-sm font-medium capitalize text-gray-500">
                    TỔNG QUỸ NHÓM
                </div>

                <div className="text-3xl font-semibold text-blue-700">
                    {formatCurrency(kpi["group"]["total"])}
                </div>

                <div className="flex flex-row gap-4 items-end">
                    <div className="flex flex-row gap-1 items-baseline">
                        <div className="text-xs text-gray-400">
                            Điểm
                        </div>
                        <div className="text-sm font-medium text-gray-700">
                            {kpi["group"]["point"]}
                        </div>
                    </div>

                    <div className="flex flex-row gap-1 items-baseline">
                        <div className="text-xs text-gray-400">
                            Đặt cọc
                        </div>
                        <div className="text-sm font-medium text-gray-700">
                            {formatCurrency(kpi["group"]["deposit"])}
                        </div>
                    </div>
                </div>

                <img 
                    className="absolute top-4 right-4 w-6 h-6" 
                    src={`${process.env.PUBLIC_URL}/icons/wallet_fill.svg`} 
                    alt="Tổng quỹ nhóm" 
                />
            </div>

            {/* GROUP CASH FLOW */}
            <div className="relative flex flex-col gap-4 items-start border border-gray-200 rounded-lg p-4 bg-white">
                <div className="text-sm font-medium capitalize text-gray-500">
                    DÒNG TIỀN THÁNG
                </div>

                <div className="text-3xl font-semibold text-green-700">
                    {formatCurrency(kpi["monthly"]["total"])}
                </div>

                <div className="flex flex-row gap-4 items-end">
                    <div className="flex flex-col gap-1 items-baseline">
                        <div className="text-xs text-gray-500">
                            Gia hạn
                        </div>
                        <div className="text-sm font-semibold text-green-700">
                            {formatCurrency(kpi["monthly"]["subscription"])}
                        </div>
                    </div>

                    <div className="flex flex-col gap-1 items-baseline">
                        <div className="text-xs text-gray-500">
                            Nạp điểm
                        </div>
                        <div className="text-sm font-semibold text-green-700">
                            {formatCurrency(kpi["monthly"]["top_up"])}
                        </div>
                    </div>

                    <div className="flex flex-col gap-1 items-baseline">
                        <div className="text-xs text-gray-500">
                            Rút điểm
                        </div>
                        <div className="text-sm font-semibold text-red-700">
                            {formatCurrency(kpi["monthly"]["withdraw"])}
                        </div>
                    </div>
                </div>

                <img 
                    className="absolute top-4 right-4 w-6 h-6" 
                    src={`${process.env.PUBLIC_URL}/icons/sale_tag.svg`} 
                    alt="Tổng quỹ nhóm" 
                />
            </div>

            {/* MEMBERSHIP */}
            <div className="relative flex flex-col gap-4 items-start border border-gray-200 rounded-lg p-4 bg-white">
                <div className="text-sm font-medium capitalize text-gray-500">
                    SỐ THÀNH VIÊN NHÓM
                </div>

                <div className="text-3xl font-semibold text-gray-700">
                    {kpi["member"]["total"]}
                </div>

                <div className="flex flex-row gap-4 items-end">
                    <div className="flex flex-col gap-1 items-baseline">
                        <div className="text-xs text-gray-400">
                            Hoạt động
                        </div>
                        <div className="text-sm font-medium text-blue-700">
                            {kpi["member"]["activated"] || '0'}
                        </div>
                    </div>

                    <div className="flex flex-col gap-1 items-baseline">
                        <div className="text-xs text-gray-400">
                            Chờ duyệt
                        </div>
                        <div className="text-sm font-medium text-yellow-700">
                            {kpi["member"]["pending"] || '0'}
                        </div>
                    </div>

                    <div className="flex flex-col gap-1 items-baseline">
                        <div className="text-xs text-gray-400">
                            Tạm khóa
                        </div>
                        <div className="text-sm font-medium text-red-700">
                            {kpi["member"]["locked"] || '0'}
                        </div>
                    </div>

                    <div className="flex flex-col gap-1 items-baseline">
                        <div className="text-xs text-gray-400">
                            Không hồ sơ
                        </div>
                        <div className="text-sm font-medium text-gray-500">
                            {kpi["member"]["no_profile"] || '0'}
                        </div>
                    </div>
                </div>

                <img 
                    className="absolute top-4 right-4 w-6 h-6" 
                    src={`${process.env.PUBLIC_URL}/icons/group_fill.svg`} 
                    alt="Tổng quỹ nhóm" 
                />
            </div>

            {/* MEMBERSHIP */}
            <div className="relative flex flex-col gap-4 items-start border border-gray-200 rounded-lg p-4 bg-white">
                <div className="text-sm font-medium capitalize text-gray-500">
                    TỔNG SỐ CHUYẾN PASS
                </div>

                <div className="text-3xl font-semibold text-gray-700">
                    {kpi["booking"]["total"]}
                </div>

                <div className="flex flex-row gap-4 items-end">
                    <div className="flex flex-row gap-1 items-baseline">
                        <div className="text-xs text-gray-400">
                            Hoàn thành
                        </div>
                        <div className="text-sm font-medium text-blue-700">
                            {kpi["booking"]["completed"]}
                        </div>
                    </div>

                    <div className="flex flex-row gap-1 items-baseline">
                        <div className="text-xs text-gray-400">
                            Khiếu nại
                        </div>
                        <div className="text-sm font-medium text-yellow-700">
                            {kpi["booking"]["complain"]}
                        </div>
                    </div>
                </div>

                <img 
                    className="absolute top-4 right-4 w-6 h-6" 
                    src={`${process.env.PUBLIC_URL}/icons/arrow_circle_up_fill.svg`} 
                    alt="Tổng quỹ nhóm" 
                />
            </div>
        </div>
    )
}