import React from "react";
import {
    appContext,
    getUserFromAppContext,
    saveUserToAppContext,
    asyncPreloadUserAvatar,
} from "../logic/appContext";
import { UserPublicInfo } from "../models/User";
import { getUserByPhone } from "../logic/api";

export default function Avatar({
    src = "",
    user = undefined,
    userPhone = "",
    title = "",
    subtitle = "",
    size = "medium",
    showTitle = true,
    width = "fit-content",
    onClick = () => {},
} : {
    src?: string,
    user?: UserPublicInfo | undefined,
    userPhone?: string,
    title?: string,
    subtitle?: string,
    size?: "small" | "medium" | "large" | "xlarge",
    showTitle?: boolean,
    width?: string,
    onClick?: () => void,
}) {

    const appCtx = React.useContext(appContext);
    const [avatarData, setAvatarData] = React.useState<string | undefined>(undefined);

    /* Download image */
    React.useEffect(() => {
        if( user && user.avatar_data_string )
        {
            setAvatarData(user.avatar_data_string);
            return
        }
        
        if( user && appCtx) {
            const savedUser = getUserFromAppContext(user?.phone_number, appCtx.users);
            if (!savedUser) {
                if( user.avatar !== undefined && user.avatar !== "") {
                    asyncPreloadUserAvatar(user)
                    .then((tmpUser) => {
                        if( tmpUser !== undefined ) {
                            setAvatarData(tmpUser.avatar_data_string);
                            saveUserToAppContext( [tmpUser], appCtx.users, appCtx.setUsers);
                        }
                        else {
                            setAvatarData(user.avatar_data_string);
                        }
                    })
                    .catch((err) => {
                        setAvatarData(undefined);
                    });
                }
                else {
                    saveUserToAppContext( [user as UserPublicInfo], appCtx.users, appCtx.setUsers);
                    setAvatarData(user.avatar_data_string);
                }
            }
            else {
                setAvatarData(savedUser.avatar_data_string);
            }
        }
        else {
            setAvatarData(src);
        }
    }, [src, user]);
    

    // Convert size to tailwind css class
    let avatarSize = "w-7 h-7";
    let labelSize = "text-xl";
    let titleSize = "text-sm";
    switch (size) {
        case "small":
            avatarSize = "w-5 h-5";
            labelSize = "text-base";
            titleSize = "text-xs";
            break;
        case "medium":
            break;
        case "large":
            avatarSize = "w-9 h-9";
            labelSize = "text-2xl";
            titleSize = "text-base";
            break;
        case "xlarge":
            avatarSize = "w-12 h-12";
            labelSize = "text-3xl";
            titleSize = "text-base";
            break;
        default:
            break;
    }

    // Get image or name avartar
    let avatar = null;
    if (avatarData !== undefined && avatarData !== "") {
        avatar = (
            // <img className={`rounded-full ${avatarSize}`} src={src} alt="avatar" />
            // <AuthedImg className={`rounded-full ${avatarSize}`} url={src} alt="avatar" />
            <img className={`rounded-full ${avatarSize}`} src={avatarData} alt={"avatar"} />
            // <div className="avatar online">
            //     <div className={`rounded-full ${avatarSize}`}>
            //         <img src={avatarData} alt={"avatar"}/>
            //     </div>
            // </div>
        );
    } else {
        avatar = (
            <img className={`rounded-full ${avatarSize}`} 
                src={`${process.env.PUBLIC_URL}/images/avatar_placeholder.svg`}
                alt="avatar" />

            // <div className="avatar online">
            //     <div className={`rounded-full ${avatarSize}`}>
            //         <img src={`${process.env.PUBLIC_URL}/images/avatar_placeholder.svg`} alt={"avatar"}/>
            //     </div>
            // </div>
        );
    }

    return (
        <div 
            className="flex flex-row gap-2 items-center rounded"
            style={{
                width: width,
            }}
            onClick={onClick}
        >
            {avatar}

            { showTitle && 
                <div className="flex flex-col gap-1 items-start">
                    <span className={`font-medium ${titleSize}`}>
                        {(title !== "")? title : user?.name}
                    </span>
                    <span className="text-xs text-gray-500">
                        {subtitle}
                    </span>
                </div>
            }
        </div>
    );
}
