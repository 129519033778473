import React from "react";
import { BookingPublicInfoV2 } from "../../common/models/Booking";
import { 
    ConversationInfo, 
    Message 
} from "../../common/models/Chat";
import axios from "axios";
import { io, Socket } from "socket.io-client";
import { useSnackbar } from "notistack";
import { getSession } from "../../auth/logic/callbacks";
import { fDateTime } from "../../common/logic/time_utils";
import { useLocation, useSearchParams } from "react-router-dom";


/*
 *======= Interface for chat page context variables
 *
 */
export interface ChatContext {
    conversations: ConversationInfo[],
    setConversations: React.Dispatch<React.SetStateAction<ConversationInfo[]>>,
    selectConversionId: string | undefined,
    setSelectConversionId: React.Dispatch<React.SetStateAction<string | undefined>>,
    updateConversation: (newConv: ConversationInfo) => void,
    loadMoreChatThreads: () => void,
    conversation: ConversationInfo | undefined,
    socket: Socket,

    // for booking
    viableBuyBookings: BookingPublicInfoV2[];
    setViableBuyBookings: React.Dispatch<React.SetStateAction<BookingPublicInfoV2[]>>;
    mainContent: string;
    setMainContent: React.Dispatch<React.SetStateAction<string>>;
    viablePostContents: string[];
    setViablePostContents: React.Dispatch<React.SetStateAction<string[]>>;
    postedContentsId: number;
    setPostedContentsId: React.Dispatch<React.SetStateAction<number>>;
    onPostContentsFinished: (failedPostIds: number[]) => void;
}


export const chatContext = React.createContext<ChatContext | null>(null);



/*
 *======= Chat context provider for chat page
 *
 */
export default function ChatContextProvider({ children }: { children: React.ReactNode }) {

    const [mainContent, setMainContent] = React.useState<string>("");
    const [viableBuyBookings, setViableBuyBookings] = React.useState<BookingPublicInfoV2[]>([]);
    const [viablePostContents, setViablePostContents] = React.useState<string[]>([]);
    const [postedContentsId, setPostedContentsId] = React.useState<number>(-1);
    
    const [conversations, setConversations] = React.useState<ConversationInfo[]>([]);
    const [selectConversionId, setSelectConversionId] = React.useState<string | undefined>(undefined);
    const [conversation, setConversation] = React.useState<ConversationInfo | undefined>(undefined);
    const [currPage, setCurrPage] = React.useState<number>(0);
    // const [socket, setSocket] = React.useState<Socket>(_socket);
    const query = new URLSearchParams(useLocation().search);
    const { enqueueSnackbar } = useSnackbar();

    /* Socket.io for updating booking list realtime */
    const socket = io(
        // "wss://taxi.zootopi.dev",
        "wss://api.xemienbac.com.vn",
        {
            transports: ["websocket"],
            // path: "/socket.io",
            auth: {
                token: getSession(),
            },
        }
    );

    /* Init booking context */
    React.useEffect(() => {

        /* Get all bookings when page is loaded */
        let bookings = [] as BookingPublicInfoV2[];
        axios.get("/v2/booking/public")
            .then(res => {
                setViableBuyBookings(res.data.map(
                    (booking: any) => {
                        let newBooking = booking as BookingPublicInfoV2;
                        axios.get(`/files/${newBooking.user_creator.avatar}`)
                            .then(res => {
                                newBooking.user_creator.avatar_url = res.data.url;
                            })
                            .catch(err => {
                                newBooking.user_creator.avatar_url = "";
                            })
                        return newBooking;
                    }
                )
                    );
            })
            .catch(err => {
                enqueueSnackbar(`Không thể lấy danh sách booking với lý do: ${err.response.data}`, { variant: "error" });
            })

        /* Handle socket broadcast new booking */
        socket.on("add-booking", (data: any) => {
            let newBooking = data as BookingPublicInfoV2;
            setViableBuyBookings(viableBuyBookings => [newBooking, ...viableBuyBookings]);
        });

        /* Handle socket broadcast a booking that is updated  */
        socket.on("update-booking", (data: any) => {
            let updatedBooking = data as BookingPublicInfoV2;
            setViableBuyBookings(viableBuyBookings => {
                let newBookings = viableBuyBookings.filter(booking => booking._id !== updatedBooking._id);
                return [updatedBooking, ...newBookings];
            });
        });

        /* Handle socket broadcast a booking that is deleted  */
        socket.on("delete-booking", (data: any) => {
            let deletedBooking = data as BookingPublicInfoV2;
            setViableBuyBookings(viableBuyBookings => {
                let newBookings = viableBuyBookings.filter(booking => booking._id !== deletedBooking._id);
                return newBookings;
            });
        });

    }, []);

    /* Handle posting contents finished */
    const onPostContentsFinished = (failedPostIds: number[]) => {
        if (failedPostIds.length > 0) {
            // keep failed bookings
            setViablePostContents(viablePostContents => {
                let newViablePostContents = viablePostContents.filter((booking, index) => !failedPostIds.includes(index));
                return newViablePostContents;
            });
        } else {
            if( postedContentsId === -1 )
            {
                // delete all bookings
                setViablePostContents([]);
            }
            else
            {
                // delete selected booking
                setViablePostContents(viablePostContents => {
                    let newViablePostContents = viablePostContents.filter((booking, index) => index !== postedContentsId);
                    return newViablePostContents;
                });
            }
        }
        setPostedContentsId(-1);
    }


    /* Init chat context */
    React.useEffect(() => {
        console.log( "-----------> Init chat context")
        setCurrPage(0);
        // Get all conversations
        axios({
            method: "GET",
            url: "/conversation",
            params: {
                page_size: 20,
                page: 0,
            },
        })
            .then((res) => {
                setConversations(res.data as ConversationInfo[]);

                // Get conversation id from query
                const convId = query.get("convId");
                if (convId) {
                    // Check if conversation id is valid
                    const foundConv = (res.data as ConversationInfo[]).find((conv) => conv._id === convId);
                    if (foundConv) {
                        setSelectConversionId(convId);
                    }
                }
            })
            .catch((err) => {
                console.log(err.response.data);
            });
    }, []);

    /* Handle socket events for messenging */
    React.useEffect(() => {
        if (socket) {
            /* Handle socket connected events */
            socket.on("connect", () => {
                console.log("---------------> socket connected");
            });

            /* Handle socket disconnected events */
            socket.on("disconnect", () => {
                console.log("---------------> socket disconnected");
            });

            /* Handle socket broadcast new booking */
            socket.on("new-message", (data: any) => {
                const newMessage = data as Message;
                console.log("---------------> new message", newMessage);
                const cloneConvs = [...conversations];
                const foundIndex = cloneConvs.findIndex((conv) => conv._id === newMessage.conversation_id);
                if (foundIndex >= 0) {
                    const savedConversation = cloneConvs.splice(foundIndex, 1)[0];
                    savedConversation.last_message = newMessage;
                    if (savedConversation.messages)
                        savedConversation.messages.unshift(newMessage);
                    else
                        savedConversation.messages = [newMessage];
                    cloneConvs.unshift(savedConversation);
                    // setConversation(savedConversation);
                    setConversations(cloneConvs);
                }
            });
        }
    }, [socket])

    /* Load chat messages of selected conversation */
    React.useEffect(() => {
        if (selectConversionId) {
            const saveConversation = conversations.find((conv) => conv._id === selectConversionId);

            // Get all messages
            axios({
                method: "GET",
                url: `/conversation/${saveConversation?._id}/messages`,
                params: {
                    page_size: 20,
                    from: saveConversation?.last_message?.created_time
                        ? fDateTime(new Date(saveConversation?.last_message?.created_time).getTime() + 60000)
                        : fDateTime(new Date().getTime() + 60000),
                    // Add 1 minute to contain the last message
                },
            })
                .then((res) => {
                    saveConversation!.messages = res.data;
                    setConversation(saveConversation);
                })
                .catch((err) => {
                    setConversation(undefined);
                    console.log(err.response.data);
                });
        }
        else {
            setConversation(undefined);
        }

    }, [selectConversionId]);


    /* Handle update a specific conversation in the list */
    const updateConversation = (newConversation: ConversationInfo) => {
        const cloneConvs = [...conversations];
        cloneConvs.unshift(newConversation);
        setConversations(cloneConvs);
    }


    /* Handle load more chat threads when scrolling to the bottom of the list */
    const loadMoreChatThreads = () => {
        // Get all conversations
        axios({
            method: "GET",
            url: "/conversation",
            params: {
                page_size: 20,
                page: currPage + 1,
            },
        })
            .then((res) => {
                const concatConvs = conversations.concat(res.data as ConversationInfo[]);
                setConversations(concatConvs);
                setCurrPage(currPage + 1);
            })
            .catch((err) => {
                console.log(err.response.data);
            });
    }


    /* Assign context */
    const chatCtx = React.useMemo(() => ({
        conversations,
        setConversations,
        selectConversionId,
        setSelectConversionId,
        conversation,
        updateConversation,
        loadMoreChatThreads,
        socket,

        mainContent,
        setMainContent,
        viableBuyBookings,
        setViableBuyBookings,
        viablePostContents,
        setViablePostContents,
        postedContentsId,
        setPostedContentsId,
        onPostContentsFinished,
    }), [
        conversations,
        setConversations,
        selectConversionId,
        setSelectConversionId,
        conversation,
        socket,

        mainContent,
        setMainContent,
        viableBuyBookings,
        setViableBuyBookings,
        viablePostContents,
        setViablePostContents,
        postedContentsId,
        setPostedContentsId,
        onPostContentsFinished,
    ]);
    // TODO: AVOID RE-RENDERING EVERY TIME NEW MESSAGE ARRIVED

    return (
        <chatContext.Provider value={chatCtx}>
            {children}
        </chatContext.Provider>
    );
}