import React from "react";
import { UserInfo } from "../../common/models/User";
import Avatar from "../../common/ui/Avatar";
import IconButton from "../../common/ui/IconButton";
import { fDateTime } from "../../common/logic/time_utils";
import { formatCurrency } from "../../common/logic/utils";
import { Icon } from "@iconify/react";
import { joinConversationWithUser } from "../../chat/logic/api";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

function AccountRow({
    user,
    onViewProfile = () => { },
    onLock = () => { },
    onUnlock = () => { },
    onEditPrivilege = () => { },
    onViewBookingsHistory = () => { },
    onViewTransactionsHistory = () => { },
}: {
    user: UserInfo,
    onViewProfile?: (user: UserInfo) => void,
    onLock?: (user: UserInfo) => void,
    onUnlock?: (user: UserInfo) => void,
    onEditPrivilege?: (user: UserInfo) => void,
    onViewBookingsHistory?: (user: UserInfo) => void,
    onViewTransactionsHistory?: (user: UserInfo) => void,
}) {

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    /* 
     *======= Callback to join a conversation with a user
     */
    const handleJoinChatWithUser = (phone_number: string) => {
        joinConversationWithUser(phone_number)
            .then((conversation) => {
                navigate(`/booking/chat?convId=${conversation._id}`);
            })
            .catch((err) => {
                enqueueSnackbar(    `Không thể bắt đầu cuộc trò chuyện với ${phone_number}: ${err.response.data}`, 
                                    { variant: "error" } );
            });
    };

    return (
        <tr>
            {/* Avatar, name and phone */}
            <td 
                className="cursor-pointer"
                onClick={() => onViewProfile(user)}
            >
                <Avatar
                    user={user}
                    size="large"
                    showTitle={true}
                    subtitle={user.phone_number}
                />
            </td>

            {/* ID Name and Date of Birth */}
            <td>
                {user.id_name ? user.id_name : "-"}
                <br />
                <span className="badge badge-ghost badge-sm">
                    {user.date_of_birth
                        ? fDateTime(user.date_of_birth, "dd/MM/yyyy")
                        : "-"}
                </span>
            </td>

            {/* Locked status */}
            <td>
                {user.locked ? (
                    <>
                        <Icon
                            icon="material-symbols:lock"
                            className="text-red-600"
                            fontSize={20}
                        />
                        <span className="badge badge-error badge-sm">
                            {`Đến ${user.locked_time
                                ? fDateTime(user.locked_time, "dd/MM/yyyy")
                                : "vô thời hạn"
                                }`}
                        </span>
                    </>
                ) : (


                    (new Date() > new Date(user.expire_date ? user.expire_date : ""))
                        ? (
                            <>
                                <Icon
                                    icon="material-symbols:lock-open-rounded"
                                    className="text-yellow-600"
                                    fontSize={20}
                                />
                                <span className="badge badge-warning badge-sm">{`${fDateTime(user.expire_date!, "dd/MM/yyyy")}`}</span>
                            </>
                        )
                        : (
                            <>
                                <Icon
                                    icon="material-symbols:lock-open-rounded"
                                    className="text-green-600"
                                    fontSize={20}
                                />
                                <span className="badge badge-ghost badge-sm">{`${fDateTime(user.expire_date!, "dd/MM/yyyy")}`}</span>
                            </>
                        )
                )}
            </td>

            {/* Point and deposit */}
            <td>
                {user.point}
                <br />
                <span className="badge badge-ghost badge-sm">{formatCurrency(user.deposit)}</span>
            </td>

            {/* Vehicles number */}
            <td>{user.vehicles?.length ? user.vehicles?.length : 0}</td>

            {/* Number of Pass Booking */}
            <td>{user.no_pass_bookings ? user.no_pass_bookings : 0}</td>

            {/* Number of Buy Booking */}
            <td>{user.no_bought_bookings ? user.no_bought_bookings : 0}</td>

            {/* Profile status */}
            <td>
                {
                    user.status === "pending"
                        ? <span className="badge badge-warning badge-sm">Chờ duyệt</span>
                        : (user.status === "activated" && user.type === "driver")
                            ? <span className="badge badge-success badge-sm">Đã duyệt</span>
                            : (user.status === "activated" && user.type !== "driver")
                                ? <span className="badge badge-info badge-sm">Admin</span>
                                : user.status === "no_profile"
                                    ? <span className="badge badge-ghost badge-sm">Không hồ sơ</span>
                                    : <span className="badge badge-error badge-sm">Tạm khóa</span>
                }
            </td>

            {/* Chat and Details button */}
            <th>
                <div className="flex flex-row items-center gap-2">
                    {/* chat button */}
                    <IconButton
                        icon="solar:chat-dots-linear"
                        variant="success"
                        onClick={() => handleJoinChatWithUser(user.phone_number)}
                    />

                    {/* details button */}
                    <div className="dropdown dropdown-end">
                        <label tabIndex={0} className="btn btn-ghost btn-xs m-1">
                            <Icon
                                icon="ph:dots-three-outline-fill"
                                className="text-gray-500"
                                fontSize={24}
                            />
                        </label>
                        <ul tabIndex={0} className="text-sm font-medium dropdown-content menu p-2 shadow-md bg-base-100 rounded-box w-48">
                            <li>
                                <a
                                    onClick={() => onViewProfile(user)}
                                >
                                    Xem hồ sơ
                                </a>
                            </li>
                            <li>
                                <a
                                    onClick={() => {
                                        if (user.status === "activated" && !user.locked) {
                                            onLock(user);
                                        } else {
                                            onUnlock(user);
                                        }
                                    }}
                                >
                                    {user.status === "activated" && !user.locked
                                        ? "Khoá tài khoản" : "Mở khoá tài khoản"
                                    }
                                </a>
                            </li>
                            {
                                user.status === "activated" && !user.locked &&
                                <li>
                                    <a
                                        onClick={() => onEditPrivilege(user)}
                                    >
                                        Cập nhật quyền
                                    </a>
                                </li>
                            }

                            <li>
                                <a
                                    onClick={() => onViewBookingsHistory(user)}
                                >
                                    Lịch sử chuyến
                                </a>
                            </li>

                            <li>
                                <a
                                    onClick={() => onViewTransactionsHistory(user)}
                                >
                                    Lịch sử giao dịch
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </th>
        </tr>
    )
}


export default React.memo(AccountRow)