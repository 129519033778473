import React from "react";

import { CarInfo, CarRequest, getDefaultCarRequest } from "../../common/models/Car";
import { downloadFile } from "../../common/logic/api";

import Input from "../../common/ui/Input";
import IconButton from "../../common/ui/IconButton";
import Select from "../../common/ui/Select";
import CheckBox from "../../common/ui/CheckBox";
import PhotoSelect from "../../common/ui/PhotoSelect";

export default function EditVehicleForm({
    vehicles = undefined,
    vehicleIndex = -1,
    onClose = () => { },
    onUpdate = () => { }
}: {
    vehicles?: CarInfo[] | undefined,
    vehicleIndex?: number,
    onClose?: () => void,
    onUpdate?: (carRequest: CarRequest) => void,
}) {
    const [vehicle, setVehicle] = React.useState<CarInfo | undefined>(undefined);
    const [photo0Src, setPhoto0Src] = React.useState<string>("");
    const [photo1Src, setPhoto1Src] = React.useState<string>("");
    const [photo2Src, setPhoto2Src] = React.useState<string>("");
    const [carRequest, setCarRequest] = React.useState<CarRequest>(getDefaultCarRequest());
    const [photo0, setPhoto0] = React.useState<File | undefined>( undefined);
    const [photo1, setPhoto1] = React.useState<File | undefined>( undefined);
    const [photo2, setPhoto2] = React.useState<File | undefined>( undefined);

    const resetForm = () => {
        setCarRequest(getDefaultCarRequest());
        setPhoto0Src("");
        setPhoto1Src("");
        setPhoto2Src("");
    }

    const handleSubmit = () => {

        let files: any[] = [photo0, photo1, photo2];
        const srcs: string[] = [photo0Src, photo1Src, photo2Src];

        onUpdate({
            ...carRequest,
            photoSrcs: srcs,
            photoFiles: files,
        });
    }

    React.useEffect(() => {
        setVehicle((vehicles && vehicleIndex >= 0) ? vehicles[vehicleIndex] : undefined);
            
        if( !vehicle ) {
            resetForm();
            return;
        };

        // download images
        if( vehicle?.photos![0] ) {
            downloadFile(vehicle.photos[0])
            .then((res) => {
                setPhoto0Src(res);
            });
        }

        if( vehicle?.photos![1] ) {
            downloadFile(vehicle.photos[1])
            .then((res) => {
                setPhoto1Src(res);
            });
        }

        if( vehicle?.photos![2] ) {
            downloadFile(vehicle.photos[2])
            .then((res) => {
                setPhoto2Src(res);
            });
        }

    }, [vehicles, vehicleIndex]);

    return (
        <div className="w-full h-full flex flex-col justify-between px-4 py-3 overflow-y-scroll">

            <div className="flex flex-col gap-4">
                {/* Form Title */}
                <div className="py-3 flex flex-row justify-between items-start">
                    <div className="text-xl font-semibold text-gray-700">
                        {vehicle ? "Cập nhật thông tin xe" : "Thêm xe mới"}
                    </div>

                    <IconButton
                        icon="ion:close-outline"
                        variant="mute"
                        onClick={() => {
                            resetForm();
                            onClose();
                        }}
                    />
                </div>

                {/* Car brand */}
                <Input
                    label="Hãng xe"
                    value={vehicle?.brand_name}
                    onChange={(e) => {
                        setCarRequest({
                            ...carRequest,
                            brand_name: e.target.value
                        });
                    }}
                />

                {/* Car model */}
                <Input
                    label="Dòng xe"
                    value={vehicle?.model}
                    onChange={(e) => {
                        setCarRequest({
                            ...carRequest,
                            model: e.target.value
                        });
                    }}
                />

                <div className="w-full flex flex-row gap-4">
                    {/* Year */}
                    <Select
                        label="Đời xe"
                        options={
                            Array.from(
                                { length: 12 },
                                (_, index) => ({
                                    value: new Date().getFullYear() - index,
                                    label: `${new Date().getFullYear() - index}`
                                }))
                        }
                        value={vehicle?.manufacture_year || new Date().getFullYear()}
                        onChange={(e) => {
                            setCarRequest({
                                ...carRequest,
                                manufacture_year: parseInt( e.target.value )
                            });
                        }}
                        width="50%"
                    />

                    {/* Year */}
                    <Select
                        label="Số chỗ"
                        options={[
                            { value: 4, label: "4 chỗ" },
                            { value: 5, label: "5 chỗ" },
                            { value: 7, label: "7 chỗ" },
                            { value: 16, label: "16 chỗ" },
                            { value: 24, label: "24 chỗ" },
                        ]}
                        value={vehicle?.seats || 4}
                        onChange={(e) => {
                            setCarRequest({
                                ...carRequest,
                                seats: parseInt(e.target.value)
                            });
                        }}
                        width="50%"
                    />
                </div>

                {/* License plate */}
                <Input
                    label="Biển số xe"
                    value={vehicle?.vehicle_number}
                    onChange={(e) => {
                        setCarRequest({
                            ...carRequest,
                            vehicle_number: e.target.value
                        });
                    }}
                />

                {/* Is taxi */}
                <CheckBox
                    label="Là xe taxi có mào"
                    checked={vehicle?.is_taxi}
                    onChange={(e) => {
                        setCarRequest({
                            ...carRequest,
                            is_taxi: e.target.checked
                        });
                    }}
                />

                {/* Car photos */}
                <div className="w-full flex flex-col gap-4">
                    <div className="text-sm font-medium text-gray-500">
                        3 Ảnh thật của xe
                    </div>

                    <div className="flex flex-row gap-4">
                        <PhotoSelect
                            value={photo0Src ? photo0Src : ""}
                            width="120px"
                            height="120px"
                            onChange={(e) => {
                                setPhoto0Src(URL.createObjectURL(e.target.files![0]));
                                setPhoto0(e.target.files![0]);
                            }}
                        />

                        <PhotoSelect
                            value={photo1Src ? photo1Src : ""}
                            width="120px"
                            height="120px"
                            onChange={(e) => {
                                setPhoto1Src(URL.createObjectURL(e.target.files![0]));
                                setPhoto1(e.target.files![0]);
                            }}
                        />
                        
                        <PhotoSelect
                            value={photo2Src ? photo2Src : ""}
                            width="120px"
                            height="120px"
                            onChange={(e) => {
                                setPhoto2Src(URL.createObjectURL(e.target.files![0]));
                                setPhoto2(e.target.files![0]);
                            }}
                        />
                    </div>
                </div>
            </div>

            {/* Submit button */}
            <button 
                className="btn btn-success btn-block mb-4 my-12"
                onClick={() => {
                    resetForm();
                    handleSubmit();
                }}
            >
                {vehicle ? "Cập nhật" : "Thêm xe"}
            </button>
        </div>
    )
}