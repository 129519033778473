import React from "react";
import { checkUserExists } from "../logic/api";
import CheckBox from "../../common/ui/CheckBox";
import Input from "../../common/ui/Input";
import { useSnackbar } from "notistack";

/*
 * Login form fields interface
 *
 */
export interface LoginFormFields {
  phone: string;
  password: string;
  remember: boolean;
}



/*
 * Login Form React Component
 *
 */
export default function FormLogin({
  handleFormSubmit,
  handleNonExistUser,
}: {
  handleFormSubmit: (formFields: LoginFormFields) => void;
  handleNonExistUser: (phone: string) => void;
}) {
  const [_formFields, setFormFields] = React.useState<LoginFormFields>({
    phone: "",
    password: "",
    remember: true,
  })

  const [showPasswordInput, setShowPasswordInput] = React.useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!showPasswordInput) {
      checkUserExists(_formFields.phone)
        .then((exist) => {
          if (exist) {
            setShowPasswordInput(true);
          } else {
            // show popup to notify user that this phone number is not registered
            handleNonExistUser(_formFields.phone);
          }
        })
        .catch((err) => {
          enqueueSnackbar("Có lỗi xảy ra, vui lòng thử lại sau!", { variant: "error", });
        });
    }
    else {
      handleFormSubmit(_formFields);
    }

  }

  return (
    <form
      className="flex flex-col gap-6 items-start px-8 py-8"
      style={{
        minHeight: "500px",
      }}
      onSubmit={submitForm}
    >

      <div className="text-3xl font-medium">
        Đăng nhập
      </div>

      <Input
        label="Số điện thoại"
        onChange={(event) => {
          setFormFields({
            ..._formFields,
            phone: event.target.value,
          });
        }}
      />

      {
        showPasswordInput &&
        <Input
          label="Mật khẩu"
          type="password"
          onChange={(event) => {
            setFormFields({
              ..._formFields,
              password: event.target.value,
            });
          }}
        />
      }

      {
        showPasswordInput &&
        <CheckBox
          label="Ghi nhớ đăng nhập"
          checked={true}
          onChange={(event) => {
            setFormFields({
              ..._formFields,
              remember: event.target.checked,
            });
          }}
        />
      }

      <Input
        type="submit"
        value={showPasswordInput ? "Đăng nhập" : "Tiếp tục"}
      />
    </form>
  );
}