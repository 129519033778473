import React from "react";
import { BankInfo } from "../models/User";
import banks_data from "../config/banks.json";
import { Icon } from "@iconify/react";

export default function BankInfoCard({
    bankInfo = undefined,
    border = true,
    showTitle = true,
    clickable = false,
    selected = false,
    onSelect = () => {},
} : {
    bankInfo?: BankInfo,
    border?: boolean,
    showTitle?: boolean,
    clickable?: boolean,
    selected?: boolean,
    onSelect?: () => void,
}) {
    const bank = banks_data.data.find((bank) => bank.bin === bankInfo?.bin);
    const borderClass = border 
                        ? (selected && clickable) 
                            ? "px-2 py-2 rounded-lg border-2 border-green-500"
                            : "px-2 py-2 rounded-lg border-2 border-gray-200" 
                        : "";
    const clickableClass = clickable ? "cursor-pointer" : "";
    const bgrClass = (selected && clickable) ? "bg-green-50" : "";
    return (
        <div 
            className={`w-full flex flex-col items-start gap-2 ${borderClass} ${clickableClass} ${bgrClass}`}
            onClick={() => {
                if(clickable) {
                    onSelect();
                }
            }}
        >
            
            { showTitle &&
                <div className="text-sm font-semibold text-gray-500">
                    Tài khoản thụ hưởng
                </div>
            }

            {/* TODO: change src to proper url */}
            <div className="w-full grid grid-cols-6 items-center">
                <img 
                    src={bank?.logo} 
                    alt="bank info" 
                    className="col-span-1"
                />

                <div className="col-span-5 flex flex-col gap-1 px-4 py-2">
                    <div className="text-base font-semibold">
                        {bank?.name}
                    </div>
                    <div className="text-base">
                        {bankInfo?.account_name}
                    </div>
                    <div className="text-base">
                        {bankInfo?.account_number}
                    </div>
                </div>

                {/* <button 
                    className="px-2 py-2 bg-transparent border-transparent"
                    onClick={() => {}}
                >
                    <Icon 
                        icon="carbon:copy" 
                        fontSize="1.5rem"
                    />
                </button> */}
            </div>
        </div>
    );
}