import { height } from "@mui/system";
import React from "react";
import { Icon } from '@iconify/react';

export default function PhotoSelect(
    {
        label = "",
        value = "",
        onChange = () => {},
        className = "",
        width = "100px",
        height = "100px",
    } : {
        label?: string,
        value?: string,
        className?: string,
        onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
        width?: string,
        height?: string,
    }
) {
    const [file, setFile] = React.useState<File | null>(null);
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    return (
        <div 
            className={`flex flex-col gap-1 items-start mt-1 ${className}`}
            style={{
                width: width,
                height: height,
            }}
        >
            {
                label &&
                <label className="text-sm font-medium text-gray-500">
                    {label}
                </label>
            }

            <div 
                className="w-full border-2 border-gray-300 rounded-lg flex flex-col items-center justify-center bg-gray-100 cursor-pointer"
                style={{
                    height: height ? height : width,
                    // paddingTop: "100%",
                }}
                onClick={() => {
                    fileInputRef.current?.click();
                }}
            >
                {
                    file ?
                    <img
                        className="w-full h-full object-cover rounded-lg"
                        src={URL.createObjectURL(file)}
                    />
                    : value ?
                    <img
                        className="w-full h-full object-cover rounded-lg"
                        src={value}
                    />
                    :
                    <div className="flex flex-col items-center justify-center gap-1 text-gray-400">
                        <Icon 
                            icon="material-symbols:photo-camera-outline" 
                            fontSize={32}
                        />
                        <div className="text-xs font-medium">
                            Chọn ảnh
                        </div>
                    </div>
                }
            </div>
            
            {/* Hidden Image File Input */}
            <input 
                className="w-full hidden h-10 px-3 py-6 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                type="file"
                accept="image/*"
                // value={file ? file.name : ""}
                onChange={(event) => {
                    if (event.target.files?.length) {
                        setFile(event.target.files[0]);
                        onChange(event);
                    }
                }}
                ref={fileInputRef}
            />
        </div>
    );
}