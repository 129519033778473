import React from "react";
import { Icon } from "@iconify/react";

export default function IconButton({
    icon = "",
    variant = "mute",
    onClick = () => {},
}: {
    icon: string,
    variant: "safe" | "warning" | "danger" | "success" | "mute",
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
}) {

    // Convert color to tailwind css class
    let iconColor = "";
    switch (variant) {
        case "safe":
            iconColor = "text-blue-500";
            break;
        case "warning":
            iconColor = "text-yellow-500";
            break;
        case "danger":
            iconColor = "text-red-500";
            break;
        case "success":
            iconColor = "text-green-500";
            break;
        case "mute":
            iconColor = "text-gray-400";    
            break;
        default:
            break;
    }

    return (
        <button
            className={`flex flex-row bg-transparent rounded-full items-center justify-center focus:outline-none px-1.5 py-1.5 border-0 ${iconColor} bg-transparent`}
            onClick={(event) => {
                onClick(event);}}
        >
            {/* Iconify icon */}
            <Icon 
                icon={icon} 
                fontSize="1.5rem"
            />
        </button>
    );
}