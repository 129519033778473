import React from "react";
import {BookingPublicInfoV2, BookingStatus} from "../../common/models/Booking";
import Avatar from "../../common/ui/Avatar";
import Chip from "../../common/ui/Chip";
import {fDateTime, isExpired} from "../../common/logic/time_utils";
import { UserPublicInfo } from "../../common/models/User";

export default function BookingCard({
    booking,
    show_status = false,
    onClick = () => {},
    width = "w-full",
    refresh = false,
    selected = false,
} : {
    booking: BookingPublicInfoV2,
    show_status?: boolean,
    onClick?: () => void,
    width?: string,
    refresh?: boolean,
    selected?: boolean,
}) {

    // Check if the booking is expired
    const [expired, setExpired] = React.useState(false);

    React.useEffect(() => {
        if( booking === undefined ) return;
        if (booking.status === BookingStatus.PENDING && !show_status) {
            setExpired( isExpired(booking.update_at) );
        }
    }, [booking, refresh, selected]);

    if( booking === undefined ) return null;
    

    let shadowCls = selected ? "shadow-xl" : "";
    let expiredCls = expired ? "bg-gray-200" : "bg-white";
    return (
        <div 
            className={ `flex flex-col gap-2 items-start rounded-lg border border-gray-200 px-4 py-3 ${shadowCls} ${expiredCls}` }
            onClick={onClick}
            style={{
                width: width,
                transform: selected ? "scale(1.05)" : "scale(1)",
            }}
        >
            
            {/* Booking creator info */}
            <div className={ show_status ? "w-full flex flex-row justify-between items-center" : "w-full flex flex-row items-center"}>
                <Avatar 
                    user={booking.user_creator as UserPublicInfo}
                    title={booking.user_creator.name}
                    subtitle=""
                    size="small"
                />

                <div className="flex flex-row gap-2 items-center">
                    { 
                        booking.complain_id?
                        <div className="badge badge-warning">Có khiếu nại</div>
                        : null
                    }

                    { 
                        show_status &&

                        (
                            booking.status === BookingStatus.PENDING ?
                                // <div className="badge badge-ghost">Chờ bổ</div>
                                <Chip label="Chờ bổ" color="warning" />
                            : booking.status === BookingStatus.ACTIVATED ?
                                // <div className="badge badge-success">Đang chạy</div>
                                <Chip label="Đang chạy" color="success" />
                            : booking.status === BookingStatus.CANCELED ?
                                // <div className="badge badge-error">Đã hủy</div>
                                <Chip label="Đã hủy" color="error" />
                            // : <div className="badge badge-info">Hoàn thành</div>
                            : <Chip label="Hoàn thành" color="info" />
                        )
                    }
                </div>
            </div>

            {/* Booking content */}
            <div className="text-base text-left font-regular whitespace-pre-wrap">
                {booking.content}
            </div>

            {/* Booking basic info */}
            <div className="w-full flex flex-row justify-between items-center">
                {/* Created time and <Go now mark> */}
                <div className="flex flex-row gap-2 items-center">
                    <div className="text-sm text-gray-500 font-medium">
                        {fDateTime(booking.update_at, "dd/MM HH:mm")}
                    </div>
                    { booking.go_now && 
                        <div className="text-sm text-yellow-500 font-medium">
                            / Đi ngay
                        </div>
                    }
                </div>

                {/* Booking point cost */}
                { booking.point_cost > 0 ?
                    <div className={`text-base font-semibold ${show_status ? 'text-gray-500' : 'text-green-500'}`}>
                        {booking.point_cost} điểm
                    </div>
                    :
                    <div className={`text-base font-semibold ${show_status ? 'text-gray-500' : 'text-yellow-500'}`}>
                        Free
                    </div>
                }
            </div>
        </div>
    );
}