import React from "react";
import IconButton from "../../common/ui/IconButton";
import CheckBox from "../../common/ui/CheckBox";
import Input from "../../common/ui/Input";
import InputMultiLine from "../../common/ui/InputMultiLine";
import {
    sendChatMessage,
    sendChatMessageV2,
} from "../logic/api";
import { chatContext } from "../logic/chatContext";
import { MessageReq } from "../../common/models/Chat";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import axios from "axios";
import imageCompression from "browser-image-compression";

export default function MessageForm({
    onSubmit,
}: {
    onSubmit: () => void,
}) {
    
    const chatCtx = React.useContext(chatContext);
    const [isMultiLines, setIsMultiLines] = React.useState<boolean>(false);
    const [msgText, setMsgText] = React.useState<string>("");
    const [msgImg, setMsgImg] = React.useState<string>("");
    const [file, setFile] = React.useState<File | null>(null);
    const imgRef = React.useRef<HTMLInputElement>(null);
    const { enqueueSnackbar } = useSnackbar();

    const cleanUp = () => {
        setMsgText("");
        setMsgImg("");
        setFile(null);
    }


    /*
     *======= Handle click on submit button
     *
     */
    const handleOnSubmit = () => {
        if( msgText === "" && msgImg === "" ) {
            return;
        }

        if( file !== null ) {
            // get link to upload file
            axios({
                method: "POST",
                url: "/files",
                data: {
                    type: "image",
                    permission: "public",
                    conversation_id: chatCtx?.selectConversionId as string,
                },
            })
            .then((res) => {
                // compress image
                const options = {
                    maxSizeMB: 0.5,
                    maxWidthOrHeight: 1440,
                    useWebWorker: true
                }
                imageCompression(file as File, options)
                .then((compressedFile) => {
                    // upload file
                    const formData = new FormData();
                    formData.append("file", compressedFile);
                    axios({
                        method: "POST",
                        url: res.data.url,
                        data: formData,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((res1) => {
                        sendChatMessage(chatCtx?.selectConversionId as string, 
                            {
                                type: "text",
                                text: msgText ? msgText : "Hình ảnh",
                                file_id: res.data.file_id as string,
                                booking_id: "",
                            } as MessageReq )
                        .catch((err2) => {
                            enqueueSnackbar("Không thể gửi tin nhắn với lý do: " + err2, { variant: "error" });
                        });
                    })
                    .catch((err1) => {
                        enqueueSnackbar("Không thể tải ảnh lên với lý do: " + err1, { variant: "error" });
                    });
                })
            })
            .catch((err) => {
                enqueueSnackbar("Không thể tải ảnh lên với lý do: " + err, { variant: "error" });
                cleanUp();
            });
        }
        else {
            sendChatMessage(chatCtx?.selectConversionId as string, 
                {
                    type: "text",
                    text: msgText,
                    booking_id: "",
                } as MessageReq )
            .catch((err2) => {
                enqueueSnackbar("Không thể gửi tin nhắn với lý do: " + err2, { variant: "error" });
                cleanUp();
            });
        }

        cleanUp();
        onSubmit();
    }
    

    /*
     *======= UI Rendering
     *
     */
    return (
        <div
            className="w-full flex flex-col gap-4 rounded-lg px-4 pb-3 pt-6"
        >
            {
                msgImg !== "" &&
                <div 
                    className="w-20 h-20 flex flex-col justify-start items-end px-1 py-1 rounded-lg"
                    style={{
                        backgroundImage: `url(${msgImg})`,
                        backgroundSize: "cover",
                    }}
                >
                    <Icon
                        icon="iwwa:delete"
                        // variant="danger"
                        onClick={(event) => {
                            event.preventDefault();
                            setFile(null);
                            setMsgImg("");
                            if(imgRef.current ) {
                                imgRef.current.files = null;
                                imgRef.current.value = "";
                            }
                        }}
                        className="cursor-pointer px-1 py-1 rounded-full bg-gray-200"
                    />
                </div>
            }

            {/* Main Booking Content Area */}
            <form 
                className="w-full flex flex-col gap-4"
                onSubmit={(event) => {
                    event.preventDefault();
                    handleOnSubmit();
                }}
            >

                <CheckBox
                    label="Nhập nhiều dòng"
                    checked={isMultiLines}
                    onChange={(event) => setIsMultiLines(event.target.checked) }
                />

                <div className="w-full flex flex-row justify-start items-start gap-2">
                    
                    {/* Message input form */}
                    {
                        isMultiLines 
                        ? <InputMultiLine
                            placeholder={"Nhập nội dung chuyến đi"}
                            value={msgText}
                            rows={3}
                            onChange={(event) => setMsgText(event.target.value)}
                        />
                        
                        : <Input
                            placeholder={"Nội dung tin nhắn"}
                            type="text"
                            value={msgText}
                            onChange={(event) => setMsgText(event.target.value)}
                        />
                    }

                    {/* Send message button */}
                    <IconButton
                        icon="carbon:send-alt"
                        variant="success"
                    />

                    {/* Upload image button */}
                    <IconButton
                        icon="heroicons:photo"
                        variant="mute"
                        onClick={(event) => {
                            event.preventDefault();
                            console.log("Upload image");
                            if( imgRef.current !== null ) {
                                imgRef.current.click();
                            }
                        }}
                    />
                    
                    {/* Hidden input for uploading image */}
                    <input
                        type="file"
                        id="file"
                        style={{display: "none"}}
                        onChange={(event) => {
                            if( event.target.files !== null ) {
                                const file_ = event.target.files[0];
                                const src = URL.createObjectURL(file_);
                                setFile(file_)
                                setMsgImg(src);
                            }
                        }}
                        accept="image/*"
                        ref={imgRef}
                    />
                </div>
            </form>
        </div>
    )
}