import React from "react";
import { UserPublicInfo, ApprovalInfo, TransactionType } from "../../common/models/User";
import Avatar from "../../common/ui/Avatar";
import { fDateTime } from "../../common/logic/time_utils";
import { formatCurrency } from "../../common/logic/utils";
import { Icon } from "@iconify/react";
import { getUserByPhone } from "../../common/logic/api";
import {
    appContext,
    getUserFromAppContext,
    saveUserToAppContext,
} from "../../common/logic/appContext";

function TransactionRow({
    transaction,
    selected = false,
    onViewTransaction = () => { },
}: {
    transaction: ApprovalInfo,
    selected?: boolean,
    onViewTransaction?: (transaction: ApprovalInfo) => void,
}) {

    const [user, setUser] = React.useState<UserPublicInfo | undefined>(undefined);
    const [admin, setAdmin] = React.useState<UserPublicInfo | undefined>(undefined);
    const appCtx = React.useContext(appContext);

    const bgClassNames = selected ? "bg-green-100" : "";

    // Get user info
    React.useEffect(() => {
        if (transaction.creator && appCtx) {
            const savedUser = getUserFromAppContext(transaction.creator, appCtx.users);
            if (savedUser) {
                setUser(savedUser);
            } else {
                getUserByPhone(transaction.creator)
                    .then((user) => {
                        if (user) {
                            setUser(user);
                            saveUserToAppContext([user], appCtx.users, appCtx.setUsers);
                        }
                    })
                    .catch((err) => {
                        setUser(undefined);
                    });
            }
        }

        if (transaction.admin_review && appCtx) {
            const savedAdmin = getUserFromAppContext(transaction.admin_review.admin!, appCtx.users);
            if (savedAdmin) {
                setAdmin(savedAdmin);
            } else {
                getUserByPhone(transaction.admin_review.admin!)
                    .then((user) => {
                        if (user) {
                            setAdmin(user);
                            saveUserToAppContext([user], appCtx.users, appCtx.setUsers);
                        }
                    })
                    .catch((err) => {
                        setAdmin(undefined);
                    });
            }
        }

    }, []);


    return (
        <tr>
            {/* Transaction type and amount */}
            <td className={bgClassNames}>
                <Avatar
                    src={
                        transaction.transaction_type === TransactionType.DEPOSIT
                        ? `${process.env.PUBLIC_URL}/icons/transaction_top_up.svg`
                        : transaction.transaction_type === TransactionType.WITHDRAW
                        ? `${process.env.PUBLIC_URL}/icons/transaction_withdraw.svg`
                        : transaction.transaction_type === TransactionType.SUBSCRIBE
                        ? `${process.env.PUBLIC_URL}/icons/transaction_subscribe.svg`
                        : ""
                    }
                    size="large"
                    showTitle={true}
                    title={
                        transaction.transaction_type === TransactionType.DEPOSIT
                        ? `Nạp ${transaction.point} điểm`
                        : transaction.transaction_type === TransactionType.WITHDRAW
                        ? `Rút ${transaction.point} điểm`
                        : transaction.transaction_type === TransactionType.SUBSCRIBE
                        ? `Gia hạn tài khoản ${Math.round(Number(transaction.point) / 0.25)} tháng`
                        : ""
                    }
                    subtitle={fDateTime(transaction.created_time!, "dd/MM/yyyy HH:mm")}
                />
            </td>

            {/* Avatar of driver who submit the transaction request */}
            <td className={bgClassNames}>
                <Avatar
                    user={user}
                    size="medium"
                    showTitle={true}
                />
            </td>


            {/* Point */}
            {
                transaction.transaction_type === TransactionType.DEPOSIT 
                || transaction.transaction_type === TransactionType.SUBSCRIBE
                ? <td className={`text-base text-right text-green-600 font-semibold ${bgClassNames}`}>{`+${transaction.point}`}</td>
                : transaction.transaction_type === TransactionType.WITHDRAW
                ? <td className={`text-base text-right text-red-600 font-semibold ${bgClassNames}`}>{`-${transaction.point}`}</td>
                : <td className={`text-base text-right font-semibold ${bgClassNames}`}>{transaction.point}</td>
            }
            <td className={bgClassNames}></td>

            {/* Status */}
            <td className={bgClassNames}>
                {
                    transaction.status === "pending"
                        ? <span className="badge badge-warning badge-sm">Chờ duyệt</span>
                        : transaction.status === "accepted"
                            ? <span className="badge badge-success badge-sm">Đã duyệt</span>
                            : <span className="badge badge-error badge-sm">Từ chối</span>
                }
            </td>
            

            {/* Admin who approved the transaction */}
            <td className={bgClassNames}>
                {
                    admin
                    ? <Avatar
                        user={admin}
                        size="medium"
                        showTitle={true}
                    />
                    : <span className="text-gray-400">__</span>
                }
            </td>

            <th className={bgClassNames}>
                <button 
                    className="btn btn-ghost btn-xs"
                    onClick={() => onViewTransaction(transaction)}
                >
                    chi tiết
                </button>
            </th>

            {/* Number of Buy Booking */}
            {/* <td>{user.no_bought_bookings ? user.no_bought_bookings : 0}</td> */}

            {/* Profile status */}
            {/* <td>
                {
                    user.status === "pending"
                        ? <span className="badge badge-warning badge-sm">Chờ duyệt</span>
                        : user.status === "activated"
                            ? <span className="badge badge-success badge-sm">Đã duyệt</span>
                            : user.status === "no_profile"
                                ? <span className="badge badge-ghost badge-sm">Không hồ sơ</span>
                                : <span className="badge badge-error badge-sm">Tạm khóa</span>
                }
            </td> */}

            {/* Details button */}
            {/* <th>
                <div className="dropdown dropdown-end">
                    <label tabIndex={0} className="btn btn-ghost btn-xs m-1">
                        <Icon
                            icon="ph:dots-three-outline-fill"
                            className="text-gray-500"
                            fontSize={24}
                        />
                    </label>
                    <ul tabIndex={0} className="text-sm font-medium dropdown-content menu p-2 shadow bg-base-100 rounded-box w-48">
                        <li>
                            <a
                                onClick={() => onViewProfile(user)}
                            >
                                Xem hồ sơ
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={() => {
                                    if (user.status === "activated" && !user.locked) {
                                        onLock(user);
                                    } else {
                                        onUnlock(user);
                                    }
                                }}
                            >
                                {user.status === "activated" && !user.locked
                                    ? "Khoá tài khoản" : "Mở khoá tài khoản"
                                }
                            </a>
                        </li>
                        {
                            user.status === "activated" && !user.locked &&
                            <li>
                                <a
                                    onClick={() => onEditPrivilege(user)}
                                >
                                    Cập nhật quyền
                                </a>
                            </li>
                        }
                    </ul>
                </div>
            </th> */}
        </tr>
    )
}


export default React.memo(TransactionRow)