import { Outlet } from "react-router-dom";
import SideBarBooking from "../components/SideBarBooking";

export default function BookingPageLayout() {
    return (
        <div className="w-full h-full grid grid-cols-10">
            <div className="col-span-2 h-full overflow-y-hidden">
                < SideBarBooking />
            </div >
            <div className="col-span-8 h-full overflow-y-auto">
                <Outlet />
            </div >
        </div>
    );
}