import React from "react";
import { UserInfo, ApprovalInfo, UserPublicInfo } from "../../common/models/User";
import { CarInfo, CarRequest } from "../../common/models/Car";
import { fDateTime } from "../../common/logic/time_utils";
import { uploadFile } from "../../common/logic/api";
import { deleteCar, uploadCarPhoto, submitProfileForApproval } from "../logic/api";
import AppBar from "../../common/ui/Nav/AppBar";
import InfoWithLabel from "../../common/ui/InfoWithLabel";
import Image from "../../common/ui/Image";
import CarCard from "../../booking/components/CarCard";
import DriverInfoCard from "../../booking/components/DriverInfoCard";
import { Icon } from "@iconify/react";
import { authContext } from "../../auth/logic/authHook";
import { useSnackbar } from "notistack";
import IdentityForm from "../forms/IdentityForm";
import SponsorSelectionForm from "../forms/SponsorSelectionForm";
import EditVehicleForm from "../forms/EditVehicleForm";

export default function PersonalProfilePage() {

    const [profile, setProfile] = React.useState<ApprovalInfo | null | undefined>(undefined);
    const [showSidePopup, setShowSidePopup] = React.useState(false);
    const [updateType, setUpdateType] = React.useState<"identity" | "vehicles" | "sponsor">("identity");
    const [vehiclesTmp, setVehiclesTmp] = React.useState<CarInfo[]>([]); // vehiclesTmp is a list of CarInfo
    const [carIndex, setCarIndex] = React.useState<number>(-1); // carIndex is an index of ApprovalInfo.vehicles[
    const [updateField, setUpdateField] = React.useState<any>({}); // updateField is a field of ApprovalInfo
    const [profileTmp, setProfileTmp] = React.useState<any>({});
    const modalCheckboxRef = React.useRef<HTMLInputElement>(null);
    const modalCarDeleteCheckboxRef = React.useRef<HTMLInputElement>(null);

    const { enqueueSnackbar } = useSnackbar();
    const authCtx = React.useContext(authContext);

    const clearContext = () => {
        setProfile(undefined);
        setVehiclesTmp([]);
        setCarIndex(-1);
        setUpdateField({});
        setProfileTmp({});
    };

    React.useEffect(() => {
        setProfile(authCtx?.authedUser?.profile);
        setProfileTmp(authCtx?.authedUser?.profile);
        setVehiclesTmp(authCtx?.authedUser?.profile?.vehicles || []);
    }, [authCtx?.authedUser]);

    const handleUpdateIdentity = (field: any) => {
        console.log("handleUpdateIdentity", field);
        setUpdateField({
            ...updateField,
            ...field,
        });

        setProfileTmp({
            ...profileTmp,
            idPhoto0: field.idPhoto0 ? URL.createObjectURL(field.idPhoto0) : undefined,
            idPhoto1: field.idPhoto1 ? URL.createObjectURL(field.idPhoto1) : undefined, 
            id_name: field.id_name ? field.id_name : profile?.id_name,
            id_number: field.id_number ? field.id_number : profile?.id_number,
            date_of_birth: field.date_of_birth ? field.date_of_birth : profile?.date_of_birth,
            sex: field.sex ? field.sex : profile?.sex,
        });
    };

    
    const handleSubmitUpdateProfile = () => {
        if (!updateField) {
            enqueueSnackbar("Không có thông tin mới để cập nhật", { variant: "error" });
            return;
        }

        const profileForSubmit = profileTmp;
        let count = 0;

        // upload new photo if any
        if (updateField?.idPhoto0) {
            uploadFile(
                undefined,
                updateField.idPhoto0 as File,
                "image",
            )
                .then((res) => {
                    const id_photos = profile?.id_photos || [];
                    if (id_photos.length < 1) {
                        id_photos.push(res.file_id);
                    }
                    id_photos[0] = res.file_id;
                    profileForSubmit.id_photos = id_photos;
                    count++;
                })
                .catch((err) => {
                    enqueueSnackbar(`Lỗi tải ảnh lên: ${err.response.data}`, { variant: "error" });
                    count++;
                });
        }

        if (updateField?.idPhoto1) {
            uploadFile(
                undefined,
                updateField.idPhoto1 as File,
                "image",
            )
                .then((res) => {
                    const id_photos = profile?.id_photos || [];
                    if (id_photos.length < 2) {
                        id_photos.push(res.file_id);
                    }
                    id_photos[1] = res.file_id;
                    profileForSubmit.id_photos = id_photos;
                    count++;
                })
                .catch((err) => {
                    enqueueSnackbar(`Lỗi tải ảnh lên: ${err.response.data}`, { variant: "error" });
                    count++;
                });
        }

        // update name
        if (updateField.id_name) {
            profileForSubmit.id_name = updateField.id_name;
        }

        // update dob
        if (updateField.date_of_birth) {
            profileForSubmit.date_of_birth = updateField.date_of_birth;
        }

        // update sex
        if (updateField.sex) {
            profileForSubmit.sex = updateField.sex;
        }

        // update CCCD/CMT
        if (updateField.id_number) {
            profileForSubmit.id_number = updateField.id_number;
        }

        // upload car photo
        if (updateField?.vehicles) {
            const vehicles: CarInfo[] = profile?.vehicles || [];
            updateField.vehicles.map((vehicle: CarRequest) => {
                uploadCarPhoto(vehicle)
                    .then((res) => {
                        vehicles.push(res as CarInfo);
                        count++;
                    })
                    .catch((err) => {
                        enqueueSnackbar(`Lỗi tải ảnh xe lên: ${err}`, { variant: "error" });
                        count++;
                    });
            })
            profileForSubmit.vehicles = vehicles;
        }
        const totalFiles = (updateField?.idPhoto0 ? 1 : 0) + (updateField?.idPhoto1 ? 1 : 0) + (updateField?.vehicles?.length || 0);
        
        // send update profile request
        // wait for all files uploaded
        const interval = setInterval(() => {
            if (count === totalFiles) {
                clearInterval(interval);
                profileForSubmit.type = "profile";
                submitProfileForApproval(profileForSubmit as ApprovalInfo)
                    .then((res) => {
                        enqueueSnackbar("Cập nhật thông tin thành công", { variant: "success" });
                        setUpdateField({});
                        modalCheckboxRef.current?.click()
                    })
                    .catch((err) => {
                        enqueueSnackbar(`Cập nhật thông tin thất bại: ${err.response.data}`, { variant: "error" });
                    });
            }
        }, 1000);

    };

    const handleDeleteVehicle = () => {
        // get deleted vehicle
        const deletedVehicle = vehiclesTmp[carIndex];
        
        if (deletedVehicle.id) {
            deleteCar(deletedVehicle.id)
                .then((res) => {
                    enqueueSnackbar("Xóa xe thành công", { variant: "success" });
                    // delete vehicle from list
                    const newVehicles = vehiclesTmp.filter((vehicle, index) => (index !== carIndex) );
                    setVehiclesTmp(newVehicles);
                    modalCarDeleteCheckboxRef.current?.click();
                })
                .catch((err) => {
                    enqueueSnackbar(`Xóa xe thất bại: ${err.response.data}`, { variant: "error" });
                });
        }
        else {
            // delete vehicle from list
            enqueueSnackbar("Xóa xe thành công", { variant: "success" });
            const newVehicles = vehiclesTmp.filter((vehicle, index) => index !== carIndex);
            setVehiclesTmp(newVehicles);
            modalCarDeleteCheckboxRef.current?.click();
        }
    };



    return (
        <div className="relative w-full h-full grid grid-cols-12 overflow-y-hiden">

            {/* Modal for showing approval sending request confirmation form */}
            <input
                type="checkbox"
                id="approval-form"
                className="modal-toggle"
                ref={modalCheckboxRef}
            />
            <label htmlFor="approval-form" className="modal cursor-pointer">
                <label className="modal-box relative" htmlFor="">
                    <div className="w-full bg-white flex flex-col gap-8 p-4 justify-center">
                        <span>Hồ sơ của bạn sẽ được gửi đến admin chờ phê duyệt. Bạn chắc chắn muốn cập nhật?</span>
                        <div className="w-full flex flex-row gap-2">

                            <button
                                className="btn btn-outline w-1/2"
                                onClick={() => {
                                    modalCheckboxRef.current?.click();
                                }}
                            >
                                Quay lại
                            </button>

                            <button
                                className="btn btn-warning w-1/2"
                                onClick={handleSubmitUpdateProfile}
                            >
                                Tiếp tục
                            </button>
                        </div>
                    </div>
                </label>
            </label>

            {/* Modal for showing confirmation diaglog to delete a car */}
            <input
                type="checkbox"
                id="approval-form"
                className="modal-toggle"
                ref={modalCarDeleteCheckboxRef}
            />
            <label htmlFor="approval-form" className="modal cursor-pointer">
                <label className="modal-box relative" htmlFor="">
                    <div className="w-full bg-white flex flex-col gap-8 p-4 justify-center">
                        <span>Bạn có chắc chắn muốn xoá xe này?</span>
                        <div className="w-full flex flex-row gap-2">

                            <button
                                className="btn btn-outline w-1/2"
                                onClick={() => {
                                    modalCarDeleteCheckboxRef.current?.click();
                                }}
                            >
                                Quay lại
                            </button>

                            <button
                                className="btn btn-warning w-1/2"
                                onClick={handleDeleteVehicle}
                            >
                                Tiếp tục
                            </button>
                        </div>
                    </div>
                </label>
            </label>



            {/* Body */}
            <div className={`${showSidePopup ? "col-span-8" : "col-span-12"} h-full flex flex-col overflow-y-hidden`}>

                <AppBar />

                <div className={`${showSidePopup ? "w-full" : "w-2/3"} h-full p-4 flex flex-row overflow-y-hidden`}>
                    {/* Indentity info */}
                    <div className="w-1/2 flex flex-col gap-4 p-4">
                        <div className="flex flex-row gap-8 items-center text-lg font-medium px-0">
                            Thông tin định danh

                            <Icon
                                icon="material-symbols:edit"
                                className="text-gray-400 cursor-pointer"
                                fontSize={20}
                                onClick={() => {
                                    setUpdateType("identity");
                                    setShowSidePopup(true);
                                }}
                            />
                        </div>

                        {
                            profileTmp
                                ? <div className="flex flex-col gap-2">
                                    <InfoWithLabel
                                        label="Họ tên CCCD"
                                        info={
                                            profileTmp?.id_name
                                                ? profileTmp.id_name
                                                : profile?.id_name
                                                    ? profile.id_name
                                                    : "N/A"}
                                    />

                                    <InfoWithLabel
                                        label="Ngày sinh"
                                        info={
                                            profileTmp?.date_of_birth
                                                ? fDateTime(profileTmp.date_of_birth, "dd/MM/yyyy")
                                                : profile?.date_of_birth
                                                    ? fDateTime(profile.date_of_birth, "dd/MM/yyyy")
                                                    : "N/A"}
                                    />

                                    <InfoWithLabel
                                        label="Giới tính"
                                        info={
                                            profileTmp?.sex === "male"
                                                ? "Nam"
                                                : profileTmp?.sex === "female"
                                                    ? "Nữ"
                                                    : profileTmp?.sex === "other"
                                                        ? "Khác"
                                                        : profile?.sex === "male"
                                                            ? "Nam"
                                                            : (profile?.sex === "female" ? "Nữ" : "Khác")}
                                    />

                                    <InfoWithLabel
                                        label="Số CMT/CCCD"
                                        info={
                                            profileTmp?.id_number
                                                ? profileTmp.id_number
                                                : profile?.id_number
                                                    ? profile.id_number
                                                    : "N/A"}
                                    />

                                    <InfoWithLabel
                                        label="Ngày vào nhóm"
                                        info={authCtx?.authedUser?.join_date ? fDateTime(authCtx.authedUser.join_date, "dd/MM/yyyy") : "N/A"}
                                    />

                                    <InfoWithLabel
                                        label="Ảnh CCCD 2 mặt"
                                        info={""}
                                    />

                                    {
                                        profileTmp?.idPhoto0
                                            ? <img
                                                src={profileTmp.idPhoto0}
                                                alt="Ảnh mặt trước CMT/CCCD"
                                                className="w-80 h-48 rounded-lg border border-gray-300"
                                            />
                                            : (profile?.id_photos![0] &&
                                                <Image
                                                    id={profile.id_photos[0]}
                                                    alt="Ảnh mặt trước CMT/CCCD"
                                                    className="w-80 h-48 rounded-lg border border-gray-300"
                                                />)
                                    }

                                    {
                                        profileTmp?.idPhoto1
                                            ? <img
                                                src={profileTmp.idPhoto1}
                                                alt="Ảnh mặt trước CMT/CCCD"
                                                className="w-80 h-48 rounded-lg border border-gray-300"
                                            />
                                            : (profile?.id_photos![1] &&
                                                <Image
                                                    id={profile.id_photos[1]}
                                                    alt="Ảnh mặt trước CMT/CCCD"
                                                    className="w-80 h-48 rounded-lg border border-gray-300"
                                                />)
                                    }

                                </div>

                                : <div className="text-gray-400">
                                    Không có thông tin
                                </div>
                        }
                    </div>

                    {/* Sponsor and Vehicles info */}
                    <div className="w-1/2 flex flex-col gap-8 p-4">

                        <div className="w-full flex-col flex gap-4">
                            <div className="flex flex-row gap-8 items-center text-lg font-medium px-0">
                                Người bảo lãnh

                                <Icon
                                    icon="material-symbols:edit"
                                    className="text-gray-400 cursor-pointer"
                                    fontSize={20}
                                    onClick={() => {
                                        setUpdateType("sponsor");
                                        setShowSidePopup(true);
                                    }}
                                />
                            </div>

                            {profile?.sponsor || updateField?.sponsor
                                ? <DriverInfoCard
                                    user={updateField?.sponsor ? updateField.sponsor : profile?.sponsor}
                                    className="border border-gray-200 rounded-lg px-3 py-2"
                                />
                                : <div className="text-sm text-gray-500">
                                    Chưa điền người bảo lãnh
                                </div>
                            }
                        </div>


                        <div className="w-full flex-col flex gap-4">
                            <div className="flex flex-row gap-8 items-center text-lg font-medium px-0">
                                Danh sách xe

                                <Icon
                                    icon="fa:plus"
                                    className="text-gray-400 cursor-pointer"
                                    fontSize={20}
                                    onClick={() => {
                                        setCarIndex(-1);
                                        setUpdateType("vehicles");
                                        setShowSidePopup(true);
                                    }}
                                />
                            </div>

                            {(vehiclesTmp.length > 0)
                                ? vehiclesTmp.map((car, index) => (
                                    <CarCard
                                        car={car}
                                        key={car.id ? car.id : index}
                                        showAction={true}
                                        onEdit={() => {
                                            setCarIndex(index);
                                            setShowSidePopup(true);
                                            setUpdateType("vehicles");
                                        }}
                                        onDelete={() => {
                                            setCarIndex(index);
                                            modalCarDeleteCheckboxRef.current?.click();
                                        }}
                                    />
                                ))
                                : <div className="text-sm text-gray-500">
                                    Không có xe nào
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* Side Popup */}
            <div className={`${showSidePopup ? "" : "hidden"} col-span-4 h-full flex flex-col-reverse border-l border-gray-200 overflow-y-scroll`}>
                {
                    updateType === "identity" ?
                        <IdentityForm
                            profile={profile}
                            onClose={() => setShowSidePopup(false)}
                            onUpdate={(updateIdentity) => {

                                console.log("----------> updateIdentity:\n", updateIdentity);
                                handleUpdateIdentity(updateIdentity);
                                setShowSidePopup(false);
                            }}
                        />
                        : updateType === "sponsor"
                            ? <SponsorSelectionForm
                                sponsor={profile?.sponsor ? profile.sponsor : ""}
                                onClose={() => setShowSidePopup(false)}
                                onUpdate={(newSponsor) => {
                                    setUpdateField({
                                        ...updateField,
                                        sponsor: newSponsor
                                    })
                                    setProfileTmp({
                                        ...profileTmp,
                                        sponsor: newSponsor?.phone_number
                                    })
                                    setShowSidePopup(false);
                                }}
                            />
                            : <EditVehicleForm
                                vehicles={vehiclesTmp ? vehiclesTmp : profile?.vehicles}
                                vehicleIndex={carIndex}
                                onClose={() => {
                                    setCarIndex(-1);
                                    setShowSidePopup(false);
                                }}

                                onUpdate={(carRequest) => {
                                    const vehicles = updateField.vehicles ? updateField.vehicles : [];
                                    vehicles.push(carRequest);
                                    setUpdateField({
                                        ...updateField,
                                        vehicles: vehicles
                                    })

                                    setVehiclesTmp([...vehiclesTmp, carRequest as CarInfo])
                                    setShowSidePopup(false);
                                }}
                            />
                }
            </div>

            {/* Update Profile Button */}
            {
                updateField && Object.keys(updateField).length > 0 &&
                <button
                    className="btn btn-success absolute bottom-4 left-8"
                    onClick={() => {
                        modalCheckboxRef.current?.click()
                    }}
                >
                    Cập nhật hồ sơ
                </button>
            }
        </div>
    );
}