import axios from 'axios';
import { ApprovalDecision, ApprovalInfo, BankInfo } from '../../common/models/User';
import { ApprovalStatus } from '../../common/models/User';
import imageCompression from "browser-image-compression";
import { UserInfo } from '../../common/models/User';
import { CarRequest } from '../../common/models/Car';
import { uploadFile } from '../../common/logic/api';


/*************************************************************
 * 
 * COMMON
 * 
 *************************************************************/
export async function getKPIs() {
    const res = await axios({
        method: 'get',
        url: '/global/report'
    });

    return res.data;
}



export async function getApprovalById(
    id: string,
){
    return axios({
        method: 'get',
        url: `/approval/${id}`,
    });
}


/*************************************************************
 * 
 * USER
 * 
 *************************************************************/
export async function getPublicUserByPhone( phone: string ){
    return axios({
        method: 'GET',
        url: `/user/${phone}`,
    });
}

export async function getUsers(
    from_page: number,
    user_query: string | undefined = undefined,
    status: string | undefined = undefined,
    deposit_string: string | undefined = undefined,
) {
    let deposit_from = 0;
    let deposit_to = 0;
    if( deposit_string === '0_100' ){
        deposit_from = 0;
        deposit_to = 100000;
    }
    else if( deposit_string === '101_300' ){
        deposit_from = 101000;
        deposit_to = 300000;
    }
    else if( deposit_string === '301_500' ){
        deposit_from = 301000;
        deposit_to = 500000;
    }
    else if( deposit_string === '501_1000' ){
        deposit_from = 501000;
        deposit_to = 1000000;
    }
    else if (deposit_string === '1001'){
        deposit_from = 1001000;
        deposit_to = 1000000000;
    }
    else {
        deposit_from = 0;
        deposit_to = 1000000000;
    }

    return axios({
        method: 'get',
        url: '/user/list',
        params: {
            page: from_page,
            page_size: 20,
            user: user_query,
            status: status,
            deposit_from: deposit_from,
            deposit_to: deposit_to,
        }
    });
}

export async function getAdmins() {
    return axios({
        method: 'get',
        url: '/user/admin',
    });
}


export async function getApprovalProfileByPhone( phone: string ){
    return axios({
        method: 'GET',
        url: `/approval/profile/${phone}`,
    });
}

export async function getMyApprovalProfile(){
    return axios({
        method: 'GET',
        url: `/approval/profile`,
    });
}

export async function getMyUserInfo() {
    return axios({
        method: 'GET',
        url: `/user`,
    });
}

export async function adminApproveProfile( 
    approvalId: string,
    decision: ApprovalDecision,
){
    return axios({
        method: 'POST',
        url: `/approval/${approvalId}`,
        data: {
            ...decision,
        }
    });
}


export async function updateAccountPermission(
    phone: string,
    decision: ApprovalDecision,
) {
    return axios({
        method: 'POST',
        url: `/user/${phone}/permission`,
        data: {
            buy_type: decision.buy_type,
            subscription: decision.subscription,
            note: decision.note,
        }
    });
}


export async function lockAccount(
    phone: string,
    lock_time: string,
    note: string,
) {
    return axios({
        method: 'POST',
        url: `/user/${phone}/status`,
        data: {
            status: "deactivated",
            lock_time: lock_time,
            note: note,
        }
    });
}


export async function unlockAccount(
    phone: string,
) {
    return axios({
        method: 'POST',
        url: `/user/${phone}/status`,
        data: {
            status: "activated",
        }
    });
}

export async function submitProfileForApproval( profile: ApprovalInfo ){
    return axios({
        method: 'POST',
        url: `/approval`,
        data: {
            ...profile,
        }
    });
}

export async function getProcessedSponsorRequests( page: number = 0, page_size: number = 20 ) {
    return axios({
        method: 'GET',
        url: `/user/sponsored`,
        params: {
            page: page,
            page_size: page_size,
        }
    });
}

export async function getPendingSponsorRequests( page: number = 0, page_size: number = 20 ) {
    return axios({
        method: 'GET',
        url: `/approval`,
        params: {
            type: "sponsor",
            page: page,
            page_size: page_size,
        }
    });
}

export async function updateBankAccount( bank: BankInfo ) {
    return axios({
        method: 'POST',
        url: `/user/bank`,
        data: {
            ...bank,
        }
    });
}


/*************************************************************
 * 
 * TRANSACTION
 * 
 *************************************************************/
export async function getApprovalTransactions(
    type: "withdraw" | "deposit" | "subscribe" | "all",
    status: "all" | ApprovalStatus,
    page: number,
){
    return axios({
        method: 'get',
        url: '/approval/transaction',
        params: {
            type: type === "all" ? undefined : type,
            status: status === "all" ? undefined : status,
            page: page,
            page_size: 20,
        }
    });
}

export async function getTransactions(
    type: "withdraw" | "deposit" | "subscribe" | "exchange" | "buy_booking" | "all",
    last_x_month: number,
    page: number,
    phone_number: string,
){
    return axios({
        method: 'GET',
        url: `/user/${phone_number}/transaction`,
        params: {
            type: type === "all" ? undefined : type,
            last_x_month: last_x_month,
            page: page,
            page_size: 20,
        }
    });
}


export async function getMyTransactions(
    type: "withdraw" | "deposit" | "subscribe" | "exchange" | "buy_booking" | "all",
    last_x_month: number,
    page: number,
){
    return axios({
        method: 'GET',
        url: `/user/transaction`,
        params: {
            type: type === "all" ? undefined : type,
            last_x_month: last_x_month,
            page: page,
            page_size: 20,
        }
    });
}


export async function getGroupBanks(){
    return axios({
        method: 'GET',
        url: `/global/banks`,
    });
}

export async function submitSubscription(plan: string) {
    return axios({
        method: 'POST',
        url: `/user/subscription`,
        data: {
            plan: plan,
        }
    });
}

export async function submitTransaction(
    type: "withdraw" | "deposit" | "exchange",
    point: number,
    photo?: File,
    note?: string,
    receiver?: string,
) {

    if( photo === undefined ){
        if( point === 0 ){
            Promise.reject("Giao dịch không hợp lệ");
        }

        return axios({
            method: 'POST',
            url: `/approval`,
            data: {
                type: "transaction",
                transaction_type: type,
                point: point,
                note: note,
                receiver: receiver,
            }
        });
    }

    const imgUrl = await axios({
        method: "POST",
        url: "/files",
        data: {
            type: "image",
            permission: "public",
        },
    });
    
    // compress image
    const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1440,
        useWebWorker: true
    }
    const compressedFile = await imageCompression(photo, options);

    // upload file
    const formData = new FormData();
    formData.append("file", compressedFile);
    axios({
        method: "POST",
        url: imgUrl.data.url,
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
    .then( res => {
        return axios({
            method: 'POST',
            url: `/approval`,
            data: {
                type: "transaction",
                transaction_type: type,
                point: point,
                note: note,
                photo: imgUrl ? imgUrl.data.file_id : undefined,
            }
        });
    })
    .catch( err => {
        Promise.reject(err);
    });
}


export async function pointToCash( point: number ){
    return axios({
        method: 'GET',
        url: '/rate/point',
        params: {
            point: point,
        }
    });
}

export async function cashToPoint( cash: number ){
    return axios({
        method: 'GET',
        url: '/rate/cash',
        params: {
            cash: cash,
        }
    });
}

/*************************************************************
 * 
 * NOTIFICATION
 * 
 *************************************************************/
export async function getNotifications(
    from_page: number,
    type: "all" | "global",
    startDate: string | undefined = undefined,
    endDate: string | undefined = undefined,
) {
    return axios({
        method: 'get',
        url: '/user/notification',
        params: {
            page: from_page,
            page_size: 20,
            type: type,
            startDate: startDate,
            endDate: endDate,
        }
    });
}


export async function recallNotification( id: string ){
    return axios({
        method: 'DELETE',
        url: `/user/notification/${id}`,
    });
}


export async function sendNotification(
    content: string,
    img?: File,
) {

    if( img === undefined ){
        if( content === "" ){
            Promise.reject("Không có nội dung để gửi");
        }

        return axios({
            method: 'POST',
            url: '/user/notification',
            data: {
                body: content,
            }
        });
    }


    const imgUrl = await axios({
        method: "POST",
        url: "/files",
        data: {
            type: "image",
            permission: "public",
        },
    });
    
    // compress image
    const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1440,
        useWebWorker: true
    }
    const compressedFile = await imageCompression(img, options);

    // upload file
    const formData = new FormData();
    formData.append("file", compressedFile);
    axios({
        method: "POST",
        url: imgUrl.data.url,
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
    .then( res => {
        return axios({
            method: 'POST',
            url: '/user/notification',
            data: {
                body: content,
                image: imgUrl ? imgUrl.data.file_id : undefined,
            }
        });
    })
    .catch( err => {
        Promise.reject(err);
    });
}


/*************************************************************
 * 
 * CAR
 * 
 *************************************************************/
export async function deleteCar( id: string ){
    return axios({
        method: 'DELETE',
        url: `/v2/user/vehicle/${id}`,
    });
}

export async function uploadCarPhoto( car: CarRequest) {
    if( car.photoFiles === undefined ) {
        return Promise.reject("Không có ảnh để tải lên");
    }

    const files = await Promise.all(car.photoFiles.map( file => {
        return uploadFile(undefined, file, "image");
    }));

    const photo_ids = files.map( file => file.file_id );
    return {
        ...car,
        photos: photo_ids,
        photoSrcs: undefined,
        photoFiles: undefined,
    } as CarRequest;
}