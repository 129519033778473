import React from "react";
import SubBookingForm from "./SubBookingForm";
import CheckBox from "../../common/ui/CheckBox";
import IconButton from "../../common/ui/IconButton";
import InputMultiLine from "../../common/ui/InputMultiLine";
import { useSnackbar } from "notistack";
import { bookingContext } from "../logic/bookingHook";
import { chatContext } from "../../chat/logic/chatContext";

export default function BookingContentForm({
    onSubmit,
}: {
    onSubmit: () => void,
}) {

    const bCtx = React.useContext(chatContext);
    const [enableMultipleBooking, setEnableMultipleBooking] = React.useState<boolean>(false);
    const [isMultipleBooking, setIsMultipleBooking] = React.useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();


    /*
     *======= Handle split one booking to multiple bookings 
     *
     */
    const handleCheckMultipleBooking = (checked : boolean) => {
        // Split to multiple bookings if checked
        if (checked && bCtx?.viablePostContents.length && bCtx.viablePostContents.length > 0) {
            bCtx?.setViablePostContents(bCtx?.viablePostContents[0].split("\n"));
        }
        
        // Merge multiple bookings to one booking if unchecked
        if( !checked && bCtx?.viablePostContents.length && bCtx.viablePostContents.length > 0) {
            if (bCtx?.viablePostContents.length && bCtx.viablePostContents.length > 0) {
                bCtx.setViablePostContents([bCtx.viablePostContents.join("\n")]);
            }
            else {
                bCtx?.setViablePostContents(bCtx.viablePostContents[0].split("\n"));
            }
        }

        setIsMultipleBooking(checked);
    }

    /*
     *======= Handle main content change
     *
     */
    const handleMainContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        // Check if new content could be split to multiple bookings
        let bookingsList = event.target.value.split("\n");
        if( bookingsList.length > 0  && bookingsList[bookingsList.length - 1] === "" ){
            bookingsList.splice(bookingsList.length - 1, 1);
        }
        if( bookingsList.length > 1){
            setEnableMultipleBooking(true);
        }
        else {
            setEnableMultipleBooking(false);
        }

        // Update state correctly
        if( !isMultipleBooking ){
            bCtx?.setViablePostContents([event.target.value]);
        }
        else {
            bCtx?.setViablePostContents(bookingsList);
        }
    }

    /*
     *======= Automatically enable/disable multiple booking checkbox when main content changes
     *
     */
    React.useEffect(() => {
        if( bCtx?.viablePostContents.length === undefined || bCtx?.viablePostContents.length === 0 ){
            if( enableMultipleBooking )
                setEnableMultipleBooking(false);
            if( isMultipleBooking )
                setIsMultipleBooking(false);
        }
    }, [bCtx?.viablePostContents]);

    /*
     *======= Handle click on submit button
     *
     */
    const handleOnSubmit = (event: React.MouseEvent<HTMLButtonElement>, postContentIdx: number) => {
        event.preventDefault();
        if (bCtx?.viablePostContents === undefined || bCtx?.viablePostContents.length === 0) {
            enqueueSnackbar("Không có nội dung nào để đăng", { variant: "warning" });
            return;
        }
        bCtx?.setPostedContentsId(postContentIdx);
        onSubmit();
    }
    

    /*
     *======= UI Rendering
     *
     */
    return (
        <div
            className="w-full flex flex-col gap-4 rounded-lg px-4 pb-3 pt-6"
        >

            {/* Sub-Bookings sections */
                isMultipleBooking &&
                bCtx?.viablePostContents.map((subContent, index) => {
                    return (
                        <SubBookingForm
                            key={index}
                            content={subContent}
                            onChange={( event) => {
                                let newSubContents = bCtx.viablePostContents;
                                newSubContents[index] = event.target.value;
                                bCtx.setViablePostContents(newSubContents);
                                bCtx.setMainContent(newSubContents.join("\n"));
                            }}
                            onCanceled={() => {
                                let newSubContents = bCtx.viablePostContents;
                                newSubContents.splice(index, 1);
                                bCtx.setViablePostContents(newSubContents);
                            }}
                            onSubmitted={(content) => {
                                bCtx.setPostedContentsId(index);
                                onSubmit();
                            }}
                        />
                    )
                })
            }


            {/* Horizontal divider */
                isMultipleBooking &&
                <div className="w-full h-px bg-gray-300"></div>
            }


            {/* Main Booking Content Area */}
            <form className="w-full flex flex-col gap-4">
                <CheckBox
                    label="Chọn đăng nhiều lịch"
                    checked={isMultipleBooking}
                    disabled={!enableMultipleBooking}
                    onChange={(event) => handleCheckMultipleBooking(event.target.checked) }
                />

                <div className="w-full flex flex-row justify-start items-start gap-4">

                    <InputMultiLine
                        placeholder={"Nhập nội dung chuyến đi"}
                        value={ 
                            ( bCtx?.viablePostContents.length && bCtx.viablePostContents.length > 0) 
                            ? bCtx?.viablePostContents.join("\n")
                            : ""
                        }
                        onChange={handleMainContentChange}
                    />

                    {   // Submit button (single or multiple)
                        isMultipleBooking
                            ? 
                            <button
                                className="w-40 h-10 rounded-lg bg-green-500 text-white font-medium text-sm mt-1"
                                onClick={(event) => handleOnSubmit(event, -1)}
                            >
                                Đăng tất cả
                            </button>

                            : <IconButton
                                icon="carbon:send-alt"
                                variant="success"
                                onClick={(event) => handleOnSubmit(event, 0)}
                            />
                    }
                </div>
            </form>

        </div>
    )
}