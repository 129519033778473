import React from "react";

export default function CheckBox(
    {
        label,
        checked = false,
        disabled = false,
        onChange = () => { },
        style = {},
    }: {
        label: string,
        checked?: boolean,
        disabled?: boolean,
        onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
        style?: React.CSSProperties,
    }
) {

    const [isChecked, setIsChecked] = React.useState<boolean>(checked);
    const [isDisabled, setIsDisabled] = React.useState<boolean>(disabled);

    React.useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    React.useEffect(() => {
        setIsDisabled(disabled);
    }, [disabled]);

    return (
        <div 
            className="flex flex-row gap-1 items-center"
            style={style}
        >
            <input
                type="checkbox"
                checked={isChecked}
                disabled={isDisabled}
                onChange={(event) => {
                    setIsChecked(event.target.checked);
                    onChange(event);
                }}
            />

            <label className={ isDisabled ? "text-sm font-medium text-gray-300" : "text-sm font-medium"}>
                {label}
            </label>
        </div>
    );
};