import React from "react";
import { UserPublicInfo } from "../../common/models/User";
import { Message } from "../../common/models/Chat";
import { BookingPublicInfoV2 } from "../../common/models/Booking";
import { authContext } from "../../auth/logic/authHook";
import {
    appContext,
    getUserFromAppContext,
    saveUserToAppContext,
    asyncPreloadUserAvatar,
} from "../../common/logic/appContext";
import { secondTimeDiff, fDateTime } from "../../common/logic/time_utils";
import { downloadFile } from "../../common/logic/api";
import Avatar from "../../common/ui/Avatar";
import BookingCard from "../../booking/components/BookingCard";
import axios from "axios";

export default function MessageCard({
    lastMessage,
    message,
    width = "auto",
    maxWidth = "60%",
}: {
    lastMessage: Message | undefined,
    message: Message,
    width?: string,
    maxWidth?: string,
}) {

    const authCtx = React.useContext(authContext);
    const appCtx = React.useContext(appContext);
    const [showSidePopup, setShowSidePopup] = React.useState(false);
    const [sender, setSender] = React.useState<UserPublicInfo | undefined>(undefined);
    const [image, setImage] = React.useState<string | undefined>(undefined);
    const [dateDivider, setDateDivider] = React.useState<string>("");
    const [alignRight, setAlignRight] = React.useState(false);
    const [booking, setBooking] = React.useState<BookingPublicInfoV2 | undefined>(undefined);

    React.useEffect(() => {
        let shouldShowSender = false;
        setDateDivider("");
        setImage(undefined);
        setAlignRight(false);
        setBooking(undefined);
        setSender(undefined);

        // set message alignment
        if (authCtx?.authedUser?.phone_number === message.user) {
            setAlignRight(true);
        }
        else {
            setAlignRight(false);
        }

        // set show date divider
        if(lastMessage === undefined) {
            setDateDivider(fDateTime(message.created_time, "dd/MM/yyyy"));
        }
        else{
            let lastDate = fDateTime(lastMessage.created_time, "dd/MM/yyyy");
            let currentDate = fDateTime(message.created_time, "dd/MM/yyyy");
            if (lastDate !== currentDate) {
                setDateDivider(currentDate);
                shouldShowSender = true;
            }
        }

        if(
            (lastMessage?.user !== message.user) ||
            (secondTimeDiff(lastMessage?.created_time, message.created_time) > 300) // 5mins
        ) {
            shouldShowSender = true;
        }

        // set sender
        if (shouldShowSender) {
            // Get user avatar and name
            if (appCtx) {
                const userInCtx = getUserFromAppContext(message.user, appCtx.users); // user here is phone number
                if (!userInCtx) {
                    axios({
                        method: 'GET',
                        url: `/user/${message.user}`,
                    })
                        .then((res) => {
                            setSender(res.data as UserPublicInfo);
                        })
                }
                else {
                    setSender(userInCtx);
                }
            }
        }

        // Download image
        if (message.file_id) {
            downloadFile(message.file_id)
                .then((res) => {
                    setImage(res);
                })
                .catch((err) => {
                    console.log(err.response.data);
                    setImage(undefined);
                })
        }

        // Get booking info
        if (message.booking_id) {
            axios({
                method: 'GET',
                url: `/booking/${message.booking_id}`,
            })
                .then((res) => {
                    setBooking(res.data as BookingPublicInfoV2);
                })
                .catch((err) => {
                    setBooking(undefined);
                    console.log(err.response.data);
                });
        }

    }, [message, lastMessage]);


    return (
        <div className={`w-full pt-1 flex flex-col gap-1 ${alignRight ? 'items-end' : 'items-start'}`}>
            {/* <div className={`flex flex-col w-auto`}> */}

                {/* Show date divider */}
                {
                    dateDivider !== "" &&
                    <div className="w-full text-center text-gray-500 text-xs font-semibold mt-8">
                        {dateDivider}
                    </div>
                }

                {/* Show avatar of the sender */}
                {
                    sender !== undefined &&
                    <div className="mt-6">
                    <Avatar
                        user={sender}
                        size="small"
                        title={sender.name}
                        showTitle={true}
                    />
                    </div>
                }

                {/* Show image */}
                {
                    image &&
                    <div className="flex flex-col gap-2 items-end">
                        <img
                            src={image}
                            alt="Ảnh"
                            className="max-w-xs rounded-lg"
                        />

                        {/* Time */}
                        <div className={`text-xs text-right
                                        ${alignRight ? 'text-gray-200' : 'text-gray-400'}`}
                        >
                            {fDateTime(message.created_time, "HH:mm")}
                        </div>
                    </div>
                }

                {/* Show booking */}
                {
                    booking &&
                    <BookingCard
                        booking={booking}
                        show_status={false}
                        width={showSidePopup ? "80%" : "66%"}
                    // onClick={() => handleBookingCardClick(booking._id)}
                    />
                }

                {/* Show message */}
                {
                    message.type === 'text' && 
                    (
                        ((message.text !== "Hình ảnh") && (message.file_id !== undefined))
                        || (message.file_id === undefined)
                    ) &&
                    <div
                        className={`flex flex-col gap-2 items-end rounded-lg px-4 py-3 
                                ${alignRight ? ' bg-green-500' : ' bg-gray-200'}`}
                        style={{
                            width: width,
                            maxWidth: maxWidth,
                        }}
                    >
                        {/* Text message */}
                        <div className={`text-base text-left font-regular whitespace-pre-wrap
                                    ${alignRight ? 'text-white' : 'text-gray-800'}`}>
                            {message.type === 'text' ? message.text : "Hỏi đáp lịch xe"}
                        </div>

                        {/* Time */}
                        <div className={`text-xs text-right
                                        ${alignRight ? 'text-gray-200' : 'text-gray-400'}`}>
                            {fDateTime(message.created_time, "HH:mm")}
                        </div>
                    </div>
                }
            {/* </div> */}

        </div>
    );
}