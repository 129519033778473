import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { getNotiToken, updateNotiToken } from "./api";
import firebaseConfig from "../config/firebaseConfig.json";

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const messaging = getMessaging(app);

export function updateToken() {


  // Check notification permission. If permission granted, proceed with
  // token request. If permission denied, display an error message and
  // terminate the function.
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");

      getToken(messaging, {
        vapidKey:
          "BPSOdAXVeIHPMA18eL1H4BGGNisuT9gREJs5FsR8dOVRh_i_tg-m_4akfq2XavnN0XQIjxAzR78fIw8DfSTrsPg",
      }).then((currentToken) => {
        if (currentToken) {
          console.log("current token for client: ", currentToken);
          // Track the token -> client mapping, by sending to backend server
          updateNotiToken(currentToken as string);
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // ...
        }
      });
    } else {
      console.log("Unable to get permission to notify.");
    }
  });
    
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("onMessage: ", payload);
      resolve(payload);
    });
  });
