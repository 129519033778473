import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import AuthProvider from "./auth/logic/authHook";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RequiredAuth from "./auth/components/RequiredAuth";
import Login from "./auth/pages/Login";
import BookingForBuyPage from "./booking/pages/BookingForBuyPage";
import ChatPage from "./chat/pages/ChatPage";
import MainWalletPage from "./account/pages/MainWalletPage";
import PersonalProfilePage from "./account/pages/PersonalProfilePage";
import SponsoredProfilePage from "./account/pages/SponsoredProfilePage";
import AdminPageLayout from "./account/pages/AdminPageLayout";
import MembersManaPage from "./account/pages/MembersManaPage";
import TransactionsManaPage from "./account/pages/TransactionsManaPage";
import AdminsManaPage from "./account/pages/AdminsManaPage";
import NotifcationsManaPage from "./account/pages/NotificationsManaPage";
import BankSettingPage from "./account/pages/BankSettingsPage";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BookingPageLayout from "./booking/pages/BookingPageLayout";
import BookingHistoryPage from "./booking/pages/BookingHistoryPage";
import PersonalPageLayout from "./account/pages/PersonalPageLayout";
import AppContextProvider from "./common/logic/appContext";
import ChatContextProvider from "./chat/logic/chatContext";
import axios from "axios";
// axios.defaults.baseURL = "https://taxi.zootopi.dev";
axios.defaults.baseURL = "https://api.xemienbac.com.vn";
// "proxy": "https://taxi.zootopi.dev",
//https://api.xemienbac.com.vn

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <AuthProvider>
    <SnackbarProvider
      dense
      maxSnack={5}
      autoHideDuration={2000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AppContextProvider>
          <BrowserRouter>

            <Routes>
              {/* Main App Pages - Required Authentication */}
              <Route
                path="/"
                element={
                  <RequiredAuth>
                    <App />
                  </RequiredAuth>
                }
              >
                <Route path="booking" element={
                  <ChatContextProvider>
                    <BookingPageLayout />
                  </ChatContextProvider>
                }>
                  <Route
                    path="for-buy"
                    element={<BookingForBuyPage />}
                  />
                  <Route path="history" element={<BookingHistoryPage />} />
                  <Route path="chat" element={<ChatPage />} />
                </Route>

                <Route path="account" element={<PersonalPageLayout />}>
                  <Route path="my-wallet" element={<MainWalletPage />} />
                  <Route
                    path="my-profile"
                    element={<PersonalProfilePage />}
                  />
                  <Route
                    path="my-sponsoring"
                    element={<SponsoredProfilePage />}
                  />
                  <Route
                    path="my-bank-account"
                    element={<BankSettingPage />}
                  />
                </Route>
                <Route path="admin" element={<AdminPageLayout />}>
                  <Route path="members" element={<MembersManaPage />} />
                  <Route
                    path="transactions"
                    element={<TransactionsManaPage />}
                  />
                  <Route path="admins" element={<AdminsManaPage />} />
                  <Route
                    path="notifications"
                    element={<NotifcationsManaPage />}
                  />
                </Route>
              </Route>

              {/* Login Page */}
              <Route path="/login" element={<Login />} />
            </Routes>
          </BrowserRouter>
        </AppContextProvider>
      </LocalizationProvider>
    </SnackbarProvider>
  </AuthProvider>
);
