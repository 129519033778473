import React from "react";
import { UserInfo, ApprovalInfo } from "../../common/models/User";
import { getMyUserInfo, getMyApprovalProfile } from "../../account/logic/api";
import { deleteSession, getSession, loginCallback, logoutCallback, setSession } from "./callbacks";
import axios from "axios";
import { updateToken } from "../../common/logic/firebase";
import { useSnackbar } from "notistack";

// CONTEXT FOR STORING AUTHENTICATION STATE ALL OVER THE APP
export interface AuthContext {
    authedUser: UserInfo | null | undefined;
    setAuthedUser: React.Dispatch<React.SetStateAction<UserInfo | null | undefined>>;
    loading: boolean;
    login: (phone: string, password: string) => Promise<UserInfo | null>;
    register: ( phone: string, name: string, password: string, 
                avartar: string | undefined, token: string) => Promise<string>;
    logout: () => void;
    reloadMe: () => Promise<void>;
}

export const authContext = React.createContext<AuthContext | null>(null);


/*
 * AUTHENTICATION PROVIDER, THAT PROVIDE
 *  - AUTH STATE   
 *  - LOGIN FUNCTION
 *  - LOGOUT FUNCTION
 *
 */
export default function AuthProvider({ children }: { children: React.ReactNode }) {

    // Initialize auth state to be stored in context
    const [authedUser, setAuthedUser] = React.useState<UserInfo | null | undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(true);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        let token = getSession();
        if (token) {
            setSession(token);
            axios.get("/user")
            .then(response => {
                setAuthedUser(response.data);
            })
            .catch(err => {
                deleteSession();
                console.log("--- SESSION DELETED ---")
                setAuthedUser(null);
            });
        }
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const auth: AuthContext = {
        authedUser: authedUser,

        setAuthedUser: setAuthedUser,

        loading: loading,

        login: async (phone: string, password: string): Promise<UserInfo | null> => {
            const user = loginCallback(phone, password) as Promise<UserInfo | null>;
            user.then((user) => {
                setAuthedUser(user);
                updateToken();
            })
                .catch((err) => {
                    enqueueSnackbar(`Đăng nhập thất bại: ${err.response.data}`, { variant: "error" });
                });
            return user;
        },

        register: async (   phone: string, password: string, name: string,
                            avartar: string | undefined, token: string): Promise<string> => {

            const res = await axios.post("/register", {
                phone_number: phone,
                name: name,
                password: password,
                avartar: avartar,
                token: token,
            });

            return res.data;
        },

        logout: () => {
            setAuthedUser(null);
            logoutCallback();
        },

        reloadMe: async () => {
            const myInfo = await getMyUserInfo();
            const myProfile = await getMyApprovalProfile();

            if (myInfo && myProfile) {
                setAuthedUser({
                    ... (myInfo.data as UserInfo),
                    profile: myProfile.data as ApprovalInfo,
                });
            }
        },
    }

    // Wait for auth state to be initialized
    if (loading) 
        return <div className="text-xl text-center">Loading...</div>;
    
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}