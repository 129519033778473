import React from "react";
import AppBar from "../../common/ui/Nav/AppBar";
import {
    ApprovalStatus,
    BankInfo,
    UserPublicInfo,
} from "../../common/models/User";
import { getGroupBanks } from "../logic/api";
import { useSnackbar } from "notistack";
import { submitSubscription, pointToCash, submitTransaction } from "../logic/api";
import { getUserByPhone } from "../../common/logic/api";
import ToogleButton from "../../common/ui/ToogleButton";
import TransactionHistorySidebarDriver from "../components/TransactionHistorySidebarDriver";
import Select from "../../common/ui/Select";
import Input from "../../common/ui/Input";
import BankInfoCard from "../../common/ui/BankInfoCard";
import InfoWithLabel from "../../common/ui/InfoWithLabel";
import { authContext } from "../../auth/logic/authHook";
import { Icon } from "@iconify/react";
import DriverInfoCard from "../../booking/components/DriverInfoCard";
import InputMultiLine from "../../common/ui/InputMultiLine";
import PhotoSelect from "../../common/ui/PhotoSelect";
import { formatCurrency } from "../../common/logic/utils";

interface TransactionFilter {
    status: ApprovalStatus | "all";
    type: "deposit" | "withdraw" | "subscribe" | "all";
}

export default function MainWalletPage() {

    const [type, setType] = React.useState<"deposit" | "withdraw" | "subscribe" | "exchange">("subscribe");
    const [groupBanks, setGroupBanks] = React.useState<BankInfo[]>([]);
    const [selectedBank, setSelectedBank] = React.useState<BankInfo | undefined>(undefined);
    const [currPage, setCurrPage] = React.useState(0);
    const [subsciptionMonths, setSubsciptionMonths] = React.useState("1_month");
    const [point, setPoint] = React.useState(0);
    const [phone, setPhone] = React.useState("");
    const [photo, setPhoto] = React.useState<File | undefined>(undefined);
    const [disableSubmit, setDisableSubmit] = React.useState(false);
    const [cash, setCash] = React.useState<string>("");
    const [beneficiary, setBeneficiary] = React.useState<UserPublicInfo | undefined>(undefined);
    const [note, setNote] = React.useState("");
    const modalCheckboxRef = React.useRef<HTMLInputElement>(null);
    const authCtx = React.useContext(authContext);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        // load list of group banks
        getGroupBanks()
            .then((res) => {
                setGroupBanks(res.data);
                if (res.data.length > 0) setSelectedBank(res.data[0]);
            })
            .catch((err) => {
                enqueueSnackbar(`Lỗi tải danh sách ngân hàng của nhóm: ${err.response.data}`, { variant: "error" });
            });
    }, []);

    // convert point to cash
    React.useEffect(() => {
        pointToCash(point)
            .then((res) => {
                setCash(formatCurrency(res.data.cash));
            })
            .catch((err) => {
                enqueueSnackbar(`Lỗi chuyển đổi điểm: ${err.response.data}`, { variant: "error" });
            });
    }, [point]);

    // handle phone string change
    React.useEffect(() => {
        // regex check valid phone number
        const regex = /((84|0[3|5|7|8|9])+([0-9]{8})\b)/g;
        if (regex.test(phone)) {
            // get user by phone
            getUserByPhone(phone)
                .then((user) => {
                    setBeneficiary(user);
                    setDisableSubmit(false);
                })
                .catch((err) => {
                    enqueueSnackbar(`Không tìm thấy tài khoản tài xế thụ hưởng: ${err.response.data}`, { variant: "error" });
                });
        } else {
            setDisableSubmit(true);
        }
    }, [phone]);

    const clearInputs = () => {
        setPoint(0);
        setDisableSubmit(false);
        setPhoto(undefined);
        setPhone("");
        setSubsciptionMonths("1_month");
        setBeneficiary(undefined);
        setNote("");
    }

    const handleSubmitTransaction = () => {
        if( type === "subscribe" ) {
            submitSubscription(subsciptionMonths)
                .then((res) => {
                    enqueueSnackbar("Giao dịch thành công", { variant: "success" });
                    clearInputs();
                })
                .catch((err) => {
                    enqueueSnackbar(`Lỗi giao dịch: ${err.response.data}`, { variant: "error" });
                });
        }
        else {
            submitTransaction(type, point, photo, note, beneficiary?.phone_number)
                .then((res) => {
                    enqueueSnackbar("Giao dịch thành công", { variant: "success" });
                    clearInputs();
                })
                .catch((err) => {
                    enqueueSnackbar(`Lỗi giao dịch: ${err.response.data}`, { variant: "error" });
                });
        }
        modalCheckboxRef.current?.click();
    }


    return (
        <div className="w-full h-full grid grid-cols-12 overflow-y-hiden">
            {/* Modal for showing approval form */}
            <input
                type="checkbox"
                id="approval-form"
                className="modal-toggle"
                ref={modalCheckboxRef}
            />
            <label htmlFor="approval-form" className="modal cursor-pointer">
                <label className="modal-box relative" htmlFor="">
                    <div className="w-full bg-white flex flex-col gap-8 p-4 justify-center">
                        <span>Bạn có chắc chắn muốn thực hiện giao dịch này</span>
                        <div className="w-full flex flex-row gap-2">

                            <button
                                className="btn btn-outline w-1/2"
                                onClick={() => {
                                    modalCheckboxRef.current?.click();
                                }}
                            >
                                Quay lại
                            </button>

                            <button
                                className="btn btn-success w-1/2"
                                onClick={handleSubmitTransaction}
                            >
                                Thực hiện
                            </button>
                        </div>
                    </div>
                </label>
            </label>

            {/* Body */}
            <div className={`col-span-7 h-full flex flex-col overflow-y-hidden`}>

                <AppBar />

                {/* Transaction Form */}
                <div className="w-full h-full flex flex-col gap-4 px-4 py-3 overflow-y-scroll">
                    {/* Form Title */}
                    <div className="text-lg font-semibold text-gray-700">
                        Tạo giao dịch mới
                    </div>

                    {/* Select type of transaction */}
                    <div className="flex flex-row gap-2">
                        <ToogleButton
                            text="Gia hạn thành viên"
                            value={type === "subscribe"}
                            onChange={(value) => {
                                if (value) setType("subscribe");
                            }}
                        />

                        <ToogleButton
                            text="Nạp điểm"
                            value={type === "deposit"}
                            onChange={(value) => {
                                if (value) setType("deposit");
                            }}
                        />

                        <ToogleButton
                            text="Rút điểm"
                            value={type === "withdraw"}
                            onChange={(value) => {
                                if (value) setType("withdraw");
                            }}
                        />

                        <ToogleButton
                            text="San điểm"
                            value={type === "exchange"}
                            onChange={(value) => {
                                if (value) setType("exchange");
                            }}
                        />
                    </div>

                    {/* Form Inputs */}
                    <div className="w-full h-full flex flex-col justify-between">
                        
                        { /* Subscriptions input */
                            type === "subscribe" &&
                            <div className="w-1/2 flex flex-col gap-2">
                                <Select
                                    label="Số tháng gia hạn"
                                    options={[
                                        { label: "1 tháng", value: "1_month" },
                                        { label: "3 tháng", value: "3_months" },
                                        { label: "6 tháng", value: "6_months" },
                                        { label: "12 tháng", value: "12_months" },
                                    ]}
                                    value={subsciptionMonths}
                                    onChange={(event) => {
                                        setSubsciptionMonths(event.target.value);
                                        const _point =  event.target.value === "1_month" ? 0.25
                                                        : event.target.value === "3_months" ? 0.75
                                                        : event.target.value === "6_months" ? 1.5
                                                        : 3;
                                        if(authCtx?.authedUser?.point && _point > authCtx?.authedUser?.point) {
                                            enqueueSnackbar("Số điểm không đủ", { variant: "error" });
                                            setDisableSubmit(true);
                                        }
                                        else {
                                            setPoint(_point);
                                            setDisableSubmit(false);
                                        }
                                    }}
                                />

                                <div className="flex flex-row gap-2 text-sm font-medium text-gray-400">
                                    <Icon
                                        icon="solar:wallet-linear"
                                        fontSize={16}
                                    />
                                    <span>{`Số điểm khả dụng: ${authCtx?.authedUser?.point}`}</span>
                                </div>
                            </div>
                        }

                        { /* Deposit input */
                            type === "deposit" &&
                            <div className="w-full flex flex-col gap-6">
                                <div className="w-full flex flex-col gap-2">
                                    <Input
                                        label="Số điểm cần nạp"
                                        type="number"
                                        value={point}
                                        onChange={(event) => {setPoint(Number(event.target.value))}}
                                        width="50%"
                                    />

                                    <div className="flex flex-row gap-2 text-sm font-medium text-gray-400">
                                        <Icon
                                            icon="solar:wallet-linear"
                                            fontSize={16}
                                        />
                                        <span>{`Số điểm khả dụng: ${authCtx?.authedUser?.point}`}</span>
                                    </div>
                                </div>

                                { 
                                    (groupBanks.length > 0) &&
                                    <div className="w-full flex flex-col gap-2">
                                        <div className="text-sm font-medium text-gray-500">
                                            Chọn tài khoản ngân hàng
                                        </div>

                                        <div className="w-full flex flex-row gap-2 overflow-x-scroll">
                                        {
                                            groupBanks.map( (bank, idx) =>
                                                <BankInfoCard
                                                    bankInfo={bank}
                                                    showTitle={false}
                                                    clickable={true}
                                                    border={true}
                                                    selected={selectedBank?.account_number === bank.account_number}
                                                    onSelect={() => setSelectedBank(bank)}
                                                />
                                            )
                                        }
                                    </div>
                                    </div>
                                }

                                <InfoWithLabel
                                    label="Nhập nội dung chuyển khoản sau"
                                    info={`TX-${authCtx?.authedUser?.phone_number} nạp ${point} điểm`}
                                    direction="column"
                                    width="50%"
                                />

                                <button
                                    className="btn btn-success btn-xs gap-2 w-40 shadow-lg"
                                >
                                    <Icon
                                        icon="uil:qrcode-scan"
                                        fontSize={16}
                                    />
                                    Hiển thị QR code
                                </button>

                                <PhotoSelect
                                    label="Chụp ảnh chuyển khoản"
                                    onChange={(event) => setPhoto(event.target.files?.[0])}
                                    width="200px"
                                />

                            </div>
                        }


                        { /* Withdraw input */
                            type === "withdraw" &&
                            <div className="w-1/2 flex flex-col gap-2">
                                <Input
                                    label="Số điểm cần rút"
                                    type="number"
                                    value={point}
                                    onChange={(event) => {
                                        if(authCtx?.authedUser?.point && Number(event.target.value) > authCtx?.authedUser?.point){
                                            enqueueSnackbar("Số điểm không đủ", { variant: "error" });
                                            setDisableSubmit(true);
                                        }
                                        else {
                                            setPoint(Number(event.target.value))
                                            setDisableSubmit(false);
                                        }
                                    }}
                                />
                                <div className="flex flex-row gap-2 text-sm font-medium text-gray-400">
                                    <Icon
                                        icon="solar:wallet-linear"
                                        fontSize={16}
                                    />
                                    <span>{`Số điểm khả dụng: ${authCtx?.authedUser?.point}`}</span>
                                </div>
                            </div>
                        }

                        { /* Point transfer input */
                            type === "exchange" &&
                            <div className="w-1/2 flex flex-col gap-6">
                                    <Input
                                        label="Số điện thoại thụ hưởng"
                                        type="text"
                                        value={phone}
                                        onChange={(event) => setPhone(event.target.value)}
                                    />

                                    {
                                        beneficiary &&
                                        <DriverInfoCard
                                            user={beneficiary}
                                            className="px-4 py-2 rounded-lg shadow-lg"
                                            enableChat={false}
                                        />
                                    }

                                    <div className="flex flex-col gap-2">
                                        <Input
                                            label="Số điểm cần san"
                                            type="number"
                                            value={point}
                                            onChange={(event) => {
                                                if(authCtx?.authedUser?.point && Number(event.target.value) > authCtx?.authedUser?.point){
                                                    enqueueSnackbar("Số điểm không đủ", { variant: "error" });
                                                    setDisableSubmit(true);
                                                }
                                                else {
                                                    setPoint(Number(event.target.value))
                                                    setDisableSubmit(false);
                                                }
                                            }}
                                        />

                                        <div className="flex flex-row gap-2 text-sm font-medium text-gray-400">
                                            <Icon
                                                icon="solar:wallet-linear"
                                                fontSize={16}
                                            />
                                            <span>{`Số điểm khả dụng: ${authCtx?.authedUser?.point}`}</span>
                                        </div>
                                    </div>

                                    <InputMultiLine
                                        label="Nội dung san điểm"
                                        value={note}
                                        onChange={(event) => setNote(event.target.value)}
                                        rows={3}
                                    />
                            </div>
                        }

                        <div className="w-1/2 flex flex-col gap-4 py-4">
                            
                            <div className="flex flex-col gap-2 rounded-md shadow-lg px-2 py-3">
                                {
                                    type === "subscribe" || type === "exchange"
                                    ? <InfoWithLabel
                                        label="Số điểm trừ vào tài khoản"
                                        info={`-${point} điểm`}
                                        direction="row"
                                        alignInfo="right"
                                        emphasis={true}
                                    />

                                    : <InfoWithLabel
                                        label="Số tiền tương ứng"
                                        info={cash}
                                        direction="row"
                                        alignInfo="right"
                                        emphasis={true}
                                    />
                                }

                                {
                                    authCtx?.authedUser?.point &&

                                    <InfoWithLabel
                                        label="Số dư điểm cuối"
                                        info={
                                            type === "subscribe" || type === "exchange" || type === "withdraw"
                                                ? `${authCtx?.authedUser?.point - point} điểm`
                                                : `${authCtx?.authedUser?.point + point} điểm`
                                        }
                                        direction="row"
                                        alignInfo="right"
                                    />
                                }
                            </div>
                            
                            <button
                                className="btn btn-success btn-block"
                                onClick={() => {
                                    modalCheckboxRef.current?.click()
                                }}
                                disabled={disableSubmit}
                            >
                                Tạo giao dịch
                            </button>
                        </div>
                    </div>

                </div>
            </div>

            {/* Detail of admin */}
            <div className={`col-span-5 h-full flex flex-col-reverse border-l border-gray-200 overflow-y-scroll`}>
                <TransactionHistorySidebarDriver />
            </div>
        </div>
    );
}