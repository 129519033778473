import React from "react";
import Avatar from "../../common/ui/Avatar";
import {
    appContext,
    getUserFromAppContext,
    saveUserToAppContext,
    asyncPreloadUserAvatar,
} from "../../common/logic/appContext";
import { authContext } from "../../auth/logic/authHook";
import { SearchChatResult } from "../../common/models/Chat";
import { UserPublicInfo } from "../../common/models/User";
import axios from "axios";

export default function SearchChatCard({
    chatInfo,
    selected,
    onClick = () => { },
}: {
    chatInfo: SearchChatResult,
    selected: boolean,
    onClick?: () => void,
}) {
    const [avatarData, setAvatarData] = React.useState<string | undefined>(undefined);
    const [partner, setPartner] = React.useState<UserPublicInfo | undefined>(undefined);
    const appCtx = React.useContext(appContext);
    const auth = React.useContext(authContext);

    /*
     *======= Get avatar of the chat thread  ======
     *
     */
    React.useEffect(() => {
        
        if (chatInfo.conversation?.type === 'complaining') {
            // fixed avatar for complaining thread
            if (chatInfo.conversation?.complaining?.status === 'resolved')
                setAvatarData(`${process.env.PUBLIC_URL}/icons/complain_resolved.svg`)
            else
                setAvatarData(`${process.env.PUBLIC_URL}/icons/complain_unresolved.svg`)
            return;
        }
        
        let partnerPhone = (chatInfo.conversation !== undefined) 
                            ? chatInfo.conversation.users.filter((p) => p !== auth?.authedUser?.phone_number)[0] 
                            : chatInfo.user?.phone_number;
        if (appCtx) {
            const userInCtx = getUserFromAppContext(partnerPhone ? partnerPhone : "", appCtx.users); 
            if (!userInCtx) {
                axios({
                    method: 'GET',
                    url: `/user/${partnerPhone}`,
                })
                    .then((res) => {
                        setPartner(res.data as UserPublicInfo);
                    })
            }
            else {
                setPartner(userInCtx);
            }
        }
        
    }, [chatInfo]);


    return (
        <div 
            className={`w-full flex flex-row justify-between hover:cursor-pointer
                        items-start px-4 py-3 border-b border-gray-100 
                        ${selected ? 'bg-green-200': ''}`}
            onClick={onClick}
        >
            <Avatar
                src={avatarData}
                user={partner}
                title={(chatInfo.conversation?.type === 'complaining') 
                        ? `Khiếu nại lịch ${chatInfo.conversation.complaining?.booking_short_id}` 
                        : partner?.name }
                size="large"
            />

        </div>
    )

}