import React from "react";
import { UserPublicInfo } from "../../common/models/User";

import { getUserByPhone } from "../../common/logic/api";

import IconButton from "../../common/ui/IconButton";
import Input from "../../common/ui/Input";
import DriverInfoCard from "../../booking/components/DriverInfoCard";
import { useSnackbar } from "notistack";

export default function SponsorSelectionForm({
    sponsor = "",
    onClose = () => { },
    onUpdate = () => { },
}: {
    sponsor: string,
    onClose?: () => void,
    onUpdate?: (updateSponsor: UserPublicInfo | undefined) => void,
}) {

    const [updateSponsor, setUpdateSponsor] = React.useState<UserPublicInfo | undefined>(undefined);
    const [phone, setPhone] = React.useState<string>("");
    const [disableSubmit, setDisableSubmit] = React.useState<boolean>(true);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        if (sponsor) {
            setPhone(sponsor);
        }
    }, [sponsor]);

    // handle phone string change
    React.useEffect(() => {
        // regex check valid phone number
        const regex = /((84|0[3|5|7|8|9])+([0-9]{8})\b)/g;
        if (regex.test(phone)) {
            // get user by phone
            getUserByPhone(phone)
                .then((user) => {
                    setUpdateSponsor(user);
                    setDisableSubmit(false);
                })
                .catch((err) => {
                    setUpdateSponsor(undefined);
                    enqueueSnackbar(`Không tìm thấy tài khoản tài xế bảo lãnh: ${err.response.data}`, { variant: "error" });
                });
        } else {
            setDisableSubmit(true);
        }
    }, [phone]);

    return (
        <div className="w-full h-full flex flex-col justify-between px-4 py-3 overflow-y-scroll">

            <div className="flex flex-col gap-4">
                {/* Form Title */}
                <div className="py-3 flex flex-row justify-between items-start">
                    <div className="text-xl font-semibold text-gray-700">
                        Cập nhật người bảo lãnh
                    </div>

                    <IconButton
                        icon="ion:close-outline"
                        variant="mute"
                        onClick={() => {
                            onClose();
                        }}
                    />
                </div>

                {/* Identity name */}
                <Input
                    label="Số điện thoại người bảo lãnh"
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                />

                {
                    updateSponsor
                        ? <DriverInfoCard
                            user={updateSponsor}
                            className="px-4 py-2 rounded-lg shadow-lg"
                            enableChat={false}
                        />
                        : sponsor
                            ? <DriverInfoCard
                                user={sponsor}
                                className="px-4 py-2 rounded-lg shadow-lg"
                                enableChat={false}
                            />
                            : null
                }
            </div>

            {/* Submit button */}
            <button 
                className="btn btn-success btn-block mb-4 my-12"
                onClick={() => {
                    onUpdate(updateSponsor);
                }}
                disabled={disableSubmit}
            >
                Cập nhật
            </button>
        </div>
    )
}