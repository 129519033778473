import React from "react";
import { checkUserExists } from "../logic/api";
import CheckBox from "../../common/ui/CheckBox";
import {
    RecaptchaVerifier,
    signInWithPhoneNumber,
    ConfirmationResult,
} from "firebase/auth";
import { auth, updateToken } from "../../common/logic/firebase";
import Input from "../../common/ui/Input";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";


/*
 * Login Form React Component
 *
 */
export default function FormOTP({
    phone,
    onConfirmOTP,
    onBack,
}: {
    phone: string;
    onConfirmOTP: (otp: string) => void;
    onBack: () => void;
}) {
    const [otp, setOTP] = React.useState<string>("");
    const [enableSubmit, setEnableSubmit] = React.useState<boolean>(false);
    const [enableResend, setEnableResend] = React.useState<boolean>(false);
    const [recaptcha, setRecaptcha] = React.useState<RecaptchaVerifier>();
    const [confirmationResult, setConfirmationResult] = React.useState<ConfirmationResult>();
    const [expireTime, setExpireTime] = React.useState<number | null>(null);
    const [countDownTime, setCountDownTime] = React.useState("");
    const [token, setToken] = React.useState<string>("");
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const startCountDown = (time: number) => {
        localStorage.setItem("OTPExpireTime", time.toString());
        setExpireTime(time);
        setEnableSubmit(false);
        setEnableResend(false);
        setOTP("");
    };

    const handleSendOTP = () => {
        // Todo: send OTP
        console.log(recaptcha);
        signInWithPhoneNumber(auth, "+84" + phone, recaptcha!)
            .then((confirmationResult) => {
                setConfirmationResult(confirmationResult);
                const time = Date.now() + 3 * 60 * 1000;
                startCountDown(time);
            })
            .catch((error) => {
                enqueueSnackbar(`Có lỗi xảy ra, thử lại sau: ${error}`, { variant: "error" });
                console.error(error);
            });
    };

    const handleConfirmOTP = () => {
        if (confirmationResult) {
            confirmationResult
                .confirm(otp)
                .then(async (result) => {
                    console.log( "---------------> okokokok")
                    const token_ = await result.user.getIdToken();
                    setToken(token_);
                    onConfirmOTP(token_);
                    updateToken();
                })
                .catch((error) => {
                    enqueueSnackbar("Có lỗi xảy ra, thử lại sau", { variant: "error" });
                    console.log(error);
                });
        }
    };

    React.useEffect(() => {
        const localExpireTime = localStorage.getItem("OTPExpireTime");
        if (localExpireTime) {
            const time = parseInt(localExpireTime);
            if (time > Date.now()) {
                startCountDown(time);
                return;
            }
        }
        const verifier = new RecaptchaVerifier(
            "recaptcha-container",
            {
                size: "invisible",
            },
            auth
        );
        if (!recaptcha) {
            verifier.verify().then(() => setRecaptcha(verifier));
        }

        return () => {
            verifier.clear();
        };
    }, []);

    React.useEffect(() => {
        if (recaptcha) {
            handleSendOTP();
        }
    }, [recaptcha])

    return (
        <div
            className="flex flex-col gap-6 items-start px-8 py-8"
            style={{
                minHeight: "500px",
            }}
            // onSubmit={handleConfirmOTP}
        >

            <div className="text-3xl font-medium">
                Xác thực OTP
            </div>

            {`Một mã OTP đã được gửi đến số điện thoại ${phone}. Vui lòng kiểm tra tin nhắn.`}

            <Input
                label="Nhập mã OTP"
                value={otp}
                onChange={(event) => {
                    setOTP(event.target.value);
                    if (event.target.value.length === 6) {
                        setEnableSubmit(true);
                    }
                    else {
                        setEnableSubmit(false);
                    }
                }}
            />

            <button
                className="btn btn-block btn-success"
                disabled={!enableSubmit}
                onClick={handleConfirmOTP}
            >
                Xác thực
            </button>

            <div className="w-full flex flex-row justify-between">
            <a
                className="link link-hover text-gray-500"
                onClick={onBack}
            >
                Quay lại
            </a>
            <a
                className={`link link-hover ${enableResend ? "" : "text-gray-300"}`}
                onClick={() => {
                    if(enableResend) {
                        handleSendOTP();
                    }
                }}
            >
                Gửi lại OTP
            </a>
            </div>
            <div id="recaptcha-container" />
        </div>
    );
}