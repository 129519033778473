import React from "react";
import axios from "axios";
import { useSnackbar } from "notistack";


export default function Image({
    id = "",
    alt = "",
    className = "",
}: {
    id: string,
    alt: string,
    className?: string,
}) {

    const [src, setSrc] = React.useState<string | undefined>(undefined);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {

        if (id) {
            // get url
            axios({
                method: "GET",
                url: `/files/${id}`,
            })
            .then((res) => {
                axios(
                    {
                        method: 'GET',
                        url: res.data.url,
                        responseType: 'arraybuffer',
                    }
                )
                .then(res1 => {
                    const blob = new Blob([res1.data], { type: 'image/jpeg' });
                    setSrc(URL.createObjectURL(blob));
                    console.log("Image loaded");
                })
                .catch(err => {
                    enqueueSnackbar(`Lỗi tải ảnh: ${err.response.data}`, { variant: "error" });
                })
            })
            .catch((err) => {
                enqueueSnackbar(`Lỗi phân giải đường dẫn ảnh: ${err.response.data}`, { variant: "error" });
            });
        }
    }, [id]);

    if (id === "") {
        return null;
    }

    return (
        <img className={className} src={src} alt={alt} />
    )
}
