import axios from "axios";
import { BookingInfoV2, BookingInfoV2Req, BookingPublicInfoV2, BookingType } from "../../common/models/Booking";

/*
    * This function is used to check if booking has optional options
    * @param {BookingInfoV2 | BookingInfoV2Req | BookingPublicInfoV2} booking
    * @return {boolean} true if booking has optional options
    * @example
    *  hasOptionalOptions(booking);
*/
export function hasOptionalOptions(
    booking: BookingInfoV2 | BookingInfoV2Req | BookingPublicInfoV2
): boolean {

    if ( booking.start_time || booking.note || booking.image || booking.seats !== 5 ) {
        return true;
    }

    if ( booking.allow_taxi || booking.highway_fee || booking.one_way || booking.round_trip || booking.name_board_required ) {
        return true;
    }

    return false;
}

/*
    * This function is used to get default booking info
    * @return {BookingInfoV2Req} default booking info
    * @example
    *   getDefaultBookingInfoV2Req();
*/
export function getDefaultBookingInfoV2Req () : BookingInfoV2Req {
    return {
        content: "",
        type: BookingType.GOODBYE,
        go_now: false,
        point_cost: 0,
        allow_taxi: false,
        highway_fee: false,
        one_way: false,
        round_trip: false,
        name_board_required: false,
        seats: 5,
    } as BookingInfoV2Req;
}

/*
    * This function is used to remove a booking from list
    * @param {BookingPublicInfoV2[]} list
    * @param {string} bookingID
    * @return {BookingPublicInfoV2[]} list after removing
    * @example
    *  removeBookingFromList(list, bookingID);
*/
export function removeBookingFromList(
    bookingsList: BookingPublicInfoV2[],
    bookingID: string
): BookingPublicInfoV2[] {
    return bookingsList.filter((booking) => booking._id !== bookingID);
}


/*
    * This function is used to update a booking in list separately
    * @param {BookingPublicInfoV2[]} list
    * @param {string} bookingID
    * @return {BookingPublicInfoV2[]} list after updating
    * @example
    *   updateBookingInList(list, bookingID, booking);
*/
export function updateBookingInList(
    bookingsList: BookingPublicInfoV2[],
    bookingID: string,
): BookingPublicInfoV2[] {
    // check if booking is in list
    const booking = bookingsList.find((item) => item._id === bookingID);
    if (!booking) return bookingsList;

    // update booking axios
    axios(
        {
            url :`/v2/booking/`,
        }
    )

    // update booking axios
    return bookingsList.map((item) => (item._id === bookingID ? booking : item));
}