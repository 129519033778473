import React from "react";
import { BookingInfoV2 } from "../../common/models/Booking";
import { ConversationInfo } from "../../common/models/Chat";
import { raiseComplaint } from "../logic/api";
import InputMultiLine from "../../common/ui/InputMultiLine";
import { useSnackbar } from "notistack";

export default function BookingComplaintForm( {
    booking,
    onRaisedComplaint = () => { },
    onFailedComplaint = () => { }
}:{
    booking: BookingInfoV2,
    onRaisedComplaint?: (conversation: ConversationInfo) => void,
    onFailedComplaint?: () => void
} ) {

    const [complaintReason, setComplaintReason] = React.useState<string>("");
    const { enqueueSnackbar } = useSnackbar();

    const handleSumit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        raiseComplaint( booking._id, complaintReason )
            .then( (res) => {
                enqueueSnackbar("Gửi khiếu nại thành công", { variant: "success" });
                setComplaintReason("");
                onRaisedComplaint( res.data as ConversationInfo );
            })
            .catch( () => {
                enqueueSnackbar("Gửi khiếu nại thất bại", { variant: "error" });
                onFailedComplaint();
            });
    }

    return (
        <form 
            className="w-full h-full bg-white flex flex-col gap-4 rounded-lg"
            onSubmit={ event => handleSumit(event)}
        >
            <div className="text-base font-semibold text-left">
                Xác nhận gửi khiếu nại
            </div>

            <InputMultiLine
                placeholder="Nhập nội dung khiếu nại"
                value={complaintReason}
                onChange={event => setComplaintReason(event.target.value)}
                rows={3}
            />

            <button
                type="submit"
                className="btn btn-block btn-warning"
            >
                Gửi khiếu nại
            </button>


        </form>
    );
}