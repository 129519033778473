import React from "react";
import { uploadFile } from "../../common/logic/api";
import { getMe } from "../logic/api";
import FormLogin, { LoginFormFields } from "../forms/FormLogin";
import FormSignUp, { SignupFormFields } from "../forms/FormSignup";
import FormOTP from "../forms/FormOTP";
import { authContext } from "../logic/authHook";
import { useNavigate, useLocation } from "react-router-dom";
import { setSession } from "../logic/callbacks";
import { useSnackbar } from "notistack";


/*
 * Login Page React Component
 *
 */
export default function Login() {

    // get login function from auth hook
    const auth = React.useContext(authContext);
    const navigate = useNavigate();
    const { state } = useLocation();
    const modalCheckboxRef = React.useRef<HTMLInputElement>(null);
    const [phone, setPhone] = React.useState<string>("");
    const [token, setToken] = React.useState<string>("");
    const [step, setStep] = React.useState<"login" | "otp" | "signup">("login");
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        // if user is already logged in, redirect to the page user originally access
        if (auth?.authedUser) {
            navigate(state?.path || "/booking");
        }
    }, [auth?.authedUser, navigate, state?.path]);

    const handleFormSubmit = (formFields: LoginFormFields) => {
        auth?.login(formFields.phone, formFields.password)
            .then(() => {
                // redirect to the page user originally access
                navigate("/booking");
            }
            )
            .catch((error) => {
                console.log(error);
            }
            );
    }

    const handleSignUp = (formFields: SignupFormFields, file?: File) => {
        // upload avartar if user choose one
        // if (file) {
        //     uploadFile(undefined, file, "image")
        //         .then(response => {
        //             formFields.avatar = response.file_id;
        //             auth?.register(formFields.phone_number, formFields.password,
        //                 formFields.name, formFields.avatar, token)
        //                 .then(signinToken => {
        //                     if (signinToken) {
        //                         setSession(signinToken);
        //                         navigate("/booking");
        //                         enqueueSnackbar("Đăng ký thành công!", { variant: "success" });
        //                     }
        //                 })
        //                 .catch(error => {
        //                     enqueueSnackbar(`Đăng ký thất bại: ${error.response.data}`, { variant: "error" });
        //                 })
        //         })
        //         .catch(error => {
        //             enqueueSnackbar(`Đăng ký thất bại: ${error.response.data}`, { variant: "error" });
        //         });
        // } else 
        {
            auth?.register(phone, formFields.password,
                formFields.name, formFields.avatar, token)
                .then(signinToken => {
                    if (signinToken) {
                        setSession(signinToken);
                        navigate("/booking");
                        enqueueSnackbar("Đăng ký thành công!", { variant: "success" });
                    }
                })
                .catch(error => {
                    enqueueSnackbar(`Đăng ký thất bại: ${error.response.data}`, { variant: "error" });
                })
        }

        // get user info
        // getMe()
        //     .then(response => {
        //         auth?.setAuthedUser(response.data);
        //     })
        //     .catch(error => {
        //         setStep("login");
        //         enqueueSnackbar(`Yêu cầu đăng nhập lại: ${error.response.data}`, { variant: "warning" });
        //     });
    }

    if (auth === undefined || auth?.loading)
        return <div className="text-xl text-center">Loading...</div>;

    return (
        <div
            className="w-full h-screen grid grid-cols-12 items-center"
        >
            {/* Modal for showing user nonexistence dialog */}
            <input
                type="checkbox"
                id="approval-form"
                className="modal-toggle"
                ref={modalCheckboxRef}
            />
            <label htmlFor="approval-form" className="modal cursor-pointer">
                <label className="modal-box relative" htmlFor="">
                    <div className="w-full bg-white flex flex-col gap-8 p-4 justify-center">
                        <span>Tài khoản không tồn tại. Bạn có muốn đăng ký tài khoản mới với số điện thoại này?</span>
                        <div className="w-full flex flex-row gap-2">

                            <button
                                className="btn btn-outline w-1/2"
                                onClick={() => {
                                    modalCheckboxRef.current?.click();
                                }}
                            >
                                Quay lại
                            </button>

                            <button
                                className="btn btn-success w-1/2"
                                onClick={() => {
                                    setStep("otp");
                                    modalCheckboxRef.current?.click();
                                }}
                            >
                                Đăng ký mới
                            </button>
                        </div>
                    </div>
                </label>
            </label>


            <div className="col-start-3 col-end-11 border rounded-lg bg-white grid sm:grid-cols-6 md:grid-cols-12 shadow">

                {/*====== Illustration =======*/}
                <div
                    className="sm:col-span-6 md:col-span-7 flex flex-row items-start justify-start px-8 py-8 gap-4"
                    style={{
                        background: `url(${process.env.PUBLIC_URL + "/images/login_ilu.svg"})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        minHeight: "200px",
                    }}
                >
                    <img src="/images/logo_192.png" alt="logo" className="sm:w-12 sm:h-12 md:w-20 md:h-20" />
                    {/* <div className="sm:text-2xl md:text-3xl text-green-600 font-medium">
                        Cộng đồng chia sẻ lịch taxi
                    </div> */}
                </div>

                {/*====== Form =======*/}
                <div className="sm:col-span-6 md:col-span-5 sm:pb-6 md:pb-12">
                    {
                        step === "login"
                            ? <FormLogin
                                handleFormSubmit={handleFormSubmit}
                                handleNonExistUser={(phone) => {
                                    setPhone(phone);
                                    modalCheckboxRef.current?.click();
                                }}
                            />
                            : step === "otp"
                                ? <FormOTP
                                    phone={phone}
                                    onConfirmOTP={(token) => { 
                                        console.log( "OTP done: ", token);
                                        setToken(token); setStep("signup"); 
                                    }}
                                    onBack={() => { setStep("login"); }}
                                />
                                : <FormSignUp
                                    handleFormSubmit={handleSignUp}
                                    phone={phone}
                                    token={token}
                                />
                    }
                </div>
            </div>
        </div>
    );
}