import React from "react";
import { Icon } from "@iconify/react";

const SearchBox = React.forwardRef( function ({
    onFocus = () => { },
    onBlur = () => { },
    onChange = () => { },
    onSubmit = () => { },
    onCancel = () => { },
    placeholder = "Tìm theo tên hoặc sđt",
    value = "",
    width = "100%"
}: {
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onSubmit?: (searchText: string) => void,
    onCancel?: () => void,
    placeholder?: string,
    value?: string,
    width?: string,
}, ref: React.ForwardedRef<HTMLInputElement>) {

    const [searchText, setSearchText] = React.useState<string>(value);
    const [showDelete, setShowDelete] = React.useState<boolean>(false);

    return (
        <form
            className="flex flex-row items-center gap-2 px-2 py-2 rounded-lg border border-gray-200"
            style={{ width: width }}
            onSubmit={(e) => {
                e.preventDefault();
                onSubmit(searchText);
            }}
        >
            <Icon icon="mdi:search" color="#9CA3AF" width="20" height="20" />
            <input
                type="text"
                className="w-full bg-transparent border-none outline-none"
                placeholder={placeholder}
                value={searchText}
                onChange={(e) => {
                    setSearchText(e.target.value);
                    onChange(e);
                }}
                onFocus={(event) => {
                    setShowDelete(true);
                    onFocus(event);
                }}
                onBlur={ (event) => {
                    if (event.target.value === "") setShowDelete(false);
                    onBlur(event);
                }}
                ref={ref}
            />
            
            { 
                showDelete 
                && <Icon 
                    className="cursor-pointer"
                    icon="iwwa:delete" 
                    color="#ababab" 
                    width="20" 
                    height="20" 
                    onClick={() => {
                        setSearchText("");
                        setShowDelete(false);
                        onCancel();
                    }}
                />
            }

        </form>
    )
});

export default SearchBox;