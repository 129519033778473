import React from "react";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Avatar from "../Avatar";
import { authContext } from "../../../auth/logic/authHook";
import { UserPublicInfo } from "../../models/User";
import { Noti } from "../../models/Noti";
import NotiCard from "./NotiCard";
import { getNotis } from "../../logic/api";
import { shouldLoadMore } from "../../logic/utils";
import {Badge,} from "@mui/material";

export default function AppBar({
    children,
}: {
    children?: React.ReactNode,
}) {

    const auth = React.useContext(authContext);
    const [notis, setNotis] = React.useState<Noti[]>([])
    const [notiPage, setNotiPage] = React.useState<number>(1);
    const [open, setOpen] = React.useState<HTMLButtonElement | null>(null);
    const [tab, setTab] = React.useState<"all" | "global">("all");

    const fetchNotis = async ( page: number, tab: string) => {
        const notis = await getNotis( page, tab );
        setNotis(notis.data);
        setNotiPage(page + 1);
    }
    
    const handleLoadMoreNotis = async () => {
        const newNotis = await getNotis( notiPage, tab );
        setNotis([...notis, ...newNotis.data]);
        setNotiPage(notiPage + 1);
    }

    // React.useEffect(() => {
    //     fetchNotis();
    // }, []);

    return (
        <div className="w-full px-4 py-2 flex flex-row items-center justify-between border-b border-gray-200">
            {
                children
                    ? children
                    : <div className="w-2 h-2 bg-transparent"></div> // dummy element to keep layout stable
            }

            {/* Avatar and Notification */}
            <div className="flex flex-row gap-5 items-center">

                <div className="badge bg-green-600 text-white font-semibold rounded p-3">
                    {`Số dư: ${auth?.authedUser?.point} điểm`}
                </div>

                {/* Notification */}
                <details className="dropdown dropdown-end">
                    <summary 
                        className="btn border-0 hover:bg-transparent p-0" 
                        onClick={(e) => {fetchNotis(0, tab)}}
                    >
                        <Badge badgeContent={0} color="error">
                            <NotificationsNoneIcon color="action" fontSize="medium" />
                        </Badge>
                    </summary>
                        
                    <div 
                        className={`p-2 shadow dropdown-content z-[1] bg-base-100 rounded-box flex flex-col
                                    w-[480px] h-[800px] drop-shadow-lg`}
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                        }}
                    >
                        {/* <div 
                            className="px-2 tabs w-full mb-4"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                // fetchNotis(0, "all");
                            }}
                        >
                            <a 
                                className={`tab tab-lg tab-bordered ${tab === "all" ? "tab-active" : ""}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    fetchNotis(0, "all");
                                }}
                            >Tất cả thông báo</a>
                            <a 
                                className={`tab tab-lg tab-bordered ${tab === "global" ? "tab-active" : ""}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    fetchNotis(0, "global");
                                }}
                            >Thông báo từ admin</a>
                        </div> */}
                        <ul 
                            className="overflow-y-scroll overflow-x-hidden"
                            onScroll={(event) => {
                                const ele = event.target as HTMLUListElement;
                                if (shouldLoadMore(
                                    ele.scrollHeight,
                                    ele.scrollTop,
                                    ele.clientHeight,
                                    false,
                                    1)
                                ) {
                                    handleLoadMoreNotis();
                                }
                            }}
                        >
                            {
                                notis.map((noti, index) => (
                                    <li key={index}>
                                        <NotiCard noti={noti} />
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </details>

                {/* Avatar */}
                <div className="rounded hover:bg-gray-100 px-2 py-2">
                    <Avatar
                        user={auth?.authedUser as UserPublicInfo}
                        title={auth?.authedUser?.name}
                        size="medium"
                    />
                </div>
            </div>
        </div>
    )
}