import React from "react";
import { ApprovalStatus } from "../../common/models/User";
import InputMultiLine from "../../common/ui/InputMultiLine";
import { ApprovalDecision } from "../../common/models/User";
import { BookingType } from "../../common/models/Booking";
import ToogleButton from "../../common/ui/ToogleButton";
import Select from "../../common/ui/Select";
import Input from "../../common/ui/Input";
import { vndCurrencyToNumber } from "../../common/logic/utils";
import { adminApproveProfile } from "../logic/api";
import { useSnackbar } from "notistack";

const defaultApprovalDecision: ApprovalDecision = {
    accept: false,
    note: "",
    rule: "admin",
    buy_type: [BookingType.GOODBYE, BookingType.WELCOME, BookingType.PROVINCE],
    deposit: 0,
    subscription: "none"
}


export default function AdminApprovalForm({
    approvalId,
    type,
    onApprovedSuccessfully = () => {},
    onApprovaedFailed = () => {},
}: {
    approvalId: string,
    type: ApprovalStatus.ACCEPTED | ApprovalStatus.REJECTED,
    onApprovedSuccessfully?: ( decision: ApprovalDecision ) => void,
    onApprovaedFailed?: () => void,
}) {

    const [approvalDecision, setApprovalDecision] = React.useState<ApprovalDecision>(defaultApprovalDecision);
    const { enqueueSnackbar } = useSnackbar();
    
    React.useEffect(() => {
        setApprovalDecision(defaultApprovalDecision);
    }, [type, approvalId]);

    const handleSumit = ( decision: ApprovalDecision ) => {
        adminApproveProfile(approvalId, decision)
        .then((res) => {
            enqueueSnackbar(`Phê duyệt ${type === ApprovalStatus.ACCEPTED 
                                        ? "thông qua"
                                        : 'từ chối' } hồ sơ thành công`, { variant: "success" });
            onApprovedSuccessfully( decision );
        })
        .catch((err) => {
            enqueueSnackbar(`Lỗi phê duyệt hồ sơ: ${err.response.data}`, { variant: "error" });
            onApprovaedFailed();
        });
    }

    return (
        <div
            className="w-full h-full bg-white flex flex-col gap-4 rounded-lg"
        >
            <div className="text-base font-semibold text-left">
                {
                    type === ApprovalStatus.ACCEPTED
                        ? "Xác nhận thông qua hồ sơ"
                        : "Xác nhận từ chối hồ sơ"
                }
            </div>

            {/* Type of booking driver can buy */

                type === ApprovalStatus.ACCEPTED
                &&
                <div className="flex flex-col gap-2">
                    <div className="text-sm font-medium text-gray-500">
                        Loại chuyến tài xế được bổ
                    </div>

                    <div className="flex flex-row gap-2">
                        <ToogleButton
                            text="Tiễn"
                            value={approvalDecision.buy_type ? approvalDecision.buy_type.includes(BookingType.GOODBYE) : false}
                            onChange={(value) => {
                                if (value) {
                                    setApprovalDecision({
                                        ...approvalDecision,
                                        buy_type: [
                                            ...approvalDecision.buy_type!,
                                            BookingType.GOODBYE
                                        ]
                                    });
                                } else {
                                    setApprovalDecision({
                                        ...approvalDecision,
                                        buy_type: approvalDecision.buy_type?.filter(type => type !== BookingType.GOODBYE)
                                    });
                                }
                            }}
                        />

                        <ToogleButton
                            text="Đón"
                            value={approvalDecision.buy_type ? approvalDecision.buy_type.includes(BookingType.WELCOME) : false}
                            onChange={(value) => {
                                if (value) {
                                    setApprovalDecision({
                                        ...approvalDecision,
                                        buy_type: [
                                            ...approvalDecision.buy_type!,
                                            BookingType.WELCOME
                                        ]
                                    });
                                } else {
                                    setApprovalDecision({
                                        ...approvalDecision,
                                        buy_type: approvalDecision.buy_type?.filter(type => type !== BookingType.WELCOME)
                                    });
                                }
                            }}
                        />

                        <ToogleButton
                            text="Tỉnh"
                            value={approvalDecision.buy_type ? approvalDecision.buy_type.includes(BookingType.PROVINCE) : false}
                            onChange={(value) => {
                                if (value) {
                                    setApprovalDecision({
                                        ...approvalDecision,
                                        buy_type: [
                                            ...approvalDecision.buy_type!,
                                            BookingType.PROVINCE
                                        ]
                                    });
                                } else {
                                    setApprovalDecision({
                                        ...approvalDecision,
                                        buy_type: approvalDecision.buy_type?.filter(type => type !== BookingType.PROVINCE)
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
            }


            {/* Membership grant */
                type === ApprovalStatus.ACCEPTED
                &&
                <Select
                    label="Tặng hạn thành viên"
                    options={[
                        { value: "none", label: "Không tặng" },
                        { value: "1_week", label: "Tặng 1 tuần" },
                        { value: "2_week", label: "Tặng 2 tuần" },
                        { value: "1_month", label: "Tặng 1 tháng" },
                        { value: "3_month", label: "Tặng 3 tháng" },
                        { value: "6_month", label: "Tặng 6 tháng" },
                        { value: "1_year", label: "Tặng 1 năm" },
                    ]}
                    value={approvalDecision.subscription}
                    onChange={event => setApprovalDecision({
                        ...approvalDecision,
                        subscription: event.target.value as ApprovalDecision["subscription"]
                    })}
                />
            }

            {/* Deposit */
                type === ApprovalStatus.ACCEPTED
                &&
                <Input
                    label="Tiền đặt cọc"
                    type="currency"
                    value={approvalDecision.deposit}
                    onChange={event => setApprovalDecision({
                        ...approvalDecision,
                        deposit: vndCurrencyToNumber(event.target.value)
                    })}
                />
            }

            {/* Note */}
            <InputMultiLine
                placeholder="Ghi chú cho tài xế"
                value={approvalDecision.note}
                onChange={event => setApprovalDecision({
                    ...approvalDecision,
                    note: event.target.value
                })}
                rows={3}
            />

            {
                type === ApprovalStatus.ACCEPTED
                    ? <button
                        className="btn btn-block btn-success"
                        onClick={() => {
                            handleSumit({
                                ...approvalDecision,
                                accept: true
                            });
                        }}
                    >
                        Xác nhận thông qua
                    </button>

                    : <button
                        className="btn btn-block btn-error"
                        onClick={() => {
                            handleSumit({
                                ...approvalDecision,
                                accept: false
                            });
                        }}
                    >
                        Xác nhận từ chối
                    </button>
            }
        </div>
    )
}