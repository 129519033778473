import React from "react";
import Avatar from "../../common/ui/Avatar";
import IconButton from "../../common/ui/IconButton";
import Chip from "../../common/ui/Chip";
import {
    appContext,
    getUserFromAppContext,
} from "../../common/logic/appContext";
import { authContext } from "../../auth/logic/authHook";
import { ConversationInfo, Message } from "../../common/models/Chat";
import { UserPublicInfo } from "../../common/models/User";
import { fTimeMessage } from "../../common/logic/time_utils";
import axios from "axios";

export default function ChatThreadCard({
    conversationInfo,
    selected,
    onClick = () => { },
}: {
    conversationInfo: ConversationInfo,
    selected: boolean,
    onClick?: () => void,
}) {
    const [avatarData, setAvatarData] = React.useState<string | undefined>(undefined);
    const [partner, setPartner] = React.useState<UserPublicInfo | undefined>(undefined);
    const appCtx = React.useContext(appContext);
    const auth = React.useContext(authContext);

    /*
     *======= Get avatar of the chat thread  ======
     *
     */
    React.useEffect(() => {
        
        if (conversationInfo.type === 'complaining') {
            // fixed avatar for complaining thread
            if (conversationInfo.complaining?.status === 'resolved')
                setAvatarData(`${process.env.PUBLIC_URL}/icons/complain_resolved.svg`)
            else
                setAvatarData(`${process.env.PUBLIC_URL}/icons/complain_unresolved.svg`)
        }
        else {
            // partner avatar if normal thread
            const partnerPhone = conversationInfo.users.filter((p) => p !== auth?.authedUser?.phone_number)[0];
            if (appCtx) {
                const userInCtx = getUserFromAppContext(partnerPhone, appCtx.users); // user here is phone number
                if (!userInCtx) {
                    axios({
                        method: 'GET',
                        url: `/user/${partnerPhone}`,
                    })
                        .then((res) => {
                            setPartner(res.data as UserPublicInfo);
                        })
                }
                else {
                    setPartner(userInCtx);
                }
            }
        }
    }, [conversationInfo]);


    return (
        <div 
            className={`w-full flex flex-row justify-between 
                        items-start px-4 py-3 border-b border-gray-100 
                        ${selected ? 'bg-green-200': ''}`}
            onClick={onClick}
        >
            <Avatar
                src={avatarData}
                user={partner}  // user avatar would be prioritized over src
                title={(conversationInfo.type === 'complaining') 
                        ? `Khiếu nại lịch xe: ${conversationInfo.complaining?.booking_short_id}` 
                        : partner?.name}
                subtitle={conversationInfo.last_message?.text}
                size="large"
            />

            <div className="flex flex-col items-end justify-start gap-2">
                <span className="text-xs text-gray-500">
                    { conversationInfo.last_message?.created_time ? fTimeMessage( conversationInfo.last_message.created_time ) : "" }
                </span>
            </div>

        </div>
    )

}