import React from "react";
import AppBar from "../../common/ui/Nav/AppBar";
import SearchBox from "../../common/ui/SearchBox";
import { chatContext } from "../logic/chatContext";
import ChatThreadCard from "../components/ChatThreadCard";
import MessageCard from "../components/MessageCard";
import MessageForm from "../forms/MessageForm";
import { searchConversation } from "../logic/api";
import { ConversationInfo, SearchChatResult } from "../../common/models/Chat";
import SearchChatCard from "../components/SearchChatCard";
import {
  UserPublicInfo,
  getDefaultUserPublicInfo,
} from "../../common/models/User";
import { authContext } from "../../auth/logic/authHook";
import {
  appContext,
  getUserFromAppContext,
} from "../../common/logic/appContext";
import { shouldLoadMore } from "../../common/logic/utils";
import axios from "axios";
import Avatar from "../../common/ui/Avatar";
import ComplaintDetail from "../components/ComplaintDetail";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import NavList from "../../common/ui/Nav/NavList";
import menu_json from "../../common/config/menu.json";

var booking_sub_menu = menu_json[0].sub_menus;

function ChatPage() {
  const [showComplaintDetail, setShowComplaintDetail] =
    React.useState<boolean>(false);
  const [partner, setPartner] = React.useState<UserPublicInfo | undefined>(
    undefined
  );
  const [lastSearchText, setLastSearchText] = React.useState<string>("");
  const [searchChatResults, setSearchChatResults] = React.useState<
    SearchChatResult[] | undefined
  >(undefined);
  const [selectedSearchChatID, setSelectedSearchChatID] =
    React.useState<string>("");
  const [showSearchResult, setShowSearchResult] =
    React.useState<boolean>(false);
  const auth = React.useContext(authContext);
  const appCtx = React.useContext(appContext);
  const chatCtx = React.useContext(chatContext);
  const location = useLocation();
  const navigate = useNavigate();

  let bodyClassName = showComplaintDetail ? "col-span-8" : "col-span-12";
  let sidePopupClassName = showComplaintDetail ? "flex" : "hidden";

  /*
   *======= Get avatar of the chat thread  ======
   *
   */
  const handleSelectChat = (
    conversation: ConversationInfo | undefined,
    partner: UserPublicInfo | undefined
  ) => {
    if (conversation) {
      if (conversation.type === "complaining") {
        // fixed avatar for complaining thread
        setPartner({
          ...getDefaultUserPublicInfo(),
          name: `Khiếu nại lịch xe: ${conversation.complaining?.booking_short_id}`,
          avatar_data_string:
            conversation.complaining?.status === "resolved"
              ? `${process.env.PUBLIC_URL}/icons/complain_resolved.svg`
              : `${process.env.PUBLIC_URL}/icons/complain_unresolved.svg`,
        });
      } else {
        const partnerPhone = conversation.users.find(
          (p) => p !== auth?.authedUser?.phone_number
        );
        if (appCtx && partnerPhone) {
          const userInCtx = getUserFromAppContext(partnerPhone, appCtx.users); // user here is phone number
          if (!userInCtx) {
            axios({
              method: "GET",
              url: `/user/${partnerPhone}`,
            }).then((res) => {
              setPartner(res.data as UserPublicInfo);
            });
          } else {
            setPartner(userInCtx);
          }
        }
      }
    } else if (partner) {
      setPartner(partner as UserPublicInfo);
    }

    navigate(`/chat`);
  };

  /*
   *======= When search request submitted
   *
   */
  const handleSearch = (searchText: string) => {
    const finalRes = [] as SearchChatResult[];
    setSearchChatResults(undefined);
    if (searchText === "") {
      return;
    }

    searchConversation(searchText).then((res) => {
      // get found conversations
      if (res[0].status === "fulfilled") {
        const searchChats = res[0].value.data?.map(
          (conversationInfo: ConversationInfo) => {
            return {
              conversation: conversationInfo,
              hasMessage: true,
            };
          }
        );
        finalRes.push(...searchChats!);
      }

      // get found users
      if (res[1].status === "fulfilled") {
        let searchChats = [] as SearchChatResult[];
        (res[1].value.data! as UserPublicInfo[]).forEach((user) => {
          // check if this user has conversation
          const hasConversation = finalRes?.find((searchChat) => {
            return (
              searchChat.conversation?.type === "normal" &&
              searchChat.conversation.users.includes(user.phone_number)
            );
          });

          // if not, add to search result
          if (hasConversation === undefined) {
            searchChats.push({
              user: user,
              hasMessage: false,
            });
          }
        });

        // add to search result
        if (searchChats.length > 0) {
          finalRes.push(...searchChats);
        }
        setSearchChatResults(finalRes);
      }
    });
  };

  /*
   *======= When search input changing
   *
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Math.abs(event.target.value.length - lastSearchText.length) > 2) {
      handleSearch(event.target.value);
      setLastSearchText(event.target.value);
    }
  };

  /*
   *======= Display complaint detail if selected chat is a complaining thread
   *
   */
  React.useEffect(() => {
    if (chatCtx?.conversation && chatCtx.conversation) {
      setShowComplaintDetail(chatCtx.conversation.type === "complaining");
    } else {
      setShowComplaintDetail(false);
    }
  }, [chatCtx?.conversation]);

  return (
    <div className="w-full h-full grid grid-cols-12 overflow-y-auto">
      <div
        className={`${bodyClassName} h-full flex flex-col-reverse overflow-y-scroll`}
      >
        {/* Message input form */}
        {chatCtx?.selectConversionId &&
          chatCtx?.conversation?.complaining?.settlement === undefined && (
            <MessageForm onSubmit={() => {}} />
          )}

        {/* List of all messages */}
        <div
          className={`w-full h-full flex flex-col-reverse overflow-y-scroll px-4 py-2 bg-gray-100 gap-1`}
          onScroll={(event) => {
            const ele = event.target as HTMLDivElement;
            if (
              shouldLoadMore(
                ele.scrollHeight,
                ele.scrollTop,
                ele.clientHeight,
                true,
                1
              )
            ) {
              console.log("load more");
            }
          }}
        >
          {chatCtx?.conversation &&
          chatCtx?.conversation?.messages !== undefined &&
          chatCtx?.conversation?.messages.length > 0 ? (
            chatCtx?.conversation?.messages.map((message, idx) => {
              return (
                <MessageCard
                  key={idx}
                  message={message}
                  lastMessage={chatCtx?.conversation?.messages![idx + 1]}
                />
              );
            })
          ) : chatCtx?.selectConversionId === undefined ? (
            <div className="w-full h-full flex flex-row items-center justify-center">
              Không có cuộc trò chuyện nào được chọn
            </div>
          ) : (
            <div className="w-full h-full flex flex-row items-center justify-center">
              Không có tin nhắn nào
            </div>
          )}
        </div>

        {/* Top Bar */}
        <AppBar>
          {partner && (
            <Avatar user={partner} title={partner.name} size="large" />
          )}
        </AppBar>
      </div>

      {/* Booking options form Sidepopup */}
      <div
        className={`${sidePopupClassName} col-span-4 h-full flex flex-col-reverse border-l border-gray-200 overflow-y-scroll`}
      >
        <ComplaintDetail conversation={chatCtx?.conversation} />
      </div>
    </div>
  );
}

export default React.memo(ChatPage);
