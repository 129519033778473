import React from "react";
import { ApprovalStatus } from "../../common/models/User";
import InputMultiLine from "../../common/ui/InputMultiLine";
import { ApprovalDecision } from "../../common/models/User";
import { BookingType } from "../../common/models/Booking";
import ToogleButton from "../../common/ui/ToogleButton";
import Select from "../../common/ui/Select";
import Input from "../../common/ui/Input";
import { vndCurrencyToNumber } from "../../common/logic/utils";
import { adminApproveProfile } from "../logic/api";
import { useSnackbar } from "notistack";

const defaultApprovalDecision: ApprovalDecision = {
    accept: false,
    note: "",
    rule: "admin",
}


export default function TrainsactionApprovalForm({
    approvalId,
    type,
    onApprovedSuccessfully = () => {},
    onApprovaedFailed = () => {},
}: {
    approvalId: string,
    type: ApprovalStatus.ACCEPTED | ApprovalStatus.REJECTED,
    onApprovedSuccessfully?: ( decision: ApprovalDecision ) => void,
    onApprovaedFailed?: () => void,
}) {

    const [approvalDecision, setApprovalDecision] = React.useState<ApprovalDecision>(defaultApprovalDecision);
    const { enqueueSnackbar } = useSnackbar();
    
    React.useEffect(() => {
        setApprovalDecision(defaultApprovalDecision);
    }, [type, approvalId]);

    const handleSumit = ( decision: ApprovalDecision ) => {
        adminApproveProfile(approvalId, decision)
        .then((res) => {
            enqueueSnackbar(`Phê duyệt ${type === ApprovalStatus.ACCEPTED 
                                        ? "thông qua"
                                        : 'từ chối' } hồ sơ thành công`, { variant: "success" });
            onApprovedSuccessfully( decision );
        })
        .catch((err) => {
            enqueueSnackbar(`Lỗi phê duyệt hồ sơ: ${err.response.data}`, { variant: "error" });
            onApprovaedFailed();
        });
    }

    return (
        <div
            className="w-full h-full bg-white flex flex-col gap-4 rounded-lg"
        >
            <div className="text-base font-semibold text-left">
                {
                    type === ApprovalStatus.ACCEPTED
                        ? "Xác nhận thông giao dịch"
                        : "Xác nhận từ chối giao dịch"
                }
            </div>


            {/* Note */}
            <InputMultiLine
                placeholder="Ghi chú cho tài xế"
                value={approvalDecision.note}
                onChange={event => setApprovalDecision({
                    ...approvalDecision,
                    note: event.target.value
                })}
                rows={3}
            />

            {
                type === ApprovalStatus.ACCEPTED
                    ? <button
                        className="btn btn-block btn-success"
                        onClick={() => {
                            handleSumit({
                                ...approvalDecision,
                                accept: true
                            });
                        }}
                    >
                        Xác nhận thông qua
                    </button>

                    : <button
                        className="btn btn-block btn-error"
                        onClick={() => {
                            handleSumit({
                                ...approvalDecision,
                                accept: false
                            });
                        }}
                    >
                        Xác nhận từ chối
                    </button>
            }
        </div>
    )
}