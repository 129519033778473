import React from "react";
import { Notification, UserPublicInfo } from "../../common/models/User";
import { fDateTime } from "../../common/logic/time_utils";
import { downloadFile } from "../../common/logic/api";
import { recallNotification } from "../logic/api";
import IconButton from "../../common/ui/IconButton";
import InfoWithLabel from "../../common/ui/InfoWithLabel";
import { useSnackbar } from "notistack";

export default function NotificationDetails({
    noti,
    onClose = () => { },
}: {
    noti: Notification,
    onClose?: (noti?: Notification) => void,
}) {

    const [userData, setUserData] = React.useState<any>(undefined);
    const [image, setImage] = React.useState<string>("");
    const modalCheckboxRef = React.useRef<HTMLInputElement>(null);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        // Get user info
        const dataObj = JSON.parse(noti.data.data);
        setUserData(dataObj.user);

        // Get photo
        if (dataObj.image) {
            downloadFile(dataObj.image)
                .then((url) => {
                    setImage(url);
                })
                .catch((err) => {
                    enqueueSnackbar(`Không thể tải ảnh đính kèm với lý do: ${err.response.data}`,
                        { variant: "error" });
                });
        }
        else {
            setImage("");
        }

    }, [noti]);


    const handleRecall = () => {
        recallNotification(noti._id)
            .then(() => {
                const newNoti = {
                    ...noti,
                    data: {
                        ...noti.data,
                        type: "global_recall",
                    }
                };
                enqueueSnackbar("Thu hồi thông báo thành công", { variant: "success" });
                onClose(newNoti);
            })
            .catch((err) => {
                enqueueSnackbar(`Không thể thu hồi thông báo với lý do: ${err.response.data}`,
                    { variant: "error" });
            });
    }


    return (
        <div className="w-full h-full flex flex-col gap-4 px-4 justify-between overflow-y-scroll">

            {/* Modal for showing approval form */}
            {
                (noti.data.type === "global") &&
                (
                    <div>
                        <input
                            type="checkbox"
                            id="approval-form"
                            className="modal-toggle"
                            ref={modalCheckboxRef}
                        />
                        <label htmlFor="approval-form" className="modal cursor-pointer">
                            <label className="modal-box relative" htmlFor="">
                                <div className="w-full bg-white flex flex-col gap-8 p-4 justify-center">
                                    <span>Bạn có chắc chắn muốn thu hồi thông báo này</span>
                                    <div className="w-full flex flex-row gap-2">
                                        <button
                                            className="btn btn-error w-1/2"
                                            onClick={handleRecall}
                                        >
                                            Thu hồi
                                        </button>

                                        <button
                                            className="btn btn-success w-1/2"
                                            onClick={() => {
                                                modalCheckboxRef.current?.click();
                                            }}
                                        >
                                            Quay lại
                                        </button>
                                    </div>
                                </div>
                            </label>
                        </label>
                    </div>
                )
            }

            <div className="w-full h-full flex flex-col gap-4">
                {/* Form Title */}
                <div className="py-3 flex flex-row justify-between items-start">
                    <div className="flex flex-col gap-2 items-start">
                        <div className="text-lg font-semibold text-gray-700">
                            {noti.notification.title}
                        </div>
                        <div className="flex flex-row gap-2 items-center">
                            <div className="text-sm text-left font-medium text-gray-500">
                                Trạng thái:
                            </div>

                            {
                                noti.data.type === "global"
                                    ? <span className="badge badge-success badge-sm">Đã ban hành</span>
                                    : <span className="badge badge-error badge-sm">Đã thu hồi</span>
                            }
                        </div>
                    </div>

                    <IconButton
                        icon="ion:close-outline"
                        variant="mute"
                        onClick={() => {
                            onClose();
                        }}
                    />
                </div>

                {/* content */}
                <div className="w-full flex flex-col gap-2">

                    <InfoWithLabel
                        label="Thời gian tạo"
                        info={fDateTime(noti.createdAt, "HH:mm - dd/MM/yyyy")}
                        direction="row"
                    />

                    {/* <InfoWithLabel
                        label="Người tạo"
                        info={userData.name}
                        direction="row"
                        multilines={true}
                    /> */}

                    <InfoWithLabel
                        label="Nội dung thông báo"
                        info={noti.notification.body}
                        direction="column"
                        multilines={true}
                    />
                </div>

                {/* Photo */}
                {
                    (image !== "") &&
                    <div className="w-full flex flex-col gap-2">
                        <div className="text-sm text-left font-medium text-gray-500">
                            Ảnh đính kèm
                        </div>

                        <img
                            className="w-80 h-auto object-cover rounded-lg"
                            src={image}
                            alt="Ảnh thông báo"
                        />
                    </div>
                }
            </div>

            {/* Buttons */}
            {
                noti.data.type === "global" &&

                <button
                    className="btn btn-block btn-error mt-12 mb-4"
                    onClick={() => {
                        modalCheckboxRef.current?.click();
                    }}
                >
                    Thu hồi thông báo
                </button>
            }
        </div>
    );
}