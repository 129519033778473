import axios from "axios";
import imageCompression from "browser-image-compression";
import { UserPublicInfo } from "../models/User";


/*
 * FUNCTION TO DOWNLOAD A FILE WITH A FILE_ID
 *  - @param fil_id: id of the file in the storage
 *  - @param type: type of the file (image, audio, video, document)
 *  - @return: a Promise of File object
 *
 */
export async function downloadFile(
    file_id: string,
    type: "image" | "audio" | "video" | "document" = "image"
) {
    if (!file_id) return undefined;

    const fileUrl = await axios({
        method: "GET",
        url: `/files/${file_id}`,
    });

    const fileData = await axios({
        method: 'GET',
        url: fileUrl.data.url,
        responseType: 'arraybuffer',
    });

    if (type === "image") {
        const blob = new Blob([fileData.data], { type: 'image/jpeg' });
        return URL.createObjectURL(blob);
    }
    else {
        return fileData.data;
    }
}


/*
 * FUNCTION TO UPLOAD A FILE TO THE STORAGE
 *  - @param file: File object to be uploaded
 *  - @param type: type of the file (image, audio, video, document)
 *  - @return: a Promise of file_id
 *
 */
export async function uploadFile(
    conversation_id: string | undefined,
    file: File,
    type: "image" | "audio" | "video" | "document" = "image"
): Promise<{ file_id: string, url: string }> {

    // compress image
    let _file: File = file;
    if (type === "image" && file !== null && file.size > 600000) {
        const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1440,
            useWebWorker: true
        }

        const compressedFile = await imageCompression(file, options);
        _file = compressedFile;
    }

    // get url to upload file
    const ret = await axios({
        method: "POST",
        url: `/files`,
        data: {
            type: type,
            permission: "public",
            conversation_id: conversation_id,
        },
    });

    // upload file
    const formData = new FormData();
    formData.append("file", _file);
    axios({
        method: "POST",
        url: ret.data.url,
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

    return ret.data;
}



/*
 * FUNCTION GET USER INFO BY PHONE
 *  - @param phone: phone number of the user
 *  - @return: a Promise of type UserPublicInfo
 *
 */
export async function getUserByPhone(phone: string): Promise<UserPublicInfo> {

    const res = await axios({
        method: "GET",
        url: `/user/${phone}`,
    });

    return res.data;

}

export async function updateNotiToken(token: string) {
    await axios({
        method: "POST",
        url: `/user/token`,
        data: {
            token,
        },
    });
}

export async function getNotiToken() {
    return await axios({
        method: "GET",
        url: `/user/token`,
    });
}

export async function getNotis(page: number, type: string) {
    return await axios({
        method: "GET",
        url: `/user/notification`,
        params: {
            page,
            type,
        },
    });
}