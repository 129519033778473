import React from "react";
import { UserPublicInfo, UserInfo } from "../../common/models/User";
import { getPublicUserByPhone } from "../../account/logic/api";
import Avatar from "../../common/ui/Avatar";
import IconButton from "../../common/ui/IconButton";
import { 
    appContext, 
    getUserFromAppContext,
    saveUserToAppContext,
} from "../../common/logic/appContext";
import { useSnackbar } from "notistack";

export default function DriverInfoCard({
    user,
    subtitle = "",
    enableChat = true,
    className = "",
    onChatClick = () => {},
    onClick = () => {},
} :{ 
    user: UserPublicInfo | UserInfo | string,
    subtitle?: string,
    enableChat?: boolean,
    className?: string,
    onChatClick?: () => void,
    onClick?: () => void,
}) {

    const [_user, setUser] = React.useState<UserPublicInfo | null | undefined>(undefined);
    const appCtx = React.useContext(appContext);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        if(typeof user === "string") {
            // find cached user
            const cachedUser = getUserFromAppContext(user, appCtx?.users!);
            if(cachedUser) {
                setUser(cachedUser);
            } else {
                // download user info
                getPublicUserByPhone(user)
                .then(response => {
                    setUser(response.data);
                    saveUserToAppContext(response.data, appCtx?.users!, appCtx?.setUsers!);
                })
                .catch(err => {
                    enqueueSnackbar(`Lỗi tải thông tin tài xế ${err.response.data}`, { variant: "error" });
                });
            }
        } else {
            setUser(user as UserPublicInfo);
        }
    }, [user]);

    if( !_user ) {
        return null;
    }

    return (
        <div className={`w-full flex flex-row justify-between items-center py-3 ${className}`}>
            <Avatar
                user={_user}
                title={_user.name}
                subtitle={subtitle ? subtitle : _user.phone_number}
                size="large"
                width="100%"
                onClick={onClick}
            />

            {enableChat &&
                <IconButton
                    icon="solar:chat-dots-linear"
                    variant="success"
                    onClick={onChatClick}
                />
            }

        </div>
    )
}