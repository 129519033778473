import React from "react";
import { BookingPublicInfoV2, BookingInfoV2, BookingType, BookingInfoV2Req } from "../../common/models/Booking";
import InputMultiLine from "../../common/ui/InputMultiLine";
import Select from "../../common/ui/Select";
import Input from "../../common/ui/Input";
import CheckBox from "../../common/ui/CheckBox";
import PhotoSelect from "../../common/ui/PhotoSelect";
import IconButton from "../../common/ui/IconButton";
import axios from "axios";
import { hasOptionalOptions } from "../logic/utils";
import { useSnackbar } from "notistack";

export default function BookingOptionsForm({
    bookingInfo,
    onSubmit = () => { },
    onClose = () => { },
}: {
    bookingInfo: BookingInfoV2,
    onSubmit: (bookingInfo: BookingPublicInfoV2) => void,
    onClose: () => void,
}) {

    const [_bookingInfo, setBookingInfo] = React.useState<BookingInfoV2>(bookingInfo);
    const [moreOptions, setMoreOptions] = React.useState<boolean>(hasOptionalOptions(bookingInfo));
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        setBookingInfo(bookingInfo);
        setMoreOptions(hasOptionalOptions(bookingInfo));
    }, [bookingInfo]);

    const handleSubmit = () => {
        axios({
            url: "/v2/booking/" + _bookingInfo._id,
            method: "PATCH",
            data: _bookingInfo as BookingInfoV2
        })
        .then(res => {
            enqueueSnackbar("Cập nhật thông tin lịch xe thành công!", { variant: "success" });
            onSubmit(res.data);
        })
        .catch(err => {
            enqueueSnackbar(`Không thể cập nhật thông tin lịch xe với lý do: ${err.response.data}`, { variant: "error" });
        });
    }

    return (
        <form
            className="w-full h-full flex flex-col gap-4 px-4 py-3 justify-between overflow-y-scroll"
            onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
            }}
        >
            {/* Form Label */}
            <div className="py-3 flex flex-row justify-between items-start">
                <div className="flex flex-col gap-2 items-start">
                    <div className="text-xl font-semibold text-gray-700">
                        Chỉnh sửa chuyến xe
                    </div>

                    <div className="text-sm text-left font-medium text-gray-500">
                        {`Mã số: ${_bookingInfo.short_id}`}
                    </div>
                </div>

                <IconButton
                    icon="ion:close-outline"
                    variant="mute"
                    onClick={() => {
                        onClose();
                    }}
                />
            </div>

            <div className="w-full h-full flex flex-col gap-4">
                {/* Booking Content */}
                <InputMultiLine
                    label="Nội dung chuyến xe"
                    value={_bookingInfo.content}
                    onChange={(event) => {
                        setBookingInfo({
                            ..._bookingInfo,
                            content: event.target.value,
                        });
                    }}
                    rows={3}
                />

                {/* Booking Route Type and Point cost */}
                <div className="w-full flex flex-row gap-4 items-center">
                    <Select
                        label="Loại lộ trình"
                        options={[{ value: BookingType.GOODBYE, label: "Tiễn" },
                        { value: BookingType.WELCOME, label: "Đón" },
                        { value: BookingType.PROVINCE, label: "Tỉnh" }]}
                        value={_bookingInfo.type}
                        onChange={(event) => {
                            setBookingInfo({
                                ..._bookingInfo,
                                type: event.target.value as BookingType,
                            });
                        }}
                        width="50%"
                    />

                    <Select
                        label="Điểm bổ lịch"
                        options={
                            Array.from(
                                { length: 12 },
                                (_, index) => index
                                    ? { value: index * 0.25, label: `${index * 0.25} điểm` }
                                    : { value: 0, label: "Free" })}
                        value={_bookingInfo.point_cost}
                        onChange={(event) => {
                            setBookingInfo({
                                ..._bookingInfo,
                                point_cost: parseFloat( event.target.value ),
                            });
                        }}
                        width="50%"
                    />
                </div>

                {/* Customer Phone Number */}
                <Input
                    label="Số điện thoại khách hàng"
                    value={_bookingInfo.client_phone}
                    onChange={(event) => {
                        setBookingInfo({
                            ..._bookingInfo,
                            client_phone: event.target.value,
                        });
                    }}
                />

                {/* ============== Optional Section =============== */}
                <CheckBox
                    label="Thêm tuỳ chọn"
                    checked={moreOptions}
                    onChange={(event) => {
                        setMoreOptions(event.target.checked);
                    }}
                    style={{
                        marginTop: "1rem",
                    }}
                />

                {
                    moreOptions &&
                    <div className="w-full flex flex-col gap-4">
                        {/* Car seats and Pickup time */}
                        <div className="w-full flex flex-row gap-4 items-center">
                            <Select
                                label="Loại xe"
                                options={[{ value: 4, label: "4 chỗ" },
                                { value: 5, label: "5 chỗ" },
                                { value: 7, label: "7 chỗ" },
                                { value: 16, label: "16 chỗ" }]}
                                value={_bookingInfo.seats}
                                width="50%"
                                onChange={(event) => {
                                    setBookingInfo({
                                        ..._bookingInfo,
                                        seats: parseInt(event.target.value),
                                    });
                                }}
                            />

                            <Select
                                label="Thời gian đón"
                                options={[{ value: 4, label: "4 chỗ" },
                                { value: 5, label: "5 chỗ" },
                                { value: 7, label: "7 chỗ" },
                                { value: 16, label: "16 chỗ" }]}
                                width="50%"
                            />
                        </div>

                        {/* Note */}
                        <InputMultiLine
                            label="Ghi chú"
                            value={_bookingInfo.note}
                            onChange={(event) => {
                                setBookingInfo({
                                    ..._bookingInfo,
                                    note: event.target.value,
                                });
                            }}
                            rows={3}
                        />

                        {/* Photos */}
                        <PhotoSelect
                            label="Ảnh chụp"
                            value={_bookingInfo.image}
                            onChange={(event) => {
                                setBookingInfo({
                                    ..._bookingInfo,
                                    image: event.target.value,
                                });
                            }}
                            width="100px"
                        />
                    </div>
                }
            </div>

            <div className="pt-12 bg-white" >
                <button
                    type="submit"
                    className="w-full py-3 bg-green-500 text-white rounded-lg"
                >
                    Cập nhật chuyến
                </button>
            </div>
        </form>
    )
}
