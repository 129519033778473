import React from "react";
import Avatar from "../../common/ui/Avatar";
import ComplaintContentCard from "./ComplaintContentCard";
import { ConversationInfo } from "../../common/models/Chat";
import { UserPublicInfo } from "../../common/models/User";
import {
    appContext,
    saveUserToAppContext,
} from "../../common/logic/appContext";
import { chatContext } from "../logic/chatContext";
import { getBookingByID } from "../../booking/logic/api";
import { getUserByPhone } from "../../common/logic/api";
import { resolveComplaint } from "../logic/api";
import { finishBooking } from "../../booking/logic/api";
import { BookingPublicInfoV2, BookingInfoV2, BookingStatus } from "../../common/models/Booking";
import DriverInfoCard from "../../booking/components/DriverInfoCard";
import BookingCard from "../../booking/components/BookingCard";
import Select from "../../common/ui/Select";
import CheckBox from "../../common/ui/CheckBox";
import InputMultiLine from "../../common/ui/InputMultiLine";
import {authContext } from "../../auth/logic/authHook";
import { 
    SettlementInfo,
    getDefaultComplaint
} from "../../common/models/Complaint";
import { useSnackbar } from "notistack";

export default function ComplaintDetail({ conversation }: { conversation: ConversationInfo | undefined }
) {

    const [booking, setBooking] = React.useState<BookingInfoV2 | undefined>(undefined);
    const [admins, setAdmins] = React.useState<UserPublicInfo[]>([]);
    const appCtx = React.useContext(appContext);
    const [shouldPenalize, setShouldPenalize] = React.useState<boolean>(false);
    const [shouldRefund, setShouldRefund] = React.useState<boolean>(false);
    const [resolved, setResolved] = React.useState<boolean>(false);
    const [settlement, setSettlement] = React.useState<SettlementInfo>(getDefaultComplaint().settlement);
    const chatCtx = React.useContext(chatContext);
    const authCtx = React.useContext(authContext);
    const {enqueueSnackbar} = useSnackbar();

    // Force finish booking 
    const handleForceFinishBooking = () => {
        if( booking === undefined ) return;

        finishBooking( booking._id )
        .then( res => {
            enqueueSnackbar( "Đã hoàn thành chuyến thay tài xế thành công", {variant: "success"} );
            setBooking( {
                ...booking,
                status: BookingStatus.FINISHED
            })
        })
        .catch( err => {
            enqueueSnackbar( `Lỗi hoàn thành chuyến thay tài xế: ${err.response.data}`, {variant: "error"} )
        })
    }

    // load users involved in the conversation
    React.useEffect(() => {
        if (conversation && conversation.complaining) {
            const booking_id = conversation.complaining.booking_id;
            getBookingByID(booking_id)
                .then((res) => {
                    const bookingTmp = res as BookingInfoV2;
                    setBooking(bookingTmp);

                    // get admins
                    const adminPhones = conversation.users.filter((user) => {
                        return user !== bookingTmp.user_creator.phone_number && user !== bookingTmp.buyer.phone_number;
                    });

                    Promise.all(adminPhones.map((phone) => {
                        return getUserByPhone(phone);
                    }))
                        .then((users) => {
                            setAdmins(users as UserPublicInfo[]);
                            saveUserToAppContext(users as UserPublicInfo[], appCtx?.users!, appCtx?.setUsers!);
                        });


                })
        }

        if( conversation?.complaining?.settlement){
            setSettlement(conversation?.complaining.settlement);
            setResolved(true);
        }
        else {
            setResolved(false);
        }

    }, [conversation]);


    /*
     *======= Handle submit resolve complaint
     *
     */
    const handleResolvingComplaint = () => {
        resolveComplaint(
            settlement,
            conversation?._id as string,
        )
        .then((res) => {            
            setResolved(true);
            enqueueSnackbar( "Giải quyết khiếu nại thành công", {variant: "success"});

            // update conversation in the list
            let resolvedConv = {...conversation} as ConversationInfo;
            resolvedConv.complaining!.settlement = settlement;
            console.log( "------------> resolved conv: ", resolvedConv );
            chatCtx?.updateConversation(resolvedConv);

        })
        .catch((error) =>{
            enqueueSnackbar( `Lỗi giải quyết khiếu nại: ${error.response.data}`, {variant: "error"} )
        })
    }

    // display nothing if the conversation is not a complaint
    if (conversation === undefined) {
        return null;
    }

    return (
        <div className="w-full h-full flex flex-col px-4 py-3 gap-4 overflow-y-scroll">
            <ComplaintContentCard
                conversation={conversation}
            />

            <div className="w-full flex flex-col">
                <div
                    tabIndex={0}
                    className="collapse collapse-arrow collapse-open py-0 border-b border-gray-200"
                >
                    <div className="collapse-title text-base font-medium px-0">
                        Thông tin chuyến xe
                    </div>
                    <div className="collapse-content  px-0 py-0 flex flex-col gap-6">
                        <BookingCard
                            booking={booking as BookingPublicInfoV2}
                            show_status={true}
                        />
                        {
                            booking?.status === BookingStatus.ACTIVATED
                            &&
                            <button
                                className="btn btn-warning btn-sm mb-4"
                                onClick={(e) => 
                                    handleForceFinishBooking()
                                }
                            >
                                Hoàn thành chuyến
                            </button>
                        }
                    </div>
                </div>

                <div
                    tabIndex={0}
                    className="collapse collapse-arrow py-0 border-b border-gray-200"
                >
                    <div className="collapse-title text-base font-medium px-0">
                        Các bên liên quan
                    </div>
                    <div className="collapse-content px-0 py-0">
                        <div className="flex flex-col gap-2">
                            {booking?.user_creator &&
                                <DriverInfoCard
                                    user={booking.user_creator}
                                    subtitle="Chủ lịch"
                                />
                            }

                            {booking?.buyer &&
                                <DriverInfoCard
                                    user={booking.buyer}
                                    subtitle="Tài xế bổ"
                                />
                            }
                        </div>
                    </div>
                </div>

                <div
                    tabIndex={1}
                    className="collapse collapse-arrow py-0  border-b border-gray-200"
                    onClick={() => {
                        const tmp = document.getElementById("collapse-1");
                        if (tmp) {
                            tmp.classList.toggle("collapse-open");
                        }
                    }}
                >
                    <div className="collapse-title text-base font-medium px-0">
                        Bộ phận giải quyết
                    </div>
                    <div className="collapse-content px-0 py-0 flex flex-col gap-4">
                        {admins.map((admin) => {
                            return (
                                <Avatar
                                    key={admin.phone_number}
                                    user={admin}
                                    size="medium"
                                    showTitle={true}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>

            {
                !resolved && ["admin-1", "admin-2", "admin-3", "admin-4"].includes(authCtx?.authedUser?.type as string)
                    ? ( // Panel to resolve the complaint
                        <div className="flex flex-col gap-4 mt-6">
                            <div className="text-base font-semibold text-green-700">
                                Giải quyết khiếu nại
                            </div>

                            <Select
                                label="Kết quả khiếu nại"
                                options={[
                                    { value: "makepeace", label: "Xử hoà" },
                                    { value: "creator", label: `Chủ lịch (${booking?.user_creator?.name}) thua` },
                                    { value: "driver", label: `Tài xế bổ (${booking?.buyer?.name}) thua` },
                                ]}
                                value={settlement.type}
                                onChange={(e) => {
                                    setSettlement({
                                        ...settlement,
                                        type: e.target.value as SettlementInfo["type"],
                                    });
                                    
                                    if( e.target.value === 'makepeace' )
                                    {
                                        setShouldPenalize(false);
                                        setShouldRefund(false);
                                        setSettlement({
                                            ...settlement,
                                            penalty: {
                                                block_account: undefined,
                                                point: 0,
                                            },
                                            award: {
                                                point: 0,
                                            },
                                        });
                                    }
                                }}
                            />

                            {
                                settlement.type !== 'makepeace' &&
                                <CheckBox
                                label="Xử phạt bên thua"
                                checked={shouldPenalize}
                                onChange={(e) => {
                                    setShouldPenalize(e.target.checked);
                                    if (!e.target.checked) {
                                        setSettlement({
                                            ...settlement,
                                            penalty: {
                                                block_account: undefined,
                                                point: 0,
                                            },
                                        })
                                    }
                                }}
                            />}

                            {shouldPenalize &&
                                <div className="flex flex-row gap-2">
                                    <Select
                                        label="Số điểm phạt"
                                        options={Array.from(
                                            { length: 12 },
                                            (_, index) => index
                                                ? { value: index * 0.25, label: `${index * 0.25} điểm` }
                                                : { value: 0, label: "0" })}
                                        width="50%"
                                        onChange={(e) => {
                                            setSettlement({
                                                ...settlement,
                                                penalty: {
                                                    ...settlement.penalty,
                                                    point: Number(e.target.value),
                                                },                                                
                                            });
                                        }}
                                        value={settlement.penalty.point}
                                    />

                                    <Select
                                        label="Khoá tài khoản"
                                        options={[
                                            { value: undefined, label: "Không khoá" },
                                            { value: "1_day", label: "1 ngày" },
                                            { value: "2_day", label: "2 ngày" },
                                            { value: "3_day", label: "3 ngày" },
                                            { value: "1_week", label: "1 tuần" },
                                            { value: "2_week", label: "2 tuần" },
                                            { value: "1_month", label: "1 tháng" },
                                            { value: "2_month", label: "2 tháng" },
                                            { value: "forever", label: "Đến khi mở lại" },
                                        ]}
                                        value={settlement.penalty.block_account}
                                        width="50%"
                                        onChange={(e) => {
                                            setSettlement({
                                                ...settlement,
                                                penalty: {
                                                    ...settlement.penalty,
                                                    block_account: e.target.value as SettlementInfo["penalty"]["block_account"],
                                                },
                                            });
                                        }}
                                    />
                                </div>
                            }

                            {
                                settlement.type !== 'makepeace' &&
                                <CheckBox
                                label="Bồi thường bên thắng"
                                checked={shouldRefund}
                                onChange={(e) => {
                                    setShouldRefund(e.target.checked);
                                    if (!e.target.checked) {
                                        setSettlement({
                                            ...settlement,
                                            award: {
                                                point: 0,
                                            }
                                        })
                                    }
                                }}
                            />}

                            {shouldRefund &&
                                <Select
                                    label="Số điểm bồi thường"
                                    options={Array.from(
                                        { length: 12 },
                                        (_, index) => index
                                            ? { value: index * 0.25, label: `${index * 0.25} điểm` }
                                            : { value: 0, label: "0" })}
                                    value={settlement.award.point}
                                    onChange={(e) => {
                                        setSettlement({
                                            ...settlement,
                                            award: {
                                                point: Number(e.target.value)
                                            },
                                        });
                                    }}
                                />
                            }

                            <InputMultiLine
                                label="Ghi chú"
                                placeholder="Nhập ghi chú"
                                value={settlement.note}
                                rows={3}
                                onChange={(e) => {
                                    setSettlement({
                                        ...settlement,
                                        note: e.target.value,
                                    });
                                }}
                            />

                            <button
                                type="submit"
                                className="w-full btn btn-success mt-12"
                                onClick={handleResolvingComplaint}
                            >
                                Giải quyết khiếu nại
                            </button>

                        </div>
                    )


                    : resolved 
                        ?( // Panel to view the result of the complaint
                        <div className="flex flex-col gap-4 mt-6">
                            <div className="text-base font-semibold text-green-700">
                                Kết quả giải quyết khiếu nại
                            </div>
                            <ul className="flex flex-col gap-2">
                                <li>
                                    {
                                        settlement.type === "makepeace" 
                                            ? "Xử hoà"
                                            : settlement.type === "creator"
                                                ? `Chủ lịch (${booking?.user_creator?.name}) thua`
                                                : `Tài xế bổ (${booking?.buyer?.name}) thua`
                                    }
                                </li>
                                {
                                    settlement.penalty.block_account &&
                                    <li>
                                        {`Khoá tài khoản bên thua ${
                                            settlement.penalty.block_account === '1_day'
                                            ? "1 ngày"
                                            : settlement.penalty.block_account === '2_day'
                                            ? "2 ngày"
                                            : settlement.penalty.block_account === '3_day'
                                            ? "3 ngày"
                                            : settlement.penalty.block_account === '1_week'
                                            ? "1 tuần"
                                            : settlement.penalty.block_account === '2_week'
                                            ? "2 tuần"
                                            : settlement.penalty.block_account === '1_month'
                                            ? "1 tháng"
                                            : settlement.penalty.block_account === '2_month'
                                            ? "2 tháng"
                                            : "Đến khi mở lại"
                                        }`}
                                    </li>
                                }
                                {
                                    settlement.penalty.point > 0 &&
                                    <li>
                                        {`Xử phạt bên thua ${settlement.penalty.point} điểm`}
                                    </li>
                                }
                                {
                                    settlement.award.point > 0 &&
                                    <li>
                                        {`Bồi thường bên thắng ${settlement.award.point} điểm`}
                                    </li>
                                }
                                {
                                    settlement.note &&
                                    <li>
                                        {`Ghi chú: ${settlement.note}`}
                                    </li>
                                }
                            </ul>
                        </div>
                    )
                    : null
            }


        </div>
    );
}