import React from "react";
import BookingCard from "../components/BookingCard";
import AppBar from "../../common/ui/Nav/AppBar";
import { BookingInfoV2 } from '../../common/models/Booking';
import { authContext } from "../../auth/logic/authHook";
import { useSnackbar } from "notistack";
import { shouldLoadMore } from "../../common/logic/utils";
import Select from "../../common/ui/Select";
import axios from "axios";
import BookingOptionsForm from "../forms/BookingOptionsForm";
import HistoryBookingDetail from "../components/HistoryBookingDetail";

function BookingHistoryPage() {
    const [bookings, setBookings] = React.useState<BookingInfoV2[]>([]);
    const [currPage, setCurrPage] = React.useState<number>(0);
    const [stopLoadMore, setStopLoadMore] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [filter, setFilter] = React.useState<"pass" | "buy">("pass");
    const [editing, setEditing] = React.useState<boolean>(false);
    const [state, setState] = React.useState<boolean>(false);
    const [selectedBooking, setSelectedBooking] =
        React.useState<BookingInfoV2 | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const auth = React.useContext(authContext);

    /*
     *======= Get all bookings of user
     *
     */
    React.useEffect(() => {
        setLoading(true);
        setStopLoadMore(false);
        axios(
            {
                method: "GET",
                url: `/v2/booking`,
                params: {
                    page_size: 40,
                    page: 0,
                    type: filter,
                }
            },
        )
            .then(
                (res) => {
                    setBookings(res.data[filter]);
                    if(res.data[filter].length < 40)
                        setStopLoadMore(true);
                    setCurrPage(1);
                }
            )
            .catch(
                (err) => {
                    console.log(err.response.data);
                    enqueueSnackbar("Lỗi tải dữ liệu lịch sử chuyến!", { variant: "error" });
                    setBookings([]);
                }
            );
        setLoading(false);
    }, [filter]);


    /*
     *======= Handle load more bookings
     *  @param _id: string          -   id of selected booking
     *
     */
    const handleLoadMore = () => {
        if (stopLoadMore) {
            return;
        }
        
        axios({
                method: "GET",
                url: `/v2/booking`,
                params: {
                    page_size: 40,
                    page: currPage + 1,
                    type: filter,
                }
        })
            .then((res) => {
                    setBookings([...bookings, ...res.data[filter]]);
                    setCurrPage(currPage + 1);
                    if(res.data[filter].length < 40)
                        setStopLoadMore(true);
            })
            .catch((err) => {
                    enqueueSnackbar("Lỗi tải dữ liệu lịch sử chuyến!", { variant: "error" });
            });
    }


    /*
     *======= Handle click on booking card
     *  @param _id: string          -   id of selected booking
     *
     */
    const handleBookingCardClick = (_id: string) => {
        let booking = bookings.find(booking => booking._id === _id);
        if (booking) {
            setSelectedBooking(booking);
            console.log(selectedBooking);
            setState(true);
        }
    }

    /*
     *======= Handle on close side popup
     *
     */
    const handleOnClose = () => {
        setSelectedBooking(null);
        setState(false);
    }

    /*
     *======= Disable editing on selected booking change
     *
     */
    React.useEffect(() => {
        setEditing(false);
    }, [selectedBooking]);

    if (loading)
        return <div className="text-xl text-center">Đang tải dữ liệu chuyến ...</div>;

    return (
        <div className="w-full h-full grid grid-cols-12 overflow-y-hidden">

            <div className={`${state ? "col-span-8" : "col-span-12"} h-full flex flex-col-reverse overflow-y-hidden`}>

                {/* List of available bookings */}
                <div 
                    className={`w-full h-full flex flex-col overflow-y-scroll px-4 py-2 bg-gray-100 gap-2`}
                    onScroll={(event) => {
                        const ele = event.target as HTMLDivElement;
                        if ( shouldLoadMore( 
                                ele.scrollHeight, 
                                ele.scrollTop, 
                                ele.clientHeight, 
                                false, 
                                1 
                        )) {
                            handleLoadMore();
                        }
                    }}
                >
                    {bookings && bookings.map((booking, index) => {
                        return (
                            <div
                                key={booking._id}
                                className={booking.user_creator.phone_number === auth?.authedUser?.phone_number
                                    ? "w-full flex flex-row justify-end"
                                    : "w-full flex flex-row justify-start"
                                }
                            >
                                <BookingCard
                                    booking={booking}
                                    show_status={true}
                                    width={state ? "80%" : "66%"}
                                    onClick={() => handleBookingCardClick(booking._id)}
                                    refresh={false}
                                    selected={selectedBooking !== undefined && selectedBooking?._id === booking._id}
                                />
                            </div>
                        );
                    })}
                </div>

                {/* Top Bar */}
                <AppBar
                    children={
                        <div className="flex flex-row justify-start items-center gap-2">
                            <Select
                                options={[
                                    { value: "pass", label: "Chuyến tôi đăng" },
                                    { value: "buy", label: "Chuyến tôi bổ" },
                                ]}
                                value={filter}
                                onChange={(e) => setFilter(e.target.value as "pass" | "buy")}
                                width="200px"
                            />
                        </div>
                    }
                />
            </div>

            {/* Booking options form Sidepopup */}
            {state && 
                <div className={`${state ? "flex" : "hidden"} col-span-4 h-full flex flex-col-reverse border-l border-gray-200 overflow-y-scroll`}>

                    {editing 
                        ? <BookingOptionsForm
                            bookingInfo={selectedBooking as BookingInfoV2}
                            onSubmit={handleOnClose}
                            onClose={handleOnClose}
                        />

                        : <HistoryBookingDetail
                            booking={selectedBooking as BookingInfoV2}
                            onClose={handleOnClose}
                            onEdit={() => setEditing(true)}
                        />
                    }
                </div>
            }

        </div>
    );
}

export default BookingHistoryPage;