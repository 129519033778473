import React from "react";
import { ApprovalInfo, ApprovalStatus, ApprovalDecision, TransactionType, UserPublicInfo } from "../../common/models/User";
import { pointToCash } from "../logic/api";
import { formatCurrency } from "../../common/logic/utils";
import { fDateTime } from "../../common/logic/time_utils";
import {downloadFile, getUserByPhone} from "../../common/logic/api";
import IconButton from "../../common/ui/IconButton";
import { useSnackbar } from "notistack";
import { appContext } from "../../common/logic/appContext";
import InfoWithLabel from "../../common/ui/InfoWithLabel";
import DriverInfoCard from "../../booking/components/DriverInfoCard";
import BankInfoCard from "../../common/ui/BankInfoCard";
import TrainsactionApprovalForm from "../forms/TransactionApprovalForm";



export default function TransactionDetails({
    approval,
    onClose = () => { },
}: {
    approval?: ApprovalInfo,
    onClose?: (approcvalId?: string) => void,
}) {
    const [cash, setCash] = React.useState(0);
    const [approvedAdmin, setApprovedAdmin] = React.useState<UserPublicInfo | undefined>(undefined);
    const [driver, setDriver] = React.useState<UserPublicInfo | undefined>(undefined);
    const [approvalAction, setApprovalAction] = React.useState<ApprovalStatus.ACCEPTED | ApprovalStatus.REJECTED>(ApprovalStatus.ACCEPTED);
    const modalCheckboxRef = React.useRef<HTMLInputElement>(null);
    const [photoURI, setPhotoURI] = React.useState<string | undefined>(undefined);
    const appCtx = React.useContext(appContext);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {

        // convert transaction point to cash
        if (approval?.point! > 0 )
        {
            pointToCash(approval?.point!)
            .then((res) => {
                setCash(res.data.cash);
            })
            .catch((err) => {
                enqueueSnackbar( `Lỗi chuyển đổi điểm thành tiền: ${err.response.data}`, { variant: "error" });
            });
        } 

        // download photo
        downloadFile(approval?.photo!)
        .then((uri) => {
            setPhotoURI(uri);
        })
        .catch((err) => {
            enqueueSnackbar( `Lỗi tải ảnh: ${err.response.data}`, { variant: "error" });
        });

        // get approved admin info
        if( approval?.status !== ApprovalStatus.PENDING && approval?.admin_review?.admin )
        {
            getUserByPhone( approval?.admin_review?.admin )
            .then((user) => {
                setApprovedAdmin(user);
            })
            .catch((err) => {
                enqueueSnackbar( `Lỗi tải thông tin admin: ${err.response.data}`, { variant: "error" });
            });
        }

        // get driver info
        if( approval  && approval.creator ) {
            let cachedUser = appCtx?.users.find((user) => user.phone_number === approval?.creator);
            if (cachedUser) {
                setDriver(cachedUser);
            } else {
                getUserByPhone(approval?.creator)
                    .then((user) => {
                        if (user) {
                            setDriver(user);
                        }
                    })
                    .catch((err) => {
                        enqueueSnackbar( `Lỗi tải thông tin tài xế: ${err.response.data}`, { variant: "error" });
                    });
            }
        }

    }, [approval]);

    if (approval === undefined ){
        return null;
    }

    return (
        <div className="w-full h-full flex flex-col gap-4 px-4 py-3 overflow-y-scroll">

            {/* Modal for showing approval form */}
            {
                approval &&
                (
                    <div>
                        <input
                            type="checkbox"
                            id="approval-form"
                            className="modal-toggle"
                            ref={modalCheckboxRef}
                        />
                        <label htmlFor="approval-form" className="modal cursor-pointer">
                            <label className="modal-box relative" htmlFor="">
                                <TrainsactionApprovalForm
                                    approvalId={approval?._id!}
                                    type={approvalAction}
                                    onApprovedSuccessfully={(decision: ApprovalDecision) => {
                                        modalCheckboxRef.current?.click();
                                        onClose(approval?._id);
                                    }}
                                    onApprovaedFailed={() => {
                                        modalCheckboxRef.current?.click();
                                    }}
                                />
                            </label>
                        </label>
                    </div>
                )
            }

            {/* Form Title */}
            <div className="py-3 flex flex-row justify-between items-start">
                <div className="flex flex-col gap-2 items-start">
                    <div className="text-xl font-semibold text-gray-700">
                        {
                            approval.transaction_type === TransactionType.DEPOSIT
                                ? "Giao dịch nạp tiền"
                                : approval.transaction_type === TransactionType.WITHDRAW
                                    ? "Giao dịch rút tiền"
                                    : "Giao dịch khác"
                        }
                    </div>
                    <div className="text-sm text-left font-medium text-gray-500">
                        {`Mã giao dịch: ${approval?._id}`}
                    </div>
                </div>

                <IconButton
                    icon="ion:close-outline"
                    variant="mute"
                    onClick={() => {
                        onClose();
                    }}
                />
            </div>

            {/* Info about approval */
                approval && approval.status === ApprovalStatus.REJECTED
                    ? (<div className="alert alert-error shadow-lg">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                            <span>{`Giao dịch đã bị từ chối bởi 
                                    admin ${approvedAdmin?.name} 
                                    lúc ${fDateTime(approval.admin_review?.created_time!, "HH:mm - dd/MM/yyyyy")} 
                                    ${approval.admin_review?.note && `với lý do: ${approval.admin_review?.note}`}`}</span>
                        </div>
                    </div>)

                    : approval && approval.status === ApprovalStatus.PENDING
                        ? (<div className="alert alert-warning shadow-lg">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                                <span>{`Giao dịch ${approval.created_time === approval.updateAt ? 'mới' : 'chỉnh sửa'} đang chờ phê duyệt`}</span>
                            </div>
                        </div>)

                        : approval && approval.status === ApprovalStatus.ACCEPTED
                            ? (<div className="alert alert-success shadow-lg">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                                    <span>{`Giao dịch đã được thông qua bởi 
                                            admin ${approvedAdmin?.name} 
                                            lúc ${fDateTime(approval.admin_review?.created_time!, "HH:mm - dd/MM/yyyyy")} 
                                            ${approval.admin_review?.note && `với ghi chú: ${approval.admin_review?.note}`}`}</span>
                                </div>
                            </div>)

                            : (<div className="alert shadow-lg">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info flex-shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    <span>Giao dịch không xác định</span>
                                </div>
                            </div>)
            }


            {/* Info about transaction */}
            <div className="w-full flex flex-col gap-2 px-4 py-3 bg-white rounded-lg shadow-md">
                <InfoWithLabel
                    label={approval.transaction_type === TransactionType.DEPOSIT ? "Số điểm nạp" : "Số điểm rút"}
                    info={`${approval.point} điểm`}
                    alignInfo="right"
                    emphasis={true}
                    direction="row"
                />

                <InfoWithLabel
                    label="Số tiền tương ứng"
                    info={formatCurrency(cash)}
                    alignInfo="right"
                    emphasis={true}
                    highlight={true}
                    direction="row"
                />
            </div>

            {/* Bank account info of beneficiary */}


            {/* Transfer note */}
            <div className="w-full flex flex-col gap-2 px-4 py-3 bg-white rounded-lg border border-gray-200">
                <InfoWithLabel
                    label="Thời gian tạo"
                    info={approval.created_time ? fDateTime(approval.created_time, "dd/MM/yyyy HH:mm") : "N/A"}
                    alignInfo="right"
                    direction="row"
                />

                <InfoWithLabel
                    label="Nội dung chuyển khoản"
                    info={approval.note ? approval.note : "N/A"}
                    direction="column"
                />
            </div>

            {/* Transaction image */}
            {photoURI &&
                <div className="w-full flex flex-col gap-2 mt-2">
                    <div className="text-sm text-gray-500">
                        Ảnh chuyển khoản
                    </div>
                    <img
                        className="w-60 h-auto object-cover rounded-lg"
                        src={photoURI}
                        alt="Ảnh chuyển khoản"
                    />
                </div>
            }

            {/* Driver who submits the transaction request */}
            <div className="w-full flex flex-col gap-2 px-4 py-3 bg-white rounded-lg border border-gray-200">
                
                <div className="text-base font-semibold text-gray-500">
                    Tài xế thực hiện giao dịch
                </div>

                {
                    driver &&
                    <DriverInfoCard
                        user={driver}
                    />
                }

                <InfoWithLabel
                    label="Ngày vào nhóm"
                    info={driver?.join_date ? fDateTime(driver.join_date, "dd/MM/yyyy") : "N/A"}
                    alignInfo="right"
                    direction="row"
                />

                <InfoWithLabel
                    label="Trạng thái"
                    info={driver?.locked ? "Đã khóa" : "Đang hoạt động"}
                    direction="row"
                    alignInfo="right"
                />

                {
                    driver?.bank &&
                    
                    <BankInfoCard
                        bankInfo={driver.bank}
                        border={false}
                    />
                }
                
            </div>


            {/* Approval Button */}
            {
                approval?.status === ApprovalStatus.PENDING &&
                <div className="flex flex-row gap-4 mt-12 mb-4">
                    <button
                        className="w-1/2 btn btn-error"
                        onClick={() => {
                            setApprovalAction(ApprovalStatus.REJECTED);
                            modalCheckboxRef.current?.click();
                        }}
                    >
                        Từ chối
                    </button>

                    <button
                        className="w-1/2 btn btn-success"
                        onClick={() => {
                            setApprovalAction(ApprovalStatus.ACCEPTED);
                            modalCheckboxRef.current?.click();
                        }}
                    >
                        Thông qua
                    </button>
                </div>
            }

        </div>
    )
}