import React from "react";
import NavList from "../../common/ui/Nav/NavList";
import menu_json from "../../common/config/menu.json";
import KPICards from "./KPICards";

var admin_sub_menu = menu_json[3].sub_menus;

export default function SideBarAdmin() {
    return (
        <div className="w-full h-full flex flex-col bg-gray-50 border-r border-gray-200">
            { admin_sub_menu &&
                <NavList 
                    menu={admin_sub_menu} 
                    basePath="/admin"
                />
            }
            <KPICards />
        </div>
    );
}