import React from "react";
import { downloadFile } from "../../common/logic/api";
import IconButton from "../../common/ui/IconButton";
import { ApprovalInfo } from "../../common/models/User";
import Input from "../../common/ui/Input";
import Select from "../../common/ui/Select";
import PhotoSelect from "../../common/ui/PhotoSelect";
import { fDate } from "../../common/logic/time_utils";

export default function IdentityForm({
    profile = undefined,
    onClose = () => { },
    onUpdate = () => { }
}: {
    profile?: ApprovalInfo | null,
    onClose?: () => void,
    onUpdate?: (updateField: any) => void,
}) {
    const [idPhoto0Src, setIdPhoto0Src] = React.useState<string>("");
    const [idPhoto1Src, setIdPhoto1Src] = React.useState<string>("");
    const [updateField, setUpdateField] = React.useState<any>({sex : "male"}); // updateField is a field of ApprovalInfo

    React.useEffect(() => {

        // download images
        if( profile?.id_photos![0] ) {
            downloadFile(profile.id_photos[0])
            .then((res) => {
                setIdPhoto0Src(res);
            });
        }

        if( profile?.id_photos![1] ) {
            downloadFile(profile.id_photos[1]).then((res) => {
                setIdPhoto1Src(res);
            });
        }

    }, [profile]);

    return (
        <div className="w-full h-full flex flex-col justify-between px-4 py-3 overflow-y-scroll">

            <div className="flex flex-col gap-4">
                {/* Form Title */}
                <div className="py-3 flex flex-row justify-between items-start">
                    <div className="text-xl font-semibold text-gray-700">
                        Cập nhật định danh cá nhân
                    </div>

                    <IconButton
                        icon="ion:close-outline"
                        variant="mute"
                        onClick={() => {
                            onClose();
                        }}
                    />
                </div>

                {/* Identity name */}
                <Input
                    label="Họ tên trên CMT/CCCD"
                    value={profile?.id_name}
                    onChange={(e) => {
                        setUpdateField({
                            ...updateField,
                            id_name: e.target.value
                        });
                    }}
                />

                {/* Date of birth */}
                <Input
                    label="Ngày sinh"
                    value={profile?.date_of_birth ? fDate(profile.date_of_birth, "dd/ MM/ yyyy") : ""}
                    type="date"
                    onChange={(e) => {
                        setUpdateField({
                            ...updateField,
                            date_of_birth: e.target.value
                        });
                    }}
                />

                {/* Gender */}
                <Select
                    label="Giới tính"
                    options={[
                        { label: "Nam", value: "male" },
                        { label: "Nữ", value: "female" },
                        { label: "Khác", value: "other" },
                    ]}
                    value={profile?.sex}
                    onChange={(e) => {
                        setUpdateField({
                            ...updateField,
                            sex: e.target.value
                        });
                    }}
                />

                {/* CMT/CCCD number */}
                <Input
                    label="Số CMT/CCCD"
                    value={profile?.id_number}
                    onChange={(e) => {
                        setUpdateField({
                            ...updateField,
                            id_number: e.target.value
                        });
                    }}
                />

                {/* CMT/CCCD image */}
                <PhotoSelect
                    label="Ảnh mặt trước CMT/CCCD"
                    value={idPhoto0Src ? idPhoto0Src : ""}
                    width="100%"
                    height="250px"
                    onChange={(e) => {
                        setUpdateField({
                            ...updateField,
                            idPhoto0: e.target.files![0]
                        });
                    }}
                />

                {/* CMT/CCCD image */}
                <PhotoSelect
                    label="Ảnh mặt sau CMT/CCCD"
                    value={idPhoto1Src ? idPhoto1Src : ""}
                    width="100%"
                    height="250px"
                    onChange={(e) => {
                        setUpdateField({
                            ...updateField,
                            idPhoto1: e.target.files![0]
                        });
                    }}
                />
            </div>

            {/* Submit button */}
            <button 
                className="btn btn-success btn-block mb-4 my-12"
                onClick={() => {
                    onUpdate(updateField);
                }}
            >
                Cập nhật
            </button>
        </div>
    )
}