import React from "react";
import Avatar from "../../common/ui/Avatar";
import { ConversationInfo } from "../../common/models/Chat";
import { UserPublicInfo } from "../../common/models/User";
import { fDateTime } from "../../common/logic/time_utils";
import { 
    appContext,
    getUserFromAppContext,
    saveUserToAppContext,
} from "../../common/logic/appContext";
import axios from "axios";

export default function ComplaintContentCard( {conversation} : {conversation: ConversationInfo} ) {

    const appCtx = React.useContext(appContext);
    const [complainer, setComplainer] = React.useState<UserPublicInfo | undefined>(undefined);

    // load user who raise the complaint info
    React.useEffect(() => {
        if (appCtx && conversation.complaining?.complainer) {
            const complainer = getUserFromAppContext(
                conversation.complaining?.complainer as string, 
                appCtx.users); // user here is phone number
            if (!complainer) {
                axios({
                    method: 'GET',
                    url: `/user/${conversation.complaining?.complainer}`,
                })
                    .then((res) => {
                        setComplainer(res.data as UserPublicInfo);
                        saveUserToAppContext([res.data as UserPublicInfo], appCtx.users, appCtx.setUsers);
                    })
            }
            else {
                setComplainer(complainer);
            }
        }
    }, []);

    // display nothing if the conversation is not a complaint
    if( conversation.complaining === undefined ) {
        return null;
    }

    return (
        <div 
            className={`flex flex-col gap-2 items-start rounded-lg 
                        border border-gray-200 px-4 py-3 shadow-md`}
        >
            <div className="text-base font-semibold text-yellow-700">
                Nội dung khiếu nại
            </div>

            <Avatar
                user={complainer}
                title={complainer?.name}
            />

            <div className="text-base text-gray-500">
                {conversation.note}
            </div>

            <div className="text-sm text-gray-400">
                {fDateTime(conversation.created_time, "dd/MM/yyyy HH:mm")}
            </div>
        </div>
    );
}