import React from "react";

export default function SubBookingForm({
    content = "",
    onCanceled = ( ) => {},
    onSubmitted = (  content: string ) => {},
    onChange = () => {},
}: {
    content?: string,
    onCanceled?: ( ) => void,
    onSubmitted?: ( content: string) => void,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
}) {
    const [_content, setContent] = React.useState<string>(content);

    return (
        <form className="w-full flex flex-row justify-between items-center">
            <input 
                type={"text"}
                placeholder={"Nhập nội dung chuyến đi"}
                value={_content}
                onChange={
                    (event) => {
                        setContent(event.target.value)
                        onChange( event )
                    }
                }
                className="w-full border-0 text-sm mr-4 px-0 focus:outline-none"
            />

            <div className="flex flex-row gap-2 items-center">
                <button 
                    className="w-20 h-10 text-red-600 text-sm text-right font-medium bg-transparent border-0 flex items-center justify-end"
                    onClick={(event) => {
                        event.preventDefault();
                        onCanceled(  );
                    }}
                >
                    Xoá
                </button>

                <div className="w-0.5 h-5 bg-gray-300"></div>

                <button
                    className="w-20 h-10 text-green-600 text-sm font-medium bg-transparent border-0 flex items-center justify-start"
                    onClick={(event) => {
                        event.preventDefault();
                        onSubmitted( _content );
                    }}
                >
                    Đăng lịch
                </button>
            </div>
        </form>
    )
}