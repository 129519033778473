import React from "react";
import AppBar from "../../common/ui/Nav/AppBar";
import Select from "../../common/ui/Select";
import SearchBox from "../../common/ui/SearchBox";
import { 
    UserInfo, 
    ApprovalStatus, 
    TransactionType,
    ApprovalInfo,
    TransactionInfo,
} from "../../common/models/User";
import { getApprovalById, getApprovalTransactions } from "../logic/api";
import { shouldLoadMore } from "../../common/logic/utils";
import { useSnackbar } from "notistack";
import TransactionRow from "../components/TransactionRow";
import TransactionDetails from "../components/TrainsactionDetails";

interface TransactionFilter {
    status: ApprovalStatus | "all";
    type: "deposit" | "withdraw" | "subscribe" | "all";
}


export default function TransactionsManaPage() {
    const [approvalTrans, setApprovalTrans] = React.useState<ApprovalInfo[]>([]);
    const [selectedTransaction, setSelectedTransaction] = React.useState<ApprovalInfo | undefined>(undefined);
    const [currPage, setCurrPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [filter, setFilter] = React.useState<TransactionFilter>({
        status: "all",
        type: "all",
    });
    const [selectedUser, setSelectedUser] = React.useState<UserInfo | undefined>(undefined);
    const [showSidePopup, setShowSidePopup] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    // Load list of transactions
    React.useEffect(() => {
        getApprovalTransactions(filter.type, filter.status, 0)
        .then((res) => {
            setApprovalTrans(res.data);
            setCurrPage(0);
        })
        .catch((err) => {
            enqueueSnackbar( `Lỗi tải danh sách giao dịch: ${err as string}`, { variant: "error" });
        });
    }, [filter]);

    /*
     * ===================== Handle View Transaction
     */
    const handleViewTransaction = (transaction: ApprovalInfo) => {
        setSelectedTransaction(transaction);
        setShowSidePopup(true);
    };

    const handleLoadMoreTransactions = () => {
        getApprovalTransactions(
            filter.type,
            filter.status,
            currPage + 1,
        )
            .then((res) => {
                setApprovalTrans([...approvalTrans, ...res.data]);
                setCurrPage(currPage + 1);
            })
            .catch((err) => {
                enqueueSnackbar(`Lỗi tải danh sách giao dịch: ${err as string}`, { variant: "error" });
            });
    };


    return (
        <div className="w-full h-full grid grid-cols-12 overflow-y-hiden">
            <div className={`${showSidePopup ? "col-span-8" : "col-span-12"} h-full flex flex-col overflow-y-hidden`}>
                <AppBar>
                    <div className="flex flex-row justify-start items-center gap-2">
                        <Select
                            options={[
                                { value: "all", label: "Loại giao dịch" },
                                { value: "deposit", label: "Nạp điểm" },
                                { value: "withdraw", label: "Rút điểm" },
                                { value: "subscribe", label: "Gia hạn tài khoản" },
                            ]}
                            value={filter.type}
                            onChange={(e) => setFilter({
                                ...filter,
                                type: e.target.value as TransactionFilter["type"],
                            })}
                            width="200px"
                        />

                        <Select
                            options={[
                                { value: "all", label: "Trạng thái giao dịch" },
                                { value: "pending", label: "Chờ duyệt" },
                                { value: "accepted", label: "Đã duyệt" },
                                { value: "rejected", label: "Từ chối" },
                            ]}
                            value={filter.status}
                            onChange={(e) => setFilter({
                                ...filter,
                                status: e.target.value as TransactionFilter["status"],
                            })}
                            width="200px"
                        />

                        <SearchBox
                            placeholder="Nhập tên hoặc sđt"
                            width="260px"
                            value={search}
                            onChange={(event) => {
                                if (Math.abs(event.target.value.length - search.length) > 2) {
                                    setSearch(event.target.value);
                                }
                            }}
                        />
                    </div>
                </AppBar>

                {/* Table of Transactions */}
                <div 
                    className="w-full h-full flex flex-col px-4 py-6 overflow-y-scroll"
                    onScroll={(event) => {
                        const ele = event.target as HTMLDivElement;
                        if (shouldLoadMore(
                            ele.scrollHeight,
                            ele.scrollTop,
                            ele.clientHeight,
                            false,
                            1)
                        ) {
                            handleLoadMoreTransactions();
                        }
                    }}
                >
                    <table className="table w-full">
                        <thead className="sticky top-0">
                            <tr>
                                <th>Giao dịch</th>
                                <th>Tài xế yêu cầu</th>
                                <th className="text-right">Số điểm</th>
                                <th></th>
                                <th>Trạng thái</th>
                                <th>Người phê duyệt</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody
                            className="overflow-y-scroll"
                        >
                            {
                                approvalTrans.map((transaction) => (
                                    <TransactionRow
                                        key={Math.random()}
                                        transaction={transaction}
                                        selected={selectedTransaction?._id === transaction._id}
                                        onViewTransaction={handleViewTransaction}
                                    />
                                ))
                            }
                        </tbody>
                    </table>
                </div>

            </div>

            {/* Detail of transactions */}
            <div className={`${showSidePopup ? "flex" : "hidden"} col-span-4 h-full flex flex-col-reverse border-l border-gray-200 overflow-y-scroll`}>
               <TransactionDetails
                    approval={selectedTransaction}
                    onClose={(approcvalId) => {
                        setShowSidePopup(false);
                        // setSelectedTransaction(undefined);

                        // Update transaction status
                        if(approcvalId) {
                            getApprovalById(approcvalId)
                            .then((res) => {
                                const newApproval = res.data as ApprovalInfo;
                                const newApprovalTrans = approvalTrans.map((approval) => {
                                    if(approval._id === newApproval._id) {
                                        return newApproval;
                                    }
                                    return approval;
                                });
                                setApprovalTrans(newApprovalTrans);
                            })
                        }
                    }}
                />
            </div>
        </div>
    );
}