import React from "react";
import Image from "../../common/ui/Image";
import {CarInfo, CarStatus } from "../../common/models/Car";
import { Icon } from "@iconify/react";
import Chip from "../../common/ui/Chip";

export default function CarCard ({
    car,
    showAction = false,
    onEdit = () => {},
    onDelete = () => {},
} : {
    car: CarInfo,
    showAction?: boolean,
    onEdit?: () => void,
    onDelete?: () => void,
}) {
    return (
        <div className="relative w-full flex flex-row gap-4 items-center border border-gray-200 bg-white rounded-lg">
            {
                car.photoSrcs ?
                    <img
                        src={car.photoSrcs[0]} alt="car"
                        className="w-24 h-24 rounded-lg"
                    />
                    :
                    <Image
                        id={car.photos[0]} alt="car"
                        className="w-24 h-24 rounded-lg"
                    />
            }

            <div className="flex flex-col gap-2 items-start">
                <div className="text-base font-bold capitalize">
                    {`${car.brand_name} ${car.model}`}
                </div>

                <div className="text-sm text-gray-500 font-medium">
                    {`Đời ${car.manufacture_year} - ${car.seats} chỗ`}
                </div>

                <div className="text-sm text-gray-500 font-medium">
                    {car.vehicle_number}
                </div>
            </div>

            <div className="absolute right-4 top-3">
                {
                    car.status === CarStatus.PENDING 
                    ? <Chip label="Chờ duyệt" color="ghost" />
                    : car.status === CarStatus.IDLE
                    ? <Chip label="Sẵn sàng" color="success" />
                    : car.status === CarStatus.RUNNING
                    ? <Chip label="Đang chạy" color="warning" />
                    : <Chip label="Chờ gửi" color="info" />
                }
            </div>

            {
                showAction &&
                <div className="absolute right-4 bottom-3 flex flex-row gap-2">
                        <Icon
                            icon="material-symbols:edit"
                            className="text-gray-400 cursor-pointer"
                            fontSize={20}
                            onClick={onEdit}
                        />

                        <Icon
                            icon="fluent-mdl2:delete"
                            className="text-gray-400 cursor-pointer text-red-700"
                            fontSize={20}
                            onClick={onDelete}
                        />
                </div>
            }
        </div>
    );
}