import axios from "axios";
import { BookingInfoV2, BookingPublicInfoV2, BookingStatus, BookingInfoV2Req } from "../../common/models/Booking";
import { isExpired } from "../../common/logic/time_utils";
import { useSnackbar } from "notistack";


/*
        * Handle click on booking buying button
        * Buy booking
    */
export const handleBuyBooking = ( vehicleID: string | null, _booking: BookingInfoV2 | BookingPublicInfoV2 ) => {
    // Check if booking is expired
    if ( _booking.status === BookingStatus.PENDING && isExpired(_booking.update_at) ) {
        return "expired";
    }

    if( vehicleID === null) 
        return "Chưa chọn xe";
    let ret = "success";
    axios(
        {
            url :`/v2/booking/${_booking.short_id}/buy`,
            method: 'POST',
            params: { vehicle: vehicleID }
        })
        .then(res => {
            console.log(res.data)
        })
        .catch(err => {
            ret = err;
        });
    return ret;
}


export async function buyBooking( vehicleID: string | null, _booking: BookingInfoV2 | BookingPublicInfoV2 )  {
    
    if( vehicleID === null) 
        return Promise.reject("Chưa chọn xe");

    console.log("buying booking: ", _booking._id, " with vehicle: ", vehicleID)

    return axios(
        {
            url :`/v2/booking/${_booking._id}/buy`,
            method: 'POST',
            data: { "vehicle": vehicleID }
        });
}

/*
    * Handle click on booking cancel button
    * Cancel booking
*/
export const handleCancelBooking = (_booking: BookingInfoV2 | BookingPublicInfoV2) => {
    axios(
        {
            url :`/v2/booking/${_booking._id}/cancel`,
            method: 'POST',
            data: {
                reason: "Khách hàng hủy chuyến xe"
            }
        })
        .then(res => {
            return "success"
        })
        .catch(err => {
            return err
        });
    return "success";
}

/*
    * Handle click on booking re-post button
    * Repost booking
*/
export const handleRepostBooking = (_booking: BookingInfoV2 | BookingPublicInfoV2) => {
    axios(
        {
            url :`/v2/booking/${_booking._id}`,
            method: 'PATCH',
            data: {
                ..._booking as BookingInfoV2Req,
                update_at: new Date().toISOString(),
            }
        })
        .then(res => {
            console.log(res.data)
            return "success"
        })
        .catch(err => {
            return err
        });
    return "success";
}


/*
    * Handle posting new booking, could be single or multiple
    * Post new booking
    * @param {BookingInfoV2Req[]} bookings
    * @returns {number[]} failed bookings index  ([]: no error)
*/
export const handlePostBooking = (bookings: BookingInfoV2Req[]) => {
    let failedBookingIndice: number[] = [];
    Promise.allSettled(
        bookings.map((booking) => {
            console.log( "booking: ", booking)
            axios(
                {
                    url :`/v2/booking/`,
                    method: 'POST',
                    data: booking
                }
            )
        })
    ).then(res => {
        res.forEach((item, index) => {
            if (item.status !== "rejected") {
                failedBookingIndice.push(index);
            }
        })
    });
    return failedBookingIndice;
}


export const getBookingByID = async (booking_id: string) : Promise<BookingInfoV2> => {
    const res = axios({
        method: 'GET',
        url: `/v2/booking/${booking_id}`,
    })

    return (await res).data;
}


/*
    * Get all available cars for buying a booking
    * @returns {CarInfo[]} failed bookings index  ([]: no error)
*/
export const getAvailableCars = async () => {
    return axios({
        method: 'GET',
        url: `/v2/user/vehicle/available`,
    });
}

export const finishBooking = async (booking_id: string) => {
    // return axios({
    //     method: 'POST',
    //     url: `/booking/${booking_id}`,
    //     data: {
    //         action: "finish"
    //     }
    // });

    return axios({
        method: 'POST',
        url: `/v2/booking/${booking_id}/finish`,
    });
}


export const raiseComplaint = async (booking_id: string, reason: string) => {
    return axios({
        method: 'POST',
        url: `/conversation`,
        data: {
            type: "complaining",
            booking_id: booking_id,
            note: reason
        }
    });
}



export async function getUserBookings(
    from_page: number,
    page_size: number,
    type: "pass" | "buy" | "all",
    phone_number: string,
) {
    console.log( "---------> get bookings of phone: ", phone_number)
    return axios({
        method: 'GET',
        url: `/booking`,
        params: {
            page: from_page,
            page_size: page_size,
            type: type,
            phone_number: phone_number
        }
    });
}