import axios from "axios";
import {
    ConversationInfo,
    MessageReq,
} from "../../common/models/Chat";
import { SettlementInfo } from "../../common/models/Complaint";
import { uploadFile } from "../../common/logic/api";


export async function sendChatMessage(
    conversationId: string,
    message: MessageReq,
    bookingId?: string
) {
    return axios(
        {
            method: "POST",
            url: `conversation/${conversationId}/messages`,
            data: {
                type: message.type,
                text: message.text,
                file_id: message.file_id,
                booking_id: bookingId,
            }
        }
    )
}


export async function sendChatMessageV2(
    conversationId: string,
    msgText?: string,
    file?: File | null,
) {
    if( !msgText && !file ) 
        return Promise.reject("No message or file to send");

    const uploadedImg = file ? await uploadFile(conversationId, file) : undefined;

    console.log("uploadedImg", uploadedImg);
    return axios(
        {
            method: "POST",
            url: `conversation/${conversationId}/messages`,
            data: {
                type: "text",
                text: msgText,
                file_id: uploadedImg?.file_id,
                booking_id: "",
            }
        }
    )
}


export async function searchConversation(query: string) {
    return Promise.allSettled(
        [
            axios({
                method: "GET",
                url: '/conversation',
                params: {
                    page_size: 20,
                    page: 0,
                    user: query,
                }
            }),

            axios({
                method: "GET",
                url: '/user/list',
                params: {
                    status: 'activated',
                    user: query,
                    deposit_from: 0,
                    deposit_to: 100000000,
                    page_size: 20,
                    page: 0,
                }
            })
        ]
    )
}


export async function resolveComplaint( 
    settlement: SettlementInfo,
    conversationId: string
) {
    return axios({
        method: "POST",
        url: `/conversation/${conversationId}`,
        data: settlement,
    })
}

export async function getConversationIDByPhone( phoneNumber: string ) {
    return axios({
        method: "GET",
        url: "conversation",
        params: {
            user: phoneNumber,
            page_size: 20,
            page: 0,
        }
    })
}

export async function pinBooking( bookingId: string, toPhone: string) {

    // Check if conversation exists
    const conversations = await getConversationIDByPhone(toPhone);

    const conversation = (conversations.data as ConversationInfo[]).find(
        conversation => conversation.complaining === undefined
    );

    if( conversation ) {
        // send message to conversation
        return Promise.allSettled( [
            conversation as ConversationInfo,

            axios(
                {
                    method: "POST",
                    url: `conversation/${conversation._id}/messages`,
                    data: {
                        type: "booking",
                        text: "Hỏi lịch trình",
                        file_id: undefined,
                        booking_id: bookingId,
                    }
                }
            )
        ]);
    }
    else {
        // create conversation
        const newConversation = await axios({
            method: "POST",
            url: "conversation",
            data: {
                booking_id: bookingId,
                note: "Hỏi lịch trình",
                type: "normal",
            }
        })
        
        return Promise.allSettled( [
            newConversation.data as ConversationInfo,

            axios(
                {
                    method: "POST",
                    url: `conversation/${newConversation.data._id}/messages`,
                    data: {
                        type: "booking",
                        text: "Hỏi lịch trình",
                        file_id: undefined,
                        booking_id: bookingId,
                    }
                }
            )
        ]);
    }
}



export async function joinConversationWithUser( phone_number: string ) {
    // Check if conversation exists
    const conversations = await getConversationIDByPhone(phone_number);

    const conversation = (conversations.data as ConversationInfo[]).find(
        conversation => conversation.complaining === undefined
    );

    if( conversation ) {
        return conversation;
    }
    else {
        // create new conversation with user
        const newConversation = await axios({
            method: "POST",
            url: "/v2/conversation/normal",
            data: {
                user: phone_number,
            }
        });

        return newConversation.data as ConversationInfo;
    }
}