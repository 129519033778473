import React from "react";
import Select from "../../common/ui/Select";
import SearchBox from "../../common/ui/SearchBox";
import NotificationRow from "../components/NotificationRow";
import ProfileDetail from "../components/ProfileDetails";
import { UserPublicInfo, Notification } from "../../common/models/User";
import { getNotifications } from "../logic/api";
import { useSnackbar } from "notistack";
import AppBar from "../../common/ui/Nav/AppBar";
import NotificationDetails from "../components/NotificationDetails";
import NewNotificationForm from "../forms/NewNotificationForm";
import NotificationCard from "../components/NotificationCard";
import { Icon } from "@iconify/react";


export default function NotifcationsManaPage() {

    const [notis, setNotis] = React.useState<Notification[]>([]);
    const [selectedNoti, setSelectedNoti] = React.useState<Notification | undefined>(undefined);
    const [showSidePopup, setShowSidePopup] = React.useState(false);
    const {enqueueSnackbar} = useSnackbar();

    // Load list of notifications
    React.useEffect(() => {
        getNotifications(0, "global")
        .then((res) => {
            setNotis(res.data);
        })
        .catch((err) => {
            enqueueSnackbar( `Lỗi tải thông báo nhóm: ${err as string}`, { variant: "error" });
        });
    }, []);

    const handleViewNotification = (noti: Notification) => {
        setSelectedNoti(noti);
        setShowSidePopup(true);
    }

    const handleUpdateNewNoti = (noti?: Notification) => {
        setShowSidePopup(false);
        if(!noti) return;
        const newNotis = notis.map((n) => {
            if(n._id === noti._id) {
                return noti;
            }
            return n;
        });
        setNotis(newNotis);
    }

    return (
        <div className="w-full h-full grid grid-cols-12 overflow-y-hiden">
            <div className={`relative ${showSidePopup ? "col-span-8" : "col-span-12"} h-full flex flex-col overflow-y-hidden`}>
                <AppBar />

                {/* Notification Creation form */}
                <div className="w-full p-4">
                    <NewNotificationForm />
                </div>

                {/* List of notification */}
                <div className="text-lg font-medium p-4">
                    Lịch sử thông báo
                </div>

                <div className="w-full h-full flex flex-col px-4 overflow-y-scroll">
                    {
                        notis.map((noti) => (
                            <NotificationCard
                                key={noti._id}
                                noti={noti}
                                selected={selectedNoti?._id === noti._id}
                                onViewDetail={handleViewNotification}
                            />
                        ))
                    }
                </div>
                
            </div>

            {/* Detail of admin */}
            <div className={`${showSidePopup ? "flex" : "hidden"} col-span-4 h-full flex flex-col-reverse border-l border-gray-200 overflow-y-scroll`}>
                {
                    selectedNoti &&
                    <NotificationDetails
                        noti={selectedNoti as Notification}
                        onClose={handleUpdateNewNoti}
                    />
                }
            </div>
        </div>
    );
}