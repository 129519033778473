import React from "react";
import { updateBankAccount } from "../logic/api";
import IconButton from "../../common/ui/IconButton";
import {  BankInfo } from "../../common/models/User";
import Input from "../../common/ui/Input";
import bank_data from "../../common/config/banks.json";
import Select from  "react-select";
import { useSnackbar } from "notistack";
import { set } from "date-fns";

export default function BankSettingForm({
    bank = undefined,
    onClose = () => { },
    onUpdate = () => { }
}: {
    bank?: BankInfo,
    onClose?: () => void,
    onUpdate?: (bank: BankInfo) => void,
}) {

    const [_bank, setBank] = React.useState<BankInfo | undefined>(bank);
    const bankListRef = React.useRef( bank_data.data );
    const [disableSubmit, setDisableSubmit] = React.useState<boolean>(true);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        setBank(bank);
    }, [bank]);

    React.useEffect(() => {
        if( _bank?.bin && _bank?.account_number && _bank?.account_name
            && ((_bank?.bin !== bank?.bin) 
            || (_bank?.account_number !== bank?.account_number)
            ||  (_bank?.account_name !== bank?.account_name)) ) {
            setDisableSubmit(false);
        } else {
            setDisableSubmit(true);
        }
    }, [_bank]);

    const handleSubmit = () => {
        updateBankAccount(_bank!)
        .then(res => {
            onUpdate(_bank!);
            enqueueSnackbar("Cập nhật tài khoản ngân hàng thành công", { variant: "success" });
        })
        .catch(err => {
            enqueueSnackbar(`Cập nhật tài khoản ngân hàng thất bại: ${err.response.data}`, { variant: "error" });
        });
    }

    return (
        <div className="w-full h-full flex flex-col justify-between px-4 py-3 overflow-y-scroll">

            <div className="flex flex-col gap-4">
                {/* Form Title */}
                <div className="py-3 flex flex-row justify-between items-start">
                    <div className="text-xl font-semibold text-gray-700">
                        {`${bank ? "Cập nhật" : "Tạo mới"} tài khoản ngân hàng`}
                    </div>

                    <IconButton
                        icon="ion:close-outline"
                        variant="mute"
                        onClick={() => {
                            setBank(bank);
                            onClose();
                        }}
                    />
                </div>

                {/* Bank name */}
                <div className="flex flex-col gap-1 items-start">
                    <label 
                        className="text-sm font-medium text-gray-500"
                        htmlFor="select" 
                        id="label-for-select"
                    >
                        Tên ngân hàng
                    </label>
                    <Select
                        className="w-full h-10 rounded-md focus:outline-none focus:border-blue-500"
                        options={bankListRef.current}
                        ariria-labelledby="label-for-select"
                        id="select"
                        placeholder="Chọn ngân hàng..."
                        formatOptionLabel={(option) => {
                            return (
                                <div className="flex flex-row items-center justify-start gap-4">
                                    <img className="w-12 h-auto" src={option.logo} alt={option.shortName} />
                                    <span>{option.name} ({option.shortName})</span>
                                </div>
                            )
                        }}
                        filterOption={(option, inputValue) => {
                            let isInName = option.data.name.toLowerCase().includes(inputValue.toLowerCase());
                            let isInShortName = option.data.shortName.toLowerCase().includes(inputValue.toLowerCase());
                            return isInName || isInShortName;
                        }}
                        value={bankListRef.current.find(bank => bank.bin === _bank?.bin)}
                        onChange={e => {
                            setBank({ 
                                ..._bank, 
                                bin: e?.bin 
                            } as BankInfo);
                        }}
                        // ref={bankNameRef}
                    />
                </div>

                {/* Bank Account Number */}
                <Input
                    label="Số tài khoản"
                    value={bank?.account_number || ""}
                    onChange={(e) => {
                        setBank({
                            ..._bank,
                            account_number: e.target.value,
                        } as BankInfo);
                    }}
                />

                {/* Bank Account Name */}
                <Input
                    label="Tên chủ tài khoản"
                    value={bank?.account_name || ""}
                    onChange={(e) => {
                        setBank({
                            ..._bank,
                            account_name: e.target.value,
                        } as BankInfo);
                    }}
                />
            </div>

            {/* Submit button */}
            <button 
                className="btn btn-success btn-block mb-4 my-12"
                onClick={handleSubmit}
                disabled={disableSubmit}
            >
                { bank ? "Cập nhật" : "Tạo mới"}
            </button>
        </div>
    );
}