import React from "react";
import BookingContentForm from "../forms/BookingContentForm";
import ViableBuyBookingDetail from "../components/ViableBuyBookingDetail";
import BookingCard from "../components/BookingCard";
import AppBar from "../../common/ui/Nav/AppBar";
import BookingCreateForm from "../forms/BookingCreateForm";
import { 
    BookingPublicInfoV2, 
    BookingInfoV2 
} from '../../common/models/Booking';
import { removeBookingFromList } from "../logic/utils";
import { authContext } from "../../auth/logic/authHook";
import { chatContext } from "../../chat/logic/chatContext";

function BookingForBuyPage() {
    const [state, setState] = React.useState<boolean>(false);
    const [shallowRefresh, setShallowRefresh] = React.useState<boolean>(false);
    const [selectedBooking, setSelectedBooking] =
        React.useState<BookingPublicInfoV2 | null>(null);
    const auth = React.useContext(authContext);
    const bCtx = React.useContext(chatContext);


    /*
     *======= Refresh screen deplay without calling API everyeach 30s
     *
     */
    React.useEffect(() => {
        const interval = setInterval(() => {
            setShallowRefresh(!shallowRefresh);
        }, 30000);
        return () => clearInterval(interval);
    }, [shallowRefresh]);


    /*
     *======= Handle click on booking card
     *  @param _id: string          -   id of selected booking
     *
     */
    const handleBookingCardClick = (_id: string) => {
        let booking = bCtx?.viableBuyBookings.find(booking => booking._id === _id);
        if (booking) {
            setSelectedBooking(booking);
            console.log(selectedBooking);
            setState(true);
        }
    }

    /*
     *======= Handle on close side popup
     *
     */
    const handleOnClose = () => {
        setSelectedBooking(null);
        setState(!state);
    }

    return (
        <div className="w-full h-full grid grid-cols-12">
            <div className={`${state ? "col-span-8" : "col-span-12"} h-full flex flex-col-reverse overflow-y-scroll`}>
                {/* Booking pass form */}
                <BookingContentForm
                    onSubmit={() => {
                        setSelectedBooking(null);
                        setState(true);
                    }}
                />

                {/* List of available bookings */}
                <div className={`w-full h-full flex flex-col-reverse overflow-y-scroll px-4 py-2 bg-gray-100 gap-2`}>
                    {bCtx?.viableBuyBookings.map((booking, index) => {
                        return (
                            <div
                                key={booking._id}
                                className={booking.user_creator.phone_number === auth?.authedUser?.phone_number
                                    ? "w-full flex flex-row justify-end"
                                    : "w-full flex flex-row justify-start"
                                }
                            >
                                <BookingCard
                                    booking={booking}
                                    show_status={false}
                                    width={state ? "80%" : "66%"}
                                    onClick={() => handleBookingCardClick(booking._id)}
                                    refresh={shallowRefresh}
                                    selected= {selectedBooking !== undefined && selectedBooking?._id === booking._id}
                                />
                            </div>
                        );
                    })}
                </div>

                {/* Top Bar */}
                <AppBar />
            </div>

            {/* Booking options form Sidepopup */}
            <div className={`${state ? "flex" : "hidden"} col-span-4 h-full flex flex-col-reverse border-l border-gray-200 overflow-y-scroll`}>
                {
                    selectedBooking 
                        ? state && <ViableBuyBookingDetail // Show booking detail if select an existing booking
                            booking={selectedBooking as BookingInfoV2}
                            onClose={handleOnClose}
                            onBuy={handleOnClose}
                            onCancel={() =>
                                bCtx?.setViableBuyBookings(
                                    removeBookingFromList(bCtx.viableBuyBookings, selectedBooking._id)
                                )
                            }
                        />
                        : state && <BookingCreateForm
                            onClose={handleOnClose}
                        />
                }
            </div>

        </div>
    );
}

export default BookingForBuyPage;