import { format, getTime, formatDistanceToNow } from "date-fns";
import moment from "moment-timezone";

const timezone = "Asia/Ho_Chi_Minh";

// ----------------------------------------------------------------------

export function fDate(date: string | number | Date, newFormat?: string) {
  const fm = newFormat || "dd MMM yyyy";

  return date ? format(new Date(date), fm) : "";
}

export function fDateTime(date: string | number | Date, newFormat?: string) {
  const fm = newFormat || "dd MMM yyyy p";

  return date ? format(new Date(date), fm) : "";
}

export function fTimestamp(date: string | number | Date) {
  return date ? getTime(new Date(date)) : "";
}

export function fToNow(date: string | number | Date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : "";
}

export function secondsToNow(date: string | number | Date) {
  return date ? (new Date().getTime() - new Date(date).getTime()) / 1000 : 0;
}

export function secondTimeDiff(date1: string | number | Date, date2: string | number | Date) {
  return date1 && date2 ? (new Date(date2).getTime() - new Date(date1).getTime()) / 1000 : 0;
}

export function fDateTimeBooking(date: string | number | Date) {
  return fDate(date, "dd/MM | HH:mm");
}

export function Timezone(date: string | number | Date) {
  return moment(new Date(date)).tz(timezone).toDate();
}

export function numberDayInMonth(date?: string | number | Date) {
  return moment(date ? new Date(date) : new Date()).daysInMonth();
}


export function isExpired(date: string | number | Date) {
  return secondsToNow(date) > 180;  // 3 minutes
}

export function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

export function fTimeMessage(date: string | number | Date) {
  if( secondsToNow(date) < 60 ) return "Vừa xong";
  if( secondsToNow(date) < 3600 ) return `${Math.floor(secondsToNow(date)/60)} phút trước`;
  if( secondsToNow(date) < 86400 ) return `${Math.floor(secondsToNow(date)/3600)} giờ trước`;
  if( secondsToNow(date) < 604800 ) return `${Math.floor(secondsToNow(date)/86400)} ngày trước`;
  return fDateTime(date, "dd/MM" );
}
