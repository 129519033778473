import React from "react";
import { TransactionInfo } from "../../common/models/User";
import { getMyTransactions } from "../logic/api";
import { shouldLoadMore } from "../../common/logic/utils";
import { useSnackbar } from "notistack";
import IconButton from "../../common/ui/IconButton";
import Select from "../../common/ui/Select";
import TransactionCard from "./TransactionCard";

interface TransactionFilter {
    last_x_months: number;
    type: "deposit" | "withdraw" | "subscribe" | "all";
}


export default function TransactionHistorySidebarDriver({
    onClose = () => {},
} : {
    onClose?: () => void,
}) {

    const [transactions, setTransactions] = React.useState<TransactionInfo[]>([]);
    const [filter, setFilter] = React.useState<TransactionFilter>({
        last_x_months: 1, // 1-12 months
        type: "all",
    });
    const [currPage, setCurrPage] = React.useState(0);
    const { enqueueSnackbar } = useSnackbar();

    // Load list of transactions
    React.useEffect(() => {
        getMyTransactions(
            filter.type, 
            filter.last_x_months, 
            0,
        )
            .then((res) => {
                setTransactions(res.data);
                setCurrPage(0);
            })
            .catch((err) => {
                enqueueSnackbar(`Lỗi tải danh sách giao dịch: ${err as string}`, { variant: "error" });
            });
    }, [ filter]);

    const handleLoadMoreTransactions = () => {
        getMyTransactions(
            filter.type, 
            filter.last_x_months, 
            currPage + 1,
        )
            .then((res) => {
                setTransactions([...transactions, ...res.data]);
                setCurrPage(currPage + 1);
            })
            .catch((err) => {
                enqueueSnackbar(`Lỗi tải danh sách giao dịch: ${err as string}`, { variant: "error" });
            });
    }


    return (
        <div className="w-full h-full flex flex-col gap-4 px-4 py-3 overflow-y-hidden">

            {/* Form Title */}
            <div className="py-3 flex flex-row justify-between items-start">
                <div className="flex flex-col gap-2 items-start">
                    <div className="text-xl font-semibold text-gray-700">
                        Lịch sử giao dịch
                    </div>
                </div>

                <IconButton
                    icon="ion:close-outline"
                    variant="mute"
                    onClick={() => {
                        onClose();
                    }}
                />
            </div>


            {/* Filter transactions */}
            <div className="w-full flex flex-row gap-2">
                <Select
                    options={[
                        { value: "all", label: "Loại giao dịch" },
                        { value: "deposit", label: "Nạp điểm" },
                        { value: "withdraw", label: "Rút điểm" },
                        { value: "subscribe", label: "Gia hạn tài khoản" },
                        { value: "buy", label: "Đăng/Bổ lịch"},
                        { value: "exchange", label: "San điểm"}
                    ]}
                    value={filter.type}
                    onChange={(e) => setFilter({
                        ...filter,
                        type: e.target.value as TransactionFilter["type"],
                    })}
                    width="50%"
                />

                <Select
                    options={
                        Array.from(
                        { length: 12 },
                        (_, index) => ({ 
                            value: index + 1, 
                            label: `${index + 1} tháng trước` 
                        }))
                    }
                    value={filter.last_x_months}
                    onChange={(e) => setFilter({
                        ...filter,
                        last_x_months: parseInt(e.target.value),
                    })}
                    width="50%"
                />
            </div>

            {/* List of transactions */}
            <div 
                className="w-full h-full flex flex-col gap-2 overflow-y-scroll"
                onScroll={(event) => {
                    const ele = event.target as HTMLDivElement;
                    if (shouldLoadMore(
                        ele.scrollHeight,
                        ele.scrollTop,
                        ele.clientHeight,
                        false,
                        1)
                    ) {
                        handleLoadMoreTransactions();
                    }
                }}
            >
                {transactions.map((transaction, index) => (
                    <TransactionCard
                        key={transaction._id}
                        transaction={transaction}
                    />
                ))}
            </div>

        </div>
    );
}